import {
  Component,
  OnInit,
  Input,
  Inject,
  HostListener,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { MarkdownService } from 'ngx-markdown';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { CustomerType, Deployment, Lab } from '../../../modals/lab.model';
import { UserLabType } from '../../../modals/lab.model';
import { LabService } from '../../../services/lab.service';
import { CommonService as CommonServicePT } from '@questions-ui';

import {
  copyToClipboard,
  htmlDecode,
  manageServiceVariables,
  stringToHtml,
  GithubDataService,
  CommonService,
  NotificationService,
} from '@utility';
import {
  EventEmitterService,
  UserSessionService,
  decryptBackendResponse,
} from '@teams-auth';
import { NotesService } from '../../../services/notes.service';
import { EventService } from '../../../services/event.services';

declare let $: any;

@Component({
  selector: 'cloudlabs-lab-guide',
  templateUrl: './lab-guide.component.html',
  styleUrls: ['./lab-guide.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabGuideComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: string;
  @Input() labDetail: Lab;
  @Input() lang: string;
  @Input() voucherCode: string;
  @Input() guideData: Deployment;
  @Input() isComplete: boolean;
  @Input() operationMode: string;
  @Output() labIsCompleted = new EventEmitter<string>();
  @Input() mode;
  @Input() splitKey: string;
  @Output() startingLab = new EventEmitter<string>();
  @Input() labUserData: any;
  @Input() labExpiryDuration: any;
  @Input() isMobile: boolean;
  @Input() set initialDeployment(initialDeployment: Deployment) {
    this.initialDeploymentData = initialDeployment;
  }

  @Input() showResourcesTab: boolean;

  public minGuide = false;
  public loading = false;
  public showDropdown = false;
  public tabView = 'guide';
  public itemsPerPage = 5;
  public labGuideData = [];
  public p = 1;
  public gitHUbdocs: any;
  public topPosToStartShowing = 100;
  public index: any;
  public defaultProps = {
    style: {},
    enableCopy: true,
  };
  public currentLabguide = [];
  appType: string;
  private notifier = new Subject();
  public userLabType = UserLabType;
  public customerType = CustomerType;
  public notes;
  private initialDeploymentData: Deployment;
  public validationPattern = /(<validation[^>]*>)/;
  public groupedQuestPattern = /(<grouped-questions[^>]*>)/;
  public ungroupedQuestPattern = /(<question[^>]*>)/;
  public inlineVideoPattern = /(<inline-video[^>]*>)/;
  public cloudPlatformIDCheck:boolean;
  public mdPath: any = [];
  public mdPathGroup: any = [];
  public videoPath: any = [];
  public videoWidth: any = [];
  public videoHeight: any = [];
  public testPattern =
    /(<validation[^>]*>)|(<grouped-questions[^>]*>)|(<question[^>]*>)|(<inline-video[^>]*>)/;

  @HostListener('window:keydown', ['$event'])
  async onkeyPress($event) {
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (
        $event.target.id === 'copyelement' ||
        ($event.target as HTMLElement).classList.contains('copyelement')
      ) {
        // eslint-disable-next-line no-useless-escape
        const brRegex = /<br\s*[\/]?>/gi;
        const copyData =
          $event.target.innerText || $event.target.firstChild.innerText;
        const txtdata = copyData.replace(brRegex, '\r\n').trim();
        await copyToClipboard(txtdata);
        if (!this.index.index) {
          this.tostrSrv.success(
            '<p role="alert">' +
              this.translateSrv.instant('MESSAGE_COPIED') +
              '</p>',
            null,
            {
              enableHtml: true,
              timeOut: 3000,
            }
          );
        }
      }
    }
  }

  @HostListener('document:click', ['$event'])
  async handleClick($event) {
    if (
      $event.target.id === 'copyelement' ||
      ($event.target as HTMLElement).classList.contains('copyelement')
    ) {
      // eslint-disable-next-line no-useless-escape
      const brRegex = /<br\s*[\/]?>/gi;
      const copyData =
        $event.target.innerText || $event.target.firstChild.innerText;
      const txtdata = copyData.replace(brRegex, '\r\n').trim();
      await copyToClipboard(txtdata);
      if (!this.index.index) {
        this.tostrSrv.success(
          '<p role="alert">' +
            this.translateSrv.instant('MESSAGE_COPIED') +
            '</p>',
          null,
          {
            enableHtml: true,
            timeOut: 3000,
          }
        );
      }
    }
    if ($event.target.id !== 'showdropdown-menu') {
      this.showDropdown = false;
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.updatePreHeights();
  }

  constructor(
    private LabSrv: LabService,
    public markdownService: MarkdownService,
    private aroute: ActivatedRoute,
    private tostrSrv: ToastrService,
    private translateSrv: TranslateService,
    private route: Router,
    private gitHubSrv: GithubDataService,
    private commonSrv: CommonService,
    private eventEmitter: EventEmitterService,
    private notesService: NotesService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private commonServicePT: CommonServicePT,
    public userSessionService: UserSessionService,
    private notificationSrv: NotificationService,
    @Inject('environment') public environment
  ) {
    this.appType = this.environment.appType;
  }

  scrollToTop() {
    const urlParts = this.route.url.split('/');
    if (urlParts.includes('labguide') || this.aroute.snapshot.params.id) {
      switch (this.tabView) {
        case 'guide':
          $(`#lab-guideview`).animate({ scrollTop: 0 });
          break;
        case 'description':
          this.scrollToTopToTab('lab-description-scroll-div');
          break;
        case 'environment':
          this.scrollToTopToTab('lab-environment-scroll-div');
          break;
        case 'validation':
          this.scrollToTopToTab('lab-validation-scroll-div');
          break;
        case 'resources':
          this.scrollToTopToTab('resources-scroll-div');
          break;
      }
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  scrollToTopToTab(id: string) {
    const elem = document.getElementById(id);
    elem.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 1000);
  }

  async ngOnInit() {
    this.notesService.notesObserver.subscribe((data) => {
      this.notes = data;
    });
    this.index = this.aroute.snapshot.params;
    this.lang = localStorage.getItem('language') || 'en';
    this.loading = true;
    await this.getLabDocs();
    this.loading = false;
    setTimeout(() => {
      this.reFlow();
      $(window).on('resize', this.reFlow);
      $(window).on('resize-tabs', this.reFlow);
    }, 1000);

    if (!this.labUserData) {
      this.LabSrv.getAttendeeLab(this.voucherCode).subscribe(
        (res) => {
          this.labUserData = res;
        },
        (error) => {
          const code = 'Error - 50026';
          this.eventEmitter.debugAlert(code, error);
        }
      );
    }

    this.translateSrv.onLangChange.subscribe(() => {
      this.reloadLabguide();
      // setTimeout(() => {
      // this.currentLabguide = [];
      // this.getLabDocs();
      // this.reFlow();
      // }, 1500);
    });
    //  this.loadScript();
    this.cloudPlatformIDCheck = this.guideData.AllocatedTestDriveViewModalDetails?.some(detail => 
      [1, 2, 4].includes(detail.CloudPlatformId)
    );
  }

  async reloadLabguide() {
    this.loading = true;
    this.labGuideData = [];
    this.gitHUbdocs = null;
    this.p = 1;
    this.currentLabguide = [];
    await this.getLabDocs();
    // console.log('changee');
    // await this.ngOnInit();
    this.loading = false;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    this.cdr.detectChanges();
  }

  /**
   * On click page navigation
   */
  getcalue() {
    this.navigationAnalytics();
    const page = this.p - 1;
    const path = this.gitHUbdocs.Files[page].RawFilePath;
    const texting = path.substring(0, path.lastIndexOf('/'));
    this.loadMdFiles();
    this.markdownService.renderer.image = (
      href: string,
      title: string,
      text: string
    ) => {
      if (!href.includes('http:/') && !href.includes('https:/')) {
        return `<img alt ="${title || text}" src="${texting}/${href}">`;
      } else {
        return `<img alt ="${title || text}" src="${href}">`;
      }
    };
    const el = document.getElementById('lab-guideview');
    el.scrollIntoView();
    el.focus();
    setTimeout(() => {
      this.handleLabGuideElements(texting);
      this.scrollToTop();
    }, 100);
  }

  navigationAnalytics() {
    const data = {
      VoucherCode: this.voucherCode,
      VisitedPageNumber: this.p,
      TotalPages: this.gitHUbdocs.Files.length,
      VisitedTime: moment.utc().format(),
    };
    this.LabSrv.createNavigationAnalytics(data).subscribe();
  }

  async updatePageLock() {
    this.gitHUbdocs = await this.LabSrv.getGitHubDocs(
      this.labDetail.UniqueName,
      this.voucherCode
    );
    this.checkUnlockingRules();
  }

  checkUnlockingRules() {
    const filteredGuides = this.gitHUbdocs.Files.filter(
      (guide) => guide.HasUnlockingRules
    ).map((guide) => guide.Order);
    this.eventService.lockPages.next(filteredGuides);
    setTimeout(() => {
      const page = this.p > 0 ? this.p - 1 : 1;
      const path = this.gitHUbdocs.Files[page].RawFilePath;
      const texting = path.substring(0, path.lastIndexOf('/'));
      this.handleLabGuideElements(texting);
    }, 100);
  }

  loadMdFiles() {
    const page = this.p > 0 ? this.p - 1 : 1;
    if (!this.currentLabguide[this.p]) {
      this.gitHubSrv
        .getGitHubData(this.gitHUbdocs.Files[page].RawFilePath)
        .subscribe(
          (res) => {
            this.currentLabguide[this.p] = res;
            const path = this.gitHUbdocs.Files[page].RawFilePath;
            const texting = path.substring(0, path.lastIndexOf('/'));
            setTimeout(() => {
              this.handleLabGuideElements(texting);
              this.scrollToTop();
            }, 1000);
            if (this.labDetail?.HasInlineQuestions) {
              this.currentLabguide[this.p]
                ?.split(this.ungroupedQuestPattern)
                .forEach((element) => {
                  if (this.ungroupedQuestPattern.test(element)) {
                    this.mdPath.push(this.getSourcePath(element));
                  }
                });
              this.currentLabguide[this.p]
                ?.split(this.groupedQuestPattern)
                .forEach((element) => {
                  if (this.groupedQuestPattern.test(element)) {
                    this.mdPathGroup.push(this.getSourcePath(element));
                  }
                });
              this.getlabguideQuestion();
            }
            this.currentLabguide[this.p]
              ?.split(this.inlineVideoPattern)
              .forEach((element) => {
                if (this.inlineVideoPattern.test(element)) {
                  this.videoPath.push(this.getSourcePath(element));
                  this.videoWidth.push(this.getWidth(element));
                  this.videoHeight.push(this.getHeight(element));
                }
              });
          },
          (err) => {
            this.currentLabguide[this.p] =
              'Error: One or more documents not found';
            const code = 'Error - 70025';
            this.eventEmitter.debugAlert(
              code,
              'Error: One or more documents not found'
            );
          }
        );
    } else if (this.labDetail?.HasInlineQuestions) {
      this.currentLabguide[this.p]
        ?.split(this.ungroupedQuestPattern)
        .forEach((element) => {
          if (this.ungroupedQuestPattern.test(element)) {
            this.mdPath.push(this.getSourcePath(element));
          }
        });
      this.currentLabguide[this.p]
        ?.split(this.groupedQuestPattern)
        .forEach((element) => {
          if (this.groupedQuestPattern.test(element)) {
            this.mdPathGroup.push(this.getSourcePath(element));
          }
        });
      this.getlabguideQuestion();
    }
    this.currentLabguide[this.p]
      ?.split(this.inlineVideoPattern)
      .forEach((element) => {
        if (this.inlineVideoPattern.test(element)) {
          this.videoPath.push(this.getSourcePath(element));
          this.videoWidth.push(this.getWidth(element));
          this.videoHeight.push(this.getHeight(element));
        }
      });
  }

  /**
   * get lab docs md file
   */
  async getLabDocs() {
    if (
      (this.guideData &&
        this.guideData.IsGitDocAvailable == true &&
        !this.labDetail.EnableAccessOverHttp) ||
      this.type === 'full'
    ) {
      this.gitHUbdocs = await this.LabSrv.getGitHubDocs(
        this.labDetail.UniqueName,
        this.voucherCode
      );
      if (this.labDetail.EnableInlineValidation) {
        this.checkUnlockingRules();
      }
      this.navigationAnalytics();
      if (this.gitHUbdocs.EventName && this.gitHUbdocs.EventName !== '') {
        this.LabSrv.guideTitle.next(this.gitHUbdocs.EventName);
      }
      if (this.gitHUbdocs.Files.length > 0) {
        this.gitHUbdocs.Files.sort((a, b) => a.Order - b.Order);

        // manage the markdown renderers
        const path = this.gitHUbdocs.Files[0].RawFilePath;
        const texting = path.substring(0, path.lastIndexOf('/'));

        this.markdownService.renderer.code = (code: string) => {
          const injectTagRegex =
            /<inject\s+(key="([^"]*)")?\s*(cloudname="([^"]*)")?\s*(style="([^"]*)")?\s*(enableCopy="([^"]*)")?\s*(enableClicktoPaste="([^"]*)")?\s*\/?>/gi;

          let style = '';
          let enableCopy = true;

          code = code.replace(
            injectTagRegex,
            (
              _,
              keyAttr,
              key,
              cloudnameAttr,
              cloudnameValue,
              styleAttr,
              styleValue,
              enableCopyAttr,
              enableCopyValue,
              enableClicktoPasteAttr,
              enableClicktoPasteValue
            ) => {
              key = key.replace(/ +/g, '').toLowerCase();

              cloudnameValue = cloudnameValue?.toLowerCase();
              let deploymentOutputValues: any = {};
              let guideDataValues: any = {};
              let initialDeploymentValues: any = {};

              if (
                this.guideData.AllocatedTestDriveViewModalDetails.length > 1
              ) {
                deploymentOutputValues =
                  this.guideData.AllocatedTestDriveViewModalDetails.find(
                    (item) =>
                      item.FriendlyName?.toLowerCase() === cloudnameValue
                  )?.DeploymentOutputValues;
                guideDataValues =
                  this.guideData.AllocatedTestDriveViewModalDetails.find(
                    (item) =>
                      item.FriendlyName?.toLowerCase() === cloudnameValue
                  );
                initialDeploymentValues =
                  this.initialDeploymentData.AllocatedTestDriveViewModalDetails.find(
                    (item) =>
                      item.FriendlyName?.toLowerCase() === cloudnameValue
                  );
              } else {
                deploymentOutputValues =
                  this.guideData.AllocatedTestDriveViewModalDetails[0]
                    ?.DeploymentOutputValues;
                guideDataValues =
                  this.guideData.AllocatedTestDriveViewModalDetails[0];
                initialDeploymentValues =
                  this.initialDeploymentData
                    .AllocatedTestDriveViewModalDetails[0];
              }

              const replacements = {
                azureaduseremail: guideDataValues?.AADEmail || '',
                azureaduserpassword: guideDataValues?.TempPassword || '',
                accesskey: guideDataValues?.AADSPAppId || '',
                displayname: guideDataValues?.AADSPDisplayName || '',
                secretkey: guideDataValues?.AADSPAppKey || '',
                subscriptionid: guideDataValues?.SubscriptionGuid || '',
                tenantid: guideDataValues?.TenantId || '',
                tenantdomainname: guideDataValues?.TenantDomainName || '',
                cloudlabsdeploymentid:
                  initialDeploymentValues?.CloudUserId || '',
              };

              // Iterate through OutputValues and add to replacements
              if (
                deploymentOutputValues &&
                Array.isArray(deploymentOutputValues.DeploymentOutputValues)
              ) {
                deploymentOutputValues.DeploymentOutputValues.forEach(
                  (deployment: any) => {
                    deployment.OutputValues.forEach((outputValue: any) => {
                      replacements[
                        outputValue.Name.replace(/ +/g, '').toLowerCase()
                      ] = outputValue.Value;
                    });
                  }
                );
              }

              // Update style if available
              if (styleAttr && styleValue) {
                style = styleValue;
              }
              enableCopy = enableCopyValue === 'false' ? false : true;

              return replacements[key] ?? '';
            }
          );

          code = code.replace(/<\/inject>/g, '');
          code = code.replace(/</g, '&lt;').replace(/>/g, '&gt;');
          return `<pre class="line-numbers language-none" tabindex="0"><code class="language-none" enablecopy="${enableCopy}" style="${style}">${code}</code></pre>`;
        };

        this.markdownService.renderer.html = (htmlTag: string) => {
          const props = { ...this.defaultProps };
          return this.commonSrv.getAllAttributes(
            props,
            htmlTag,
            this.guideData,
            this.guideData,
            this.initialDeploymentData
          );
        };

        this.commonSrv.getImageTag(path, this.markdownService);
        this.commonSrv.getLinks(this.markdownService);

        this.loadMdFiles();
        // const gitdocObserables = this.gitHUbdocs.Files.map((file) =>
        //   this.gitHubSrv.getGitHubData(file.RawFilePath)
        // );

        // combineLatest(gitdocObserables)
        //   .pipe(
        //     takeUntil(this.notifier),
        //     map((resp) =>
        //       resp.map((res, index) => ({
        //         res,
        //         order: this.gitHUbdocs.Files[index].Order,
        //       }))
        //     )
        //   )
        //   .subscribe(
        //     (res) => {
        //       this.labGuideData = res;
        //       this.labGuideData.sort((x, y) => {
        //         return x.order - y.order;
        //       });
        // setTimeout(() => {
        //   this.handleLabGuideElements(texting);
        // }, 3000);
        //   },
        //   (err) => {
        //     this.labGuideData = [
        //       { res: 'Error: One or more documents not found', order: 1 },
        //     ];
        //     const code = 'Error - 70025';
        //     this.eventEmitter.debugAlert(
        //       code,
        //       'Error: One or more documents not found'
        //     );
        //   }
        // );
      }
    } else {
      if (this.labDetail.LabLaunchPageDescription) {
        this.tabView = 'description';
      } else {
        this.tabView = 'environment';
      }
    }
  }
  /**
   * selected tab
   * @param item
   */

  viewTab(item) {
    this.tabView = item;
    this.showDropdown = false;
  }

  completeTheLab(event: string) {
    this.labIsCompleted.emit(event);
  }

  reFlow() {
    const tab_wrapper = $('.nav-tabs');
    const wrapper_width = tab_wrapper.width(),
      dropdown_width = tab_wrapper.find('li.dropdown').width();
    let width_sum = 0;
    tab_wrapper.find('>li:not(li.dropdown)').each(function () {
      // TODO Phase 2 - Aakash, Ashish - Need to look properly to fix 'this' red error
      width_sum += $(this).outerWidth(true);
      if (width_sum + dropdown_width + 30 > wrapper_width) $(this).hide();
      else $(this).show();
    });
    const hidden_lists = tab_wrapper.find('>li:not(li.dropdown):not(:visible)');
    if (hidden_lists.length > 0) {
      $('li.dropdown').show();
      tab_wrapper.find('ul.dropdown-menu li').hide();
      hidden_lists.map((element) => {
        tab_wrapper
          .find('ul.dropdown-menu li#' + hidden_lists[element].id + '-dup')
          .show();
      });
    } else {
      $('li.dropdown').hide();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      for (const key in changes) {
        if (
          Object.prototype.hasOwnProperty.call(changes, key) &&
          changes[key].currentValue
        ) {
          this[key] = changes[key].currentValue;
        }
        if (
          !changes[key].isFirstChange() &&
          key === 'lang' &&
          (changes[key].currentValue ||
            // eslint-disable-next-line no-prototype-builtins
            (changes[key].hasOwnProperty('previousValue') &&
              changes[key].currentValue !== changes[key].previousValue))
        ) {
          // this.loading = true;
          // this.labGuideData = [];
          // this.gitHUbdocs = null;
          // this.p = 0;
          // this.currentLabguide = [];
          // await this.getLabDocs();
          // // console.log('changee');
          // // await this.ngOnInit();
          // this.loading = false;
          // setTimeout(() => {
          //   window.dispatchEvent(new Event('resize'));
          // }, 100);
        }
      }
    }
    this.cdr.detectChanges();
  }

  handleLabGuideElements(texting) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const defaultProps = { ...this.defaultProps };
    if (texting !== '') {
      $('#lab-guideview img').each(function (index, elm) {
        // Add tab index to all images
        $(elm).attr('tabindex', '0');
        $(elm).addClass('img-click');

        if ($(elm).attr('src') && !$(elm).attr('src').includes('http')) {
          $(elm).attr('src', texting + '/' + $(elm).attr('src'));
        }
      });
    } else {
      $('#lab-guideview img').each(function (index, elm) {
        // Add tab index to all images
        $(elm).attr('tabindex', '0');
        $(elm).addClass('img-click');
      });
    }
    const clickToCopyText = this.translateSrv.instant('COPY');
    $('#lab-guideview .copydetails').each(function () {
      $(this).find('a').remove();
      let text = $(this).text();
      if (text !== undefined && text !== null && text !== '') {
        text = text.trim();
      }

      const ariaLabel = $(this).attr('aria-label');
      $(this).append(
        '<a style="' +
          $(this).attr('style') +
          '" tabindex="0" role="button"  aria-label="' +
          clickToCopyText +
          ' ' +
          ariaLabel +
          '"  data-toggle="tooltip" ' +
          '  title="' +
          clickToCopyText +
          ' ' +
          ariaLabel +
          '" alt="" class="fa fa-clone copyelement">' +
          text +
          '</a>'
      );
      // set tooltip
      $('[data-toggle="tooltip"]').tooltip({
        placement: 'bottom',
        trigger: 'hover',
      });
    });
    $('#lab-guideview pre').each(function () {
      $(this).find('a').remove();
      const text = $(this).find('code')[0].innerHTML;
      const htmlText = htmlDecode(text);
      const elm = stringToHtml(htmlText);
      let outputValue;
      const props = { ...defaultProps };
      if (elm) {
        const key = elm.getAttribute('key').replace(/ +/g, '').toLowerCase();

        const cloudName = elm
          .getAttribute('cloudname')
          .replace(/ +/g, '')
          ?.toLowerCase();
        let deploymentOutputValues;
        let guideDataValues;
        if (self.guideData.AllocatedTestDriveViewModalDetails.length > 1) {
          guideDataValues =
            self.guideData.AllocatedTestDriveViewModalDetails.find(
              (item) => item.FriendlyName?.toLowerCase() === cloudName
            );
          deploymentOutputValues = guideDataValues?.DeploymentOutputValues;
        } else {
          deploymentOutputValues =
            self.guideData.AllocatedTestDriveViewModalDetails[0]
              ?.DeploymentOutputValues;
          guideDataValues =
            self.guideData.AllocatedTestDriveViewModalDetails[0];
        }

        if (elm.hasAttribute('enableCopy')) {
          props.enableCopy = Boolean(elm.getAttribute('enableCopy') === 'true');
        }
        if (elm.hasAttribute('style')) {
          props.style = elm.getAttribute('style');
        }

        if (deploymentOutputValues && deploymentOutputValues.length > 0) {
          deploymentOutputValues.forEach((item: any) => {
            const { OutputValues } = item;
            if (
              OutputValues &&
              OutputValues.length > 0 &&
              OutputValues.findIndex(
                (value) => value.Name.replace(/ +/g, '').toLowerCase() === key
              ) > -1
            ) {
              outputValue = OutputValues.find(
                (value) => value.Name.replace(/ +/g, '').toLowerCase() === key
              );
            }
          });
        }

        // manage the service variable
        const Value = manageServiceVariables(key, guideDataValues);
        if (Value !== '') {
          outputValue = { Value };
        }
      }
      const value = outputValue !== undefined ? outputValue.Value : text;
      if (outputValue !== undefined) {
        $(this).find('code')[0].innerHTML = value;
      }
      // handle css style on variables
      if (props.style) {
        const codeElm = $(this).find('code')[0];
        if (codeElm) {
          $(codeElm).attr('style', props.style);
        }
      }
      const heightinPX = $(this).css('height');
      $(this).css('display', 'inline-block');
      if (
        $(this).next('a').length === 0 &&
        parseInt(heightinPX.slice(0, -2)) > 0
      ) {
        $(this).after(
          `<a tabindex="0" title="${clickToCopyText}" role="button" aria-label="Click to copy" alt="" class="fa fa-clone copyelement" style="bottom:${
            parseInt(heightinPX.slice(0, -2)) - 10
          }px;left:0.4rem"><p style="display:none">${value.trimEnd()}</p></a>`
        );
        $('[data-toggle="tooltip"]').tooltip({
          placement: 'bottom',
          trigger: 'hover',
        });
      }
    });
    $('.selfnavigation').on('click', function () {
      const id = $(this).data('id');
      const elementID = id.replace('#', '');
      const el = document.getElementById(elementID);
      el.scrollIntoView();
    });
  }
  startLab() {
    this.startingLab.emit();
  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private updatePreHeights(): void {
    $('#lab-guideview pre').each(function () {
      const heightinPX = $(this).css('height');
      if ($(this).next('a').length > 0) {
        $(this)
          .next('a')
          .css('bottom', parseInt(heightinPX.slice(0, -2)) - 10 + 'px');
      }
    });
  }
  getUngroupQuestionData(data: any) {
    const questData = this.mdPath.find((e) => data.indexOf(e.md) > -1);
    if (questData?.childData) {
      this.userSessionService.isQuestionLoaded$.next(true);
    }

    return questData?.childData;
  }
  getGroupQuestionData(data: any) {
    const questData = this.mdPathGroup.find((e) => data.indexOf(e.md) > -1);
    if (questData?.childData) {
      this.userSessionService.isQuestionLoaded$.next(true);
    }
    return questData?.childData;
  }

  getVideoLink(data: any) {
    const videoLink = this.videoPath.find((e) => data.indexOf(e) > -1);
    return videoLink;
  }

  getVideoWidth(data: any) {
    const width = this.videoWidth.find((e) => data.indexOf(e) > -1);
    return width;
  }

  getVideoHeight(data: any) {
    const height = this.videoHeight.find((e) => data.indexOf(e) > -1);
    return height;
  }

  public getlabguideQuestion(): void {
    this.commonServicePT
      .getlabguideQuestion(this.voucherCode, this.p)
      .subscribe((res: any) => {
        // const res = decryptBackendResponse(encryptedRes, this.userSessionService.getOriginalKeyPair().private);
        if (res?.status === 'Error') {
          const code = 'Error - 30051';
          this.notificationSrv.error(res.message, null);
          this.eventEmitter.debugAlert(code, res.message);
        } else {
          const ungroupedQuestions = res.ungroupedQuestions;
          this.mdPath = this.mdPath.map((e: any) => {
            const findQuestFileName = ungroupedQuestions.find(
              (r: any) => r.questionFileName == e
            );
            const question = `<question source="${e}" />`;
            e = { md: e, childData: findQuestFileName, questionTag: question };
            return e;
          });
          const groupedQuestions = res.groupedQuestions;
          this.mdPathGroup = this.mdPathGroup.map((e: any) => {
            const findgroupFileName = groupedQuestions.find(
              (r: any) => r.groupFileName == e
            );
            const question = `<grouped-questions source="${e}" />`;
            e = { md: e, childData: findgroupFileName, questionTag: question };
            return e;
          });
        }
      });
  }

  private getSourcePath(inlineQuestionTag): string {
    const regexr = /source="([^"]+)"/;
    // const regexr = /src="([^"]+)"/;
    const match = regexr.exec(inlineQuestionTag);
    if (match && match.length > 1) {
      const questionMdUrl = match[1];
      return questionMdUrl;
    }
    return '';
  }

  private getWidth(inlineQuestionTag): string {
    const regexr = /width="([^"]+)"/;
    const match = regexr.exec(inlineQuestionTag);
    if (match && match.length > 1) {
      const width = match[1];
      return width;
    }
    return '';
  }

  private getHeight(inlineQuestionTag): string {
    const regexr = /height="([^"]+)"/;
    const match = regexr.exec(inlineQuestionTag);
    if (match && match.length > 1) {
      const height = match[1];
      return height;
    }
    return '';
  }
}
