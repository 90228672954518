<div id="resource-container">

  <div *ngIf="environment.customer === customerType.DATABRICKS; else vmBlock">
    <div class="square-box heading-outer">
      <span class="heading" role="heading" aria-level="2">{{ "TAB_RESOURCES" | translate }}</span>
      <div id="refresh-all-btn" role="button" class="btn btn-sm ts-btn-fluent-primary btn-primary refresh text-right"
        tabindex="0" (click)="getDBWorkspaceResouces()" (keyup.enter)="getDBWorkspaceResouces()"
        attr.aria-label="{{ 'REFRESH_ALL_VM' | translate }}" style="padding-bottom: 0px;">
        <span style="font: normal normal normal 14px/1" class="fas fa-sync-alt vm-action-buttons mr-2 mb-2 refresh-vm"
          [ngClass]="{ rotate: isVMLoading }">
          <span *ngIf="!docActions"> </span>
        </span>
        <span>{{ "BUTTON_REFRESH" | translate }}</span>
      </div>
    </div>

    <div class="vm-group square-box" *ngIf="databriksCluserList.length>0">
      <span class="resources-heading" resources-heading>
        <div class="d-flex heading-box">
          <div class="heading">
            <div class="caption-subject" role="heading" aria-level="2" aria-label="DATABRICKS_CLUSTERS">
              {{ "DATABRICKS_CLUSTERS" | translate : {singularPlural:databriksCluserList.length | pluralize} }}
            </div>
          </div>
          <p role="heading" aria-level="3" *ngIf="dbUsage && showUpTimeLimit">
            {{'MAXIMUM_UPTIME_LIMIT'| translate}}{{ " (hh:mm) : " + manageTimestamp(dbUsage.UptimeLimit) }}
          </p>
          <p role="heading" aria-level="3" *ngIf="dbUsage && showUpTimeLimit">
            {{'REMAINING_UPTIME'| translate}} {{ " (hh:mm) : " + manageTimestamp(dbUsage.RemainingUptime) }}
            <span id="vm-tooltip" class="info-tooltip" [ngClass]="{ timeReached: dbUsage.RemainingUptime === '00:00' }"
              tooltip="{{ 'RESOURCES_TOOLTIP_1' | translate }}">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </span>
          </p>
        </div>
      </span>
      <div class="portlet-body">
        <div class="table-responsive">
          <table aria-label="Lab Resource Table" class="table" tabindex="0">
            <tr>
              <th>{{ "NAME" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
              <th>
                {{ "ACTIONS" | translate }}
              </th>
            </tr>
            <tbody>
              <tr *ngFor="let row of databriksCluserList; let i = index">
                <td>
                  {{ row.ResourceName }}
                </td>
                <td>
                  {{dbClusterStatus.includes(row.ResourceStatus) ? row.ResourceStatus : 'UPDATING'}}
                </td>
                <td class="text-left vm-buttons" header-class="'text-left'">
                  <ng-container *ngIf="!row.IsSharedResource">
                    <teams-button [ariaLabel]="'Start'" [titleText]="'START' | translate"
                      [style]=" (row.ResourceStatus === 'RUNNING' || row.ResourceStatus === 'PENDING' || row.ResourceStatus === 'FAILED' || row.ResourceStatus === 'STARTING' || !row.ResourceId) ? 'cursor: not-allowed':''"
                      id="{{ 'START' | translate }}{{ row.ResourceName }}"
                      (buttonClick)="virtualMachineOperations(row,'11')"
                      [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                      [disabled]="row.ResourceStatus === 'RUNNING' || row.ResourceStatus === 'PENDING' || row.ResourceStatus === 'FAILED' || row.ResourceStatus === 'STARTING' || !row.ResourceId">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
                    </teams-button>
                    <teams-button [ariaLabel]="'Stop'" [titleText]="'STOP' | translate"
                      [style]=" (row.ResourceStatus === 'TERMINATING' || row.ResourceStatus === 'TERMINATED' || row.ResourceStatus === 'FAILED' || row.ResourceStatus === 'STOPPING' || !row.ResourceId) ? 'cursor: not-allowed':''"
                      id="{{ 'STOP' | translate }}{{ row.ResourceName }}"
                      (buttonClick)="virtualMachineOperations(row,'12')"
                      [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                      [disabled]="row.ResourceStatus === 'TERMINATING' || row.ResourceStatus === 'TERMINATED' || row.ResourceStatus === 'FAILED' || row.ResourceStatus === 'STOPPING' || !row.ResourceId">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
                    </teams-button>
                  </ng-container>
                  <teams-button [ariaLabel]="'SYNC'" [titleText]="'SYNC' | translate"
                    [style]=" (!row.ResourceId) ? 'cursor: not-allowed':''"
                    id="{{ 'SYNC' | translate }}{{ row.ResourceName }}" (buttonClick)="getUpdatedDBClusterStatus(row,i)"
                    [disabled]="!row.ResourceId"
                    [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'">
                    <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-refresh"></i>
                  </teams-button>
                </td>
              </tr>

              <tr *ngIf="databriksCluserList.length<=0">
                <td colspan="3" translate="NO_RECORDS_FOUND" style="border-top: 0"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="sqlPool-section" class="square-box" *ngIf="databriksSQLEPList.length>0">
      <div class="heading-box">
        <h3 class="heading">{{'DB_SQL_EP_HEADER' | translate : {singularPlural:databriksSQLEPList.length | pluralize} }}
        </h3>
      </div>
      <div class="sql-group">
        <div class="portlet-body">
          <div class="table-responsive">
            <table aria-label="Sql Pool Table" class="table" tabindex="0">
              <tr>
                <th>{{ "NAME" | translate }}</th>
                <th>{{ "STATUS" | translate }}</th>
                <th>
                  {{ "ACTIONS" | translate }}
                </th>
              </tr>
              <tbody>
                <tr let *ngFor="let row of databriksSQLEPList; let i = index">
                  <td>
                    {{ row.ResourceName }}
                  </td>
                  <td>
                    {{row.ResourceStatus}}
                  </td>
                  <td class="text-left vm-buttons" header-class="'text-left'">
                    <ng-container *ngIf="!row.IsSharedResource">
                      <teams-button [ariaLabel]="'Start'" [titleText]="'START' | translate"
                        [style]=" (row.ResourceStatus === 'RUNNING' || row.ResourceStatus === 'STARTING')?'cursor: not-allowed':''"
                        id="{{ 'START' | translate }}{{ row.ResourceName }}"
                        (buttonClick)="virtualMachineOperations(row,'13')"
                        [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                        [disabled]="row.ResourceStatus === 'RUNNING' || row.ResourceStatus === 'STARTING'">
                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
                      </teams-button>
                      <teams-button [ariaLabel]="'STOP'" [titleText]="'STOP' | translate"
                        [style]="(row.ResourceStatus === 'PENDING' || row.ResourceStatus === 'STOPPED' || row.ResourceStatus === 'STOPPING')?'cursor: not-allowed':''"
                        id="{{ 'STOP' | translate }}{{ row.ResourceName }}"
                        (buttonClick)="virtualMachineOperations(row,'14')"
                        [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                        [disabled]="row.ResourceStatus === 'PENDING' || row.ResourceStatus === 'STOPPED' || row.ResourceStatus === 'STOPPING'">
                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
                      </teams-button>
                    </ng-container>
                    <teams-button [ariaLabel]="'SYNC'" [titleText]="'SYNC' | translate"
                      [style]="(!row.ResourceId)?'cursor: not-allowed':''"
                      id="{{ 'SYNC' | translate }}{{ row.ResourceName }}" (buttonClick)="getUpdatedDBSQLEPStatus(row,i)"
                      [disabled]="!row.ResourceId"
                      [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-refresh"></i>
                    </teams-button>
                  </td>
                </tr>
                <tr *ngIf="databriksSQLEPList.length<=0">
                  <td colspan="3" translate="NO_RECORDS_FOUND" style="border-top: 0"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="noResources" class="square-box" *ngIf="databriksSQLEPList.length<=0 && databriksCluserList.length<=0">
      <p>{{ "NO_ACTIVE_RESOURCES" | translate }}</p>
    </div>
  </div>
  
  

  <ng-template #vmBlock>
    <div class="square-box heading-outer">
      <span class="heading" role="heading" aria-level="2">{{ "TAB_RESOURCES" | translate }}</span>
      <button id="refresh-all-btn" role="button" class="btn btn-sm ts-btn-fluent-primary btn-primary refresh text-right"
        tabindex="0" (click)="loadResources()" (keyup.enter)="loadResources()"
        attr.aria-label="{{ 'REFRESH_ALL_VM' | translate }}">
        <span style="font: normal normal normal 14px/1" class="fas fa-sync-alt vm-action-buttons mr-2 mb-2 refresh-vm"
          [ngClass]="{ rotate: isVMLoading }">
          <span *ngIf="!docActions"> </span>
        </span>
        <span>{{ "BUTTON_REFRESH" | translate }}</span>
      </button>
    </div>
    <!-- VM labs section started -->
    <div class="vm-group square-box">
      <span class="resources-heading" resources-heading *ngIf="multiVmUsageList && multiVmUsageList.length>0">
        <div class="d-flex heading-box">
          <div class="heading">
            <div  class="caption-subject" role="heading" aria-level="3"
              aria-label="TAB VIRTUAL MACHINES">
              {{ "TAB_VIRTUAL_MACHINES" | translate }}
              </div>

            <!-- <div [ngSwitch]="vmUsageList[0].CloudPlatformId" class="caption-subject" role="heading" aria-level="3" -->
            <!-- <div [ngSwitch]="multiResourceList.CloudPlatformId" class="caption-subject" role="heading" aria-level="3"
              aria-label="TAB VIRTUAL MACHINES">
              <ng-container *ngSwitchCase="2">{{ 'EC2_INSTANCES' | translate }}</ng-container>
              <ng-container *ngSwitchCase="3">{{ 'VM_INSTANCES' | translate }}</ng-container>
              <ng-container *ngSwitchCase="4">{{ 'TAB_VIRTUAL_MACHINES' | translate }}</ng-container>
              <ng-container *ngSwitchDefault>{{ "TAB_VIRTUAL_MACHINES" | translate }}</ng-container>

            </div> -->
          </div>
          <p role="heading" aria-level="4" *ngIf="attendeLabDetails.ComputeVMUsage && showUpTimeLimit">
            {{'MAXIMUM_UPTIME_LIMIT'| translate}}{{ " (hh:mm) : " + upTimeLimit }}
          </p>
          <p role="heading" aria-level="4" *ngIf="showUpTimeLimit">
            {{'REMAINING_UPTIME'| translate}} {{ " (hh:mm) : " + remaining_UpTime }}
            <span id="vm-tooltip" class="info-tooltip" [ngClass]="{ timeReached: remaining_UpTime === '00:00' }"
              tooltip="{{ VMtooltipText | translate }}">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </span>
          </p>
        </div>
      </span>
      <ng-container *ngIf="multiVmUsageList?.length > 1">
        <div class="d-flex align-items-center justify-content-start w-100">
          <ul class="nav nav-tabs tabs_1 tab-width ts-tabs-list" role="tablist" style="width: 100%;">
              <li id="link_{{item.id}}" class="nav-item" role="presentation" *ngFor="let item of multiVmUsageList; let i = index;">
                  <a class="nav-link ts-tab-item" id="main-landmark{{i}}" role="tab"
                      [appArrowKeysAccessible]="'arrow-key-doc-guide'" style="text-decoration: none;"
                      [attr.aria-selected]="item.selected" [ngClass]="{'active-tab': multiResourceList === item}"
                      (click)="viewTab(item)" href="javascript:void(0)"
                      href="javascript:void(0)"><b
                          [translate]="item.CloudPlatformFriendlyName"></b></a>
              </li>
          </ul>
        </div>
      </ng-container>
      <!-- virtual box section -->
      <div class="portlet-body">
        <div class="table-responsive">
          <table aria-label="Lab Resource Table" class="table" tabindex="0">
            <tr>
              <th>{{ "NAME" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
              <th *ngIf="attendeLabDetails.CloudPlatformId !== 4" class="hide-column">{{ "DNS_NAME" | translate }}</th>
              <th class="hide-column">{{ "IP_ADDRESS" | translate}}</th>
              <th *ngIf="attendeLabDetails.EnableResourceOperations">
                {{ "ACTIONS" | translate }}
              </th>
            </tr>
            <!-- <tbody *ngIf="vmUsageList && vmUsageList.length>0">
              <ng-container *ngFor="let vmUsage of vmUsageList"> -->
            <tbody >
              <ng-container *ngFor="let vmUsage of multiResourceList?.Resources">
              <!-- <ng-container *ngFor="let row of vmUsage.ResourceList; let i = index"> -->
              <ng-container *ngFor="let row of vmUsage.ResourceList; let i = index">  
                <tr>
                  <ng-container *ngIf="!row.IsDynamicRG">
                    <td>
                      <div class="d-flex align-items-center vm-name"
                        [ngClass]="{'cursor-pointer': isMobile || row.ContainsHyperV}"
                        (click)="isMobile || row.ContainsHyperV ? collpaseExpand(row) : ''">
                        <i *ngIf="isMobile || row.ContainsHyperV" class="fas fa-chevron-down mr-3" role="img"
                          aria-label="img" aria-hidden="true"></i>
                        <span>
                          {{ row.ResourceName }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span *ngIf="row.Status === '' || row.Status === null">{{
                        "UPDATING_STATUS" | translate
                        }}</span>
                      <span *ngIf="row.Status !== '' && row.Status !== null">{{
                        row.Status
                        }}</span>
                      <span *ngIf="
                  vmUsage.UptimeLimit !== null &&
                  vmUsage.RemainingUptime !== null &&
                  vmUsage.RemainingUptime === '00:00:00' &&
                  (row.Status === 'VM deallocated' ||
                  row.Status === 'Deallocating' ||
                  row.Status === 'VM deallocating')
                  ">-&nbsp;{{
                        "RUNTIME_LIMIT_EXHAUSTED_STATUS" | translate
                        }}</span>
                    </td>

                    <td *ngIf="!docActions && attendeLabDetails.CloudPlatformId !== 4" class="hide-column" style="word-break: break-word">
                      {{ row.DNSName }}
                      <a *ngIf="row.DNSName" role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'DNS_NAME' | translate}}"
                        [cbContent]="row.DNSName" class="" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} {{'USERNAME' | translate}}" (click)="copyContent()">
                        <i role="img" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a>
                    </td>

                    <td class="hide-column" style="word-break: break-word">
                      {{ row.PublicIpAddress }}
                      <a *ngIf="row.PublicIpAddress" role="button" ngxClipboard
                        tooltip="{{'COPY' | translate}} {{'IP_ADDRESS' | translate}}" [cbContent]="row.PublicIpAddress" class=""
                        href="javascript:void(0)" attr.aria-label="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                        (click)="copyContent()">
                        <i role="img" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a>
                    </td>
                    <td class="text-left vm-buttons" header-class="'text-left'"
                      *ngIf="attendeLabDetails.EnableResourceOperations">
                      <teams-button [ariaLabel]="'Start'" [tooltip]="'START' | translate" id="{{ 'START' | translate }}
                                                                                  {{ row.ResourceGroupName }}
                                                                                  {{ row.ResourceName }}"
                        (buttonClick)="virtualMachineOperations(row, '7')" [style]="((['VM running','running', 'terminated','RUNNING','TERMINATED']).includes(row.Status) ||
                                              (attendeLabDetails.ComputeVMUsage === true &&
                                              vmUsage.UptimeLimit !== null &&
                                              vmUsage.RemainingUptime !== null &&
                                              vmUsage.RemainingUptime === '00:00:00' )) ? 'cursor: not-allowed' : ''"
                        [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                        [disabled]="
                                                                                  (['VM running','running', 'terminated','RUNNING','TERMINATED','TERMINATING']).includes(row.Status) ||
                                                                                  (attendeLabDetails.ComputeVMUsage === true &&
                                                                                  vmUsageList.UptimeLimit !== null &&
                                                                                  vmUsageList.RemainingUptime !== null &&
                                                                                  vmUsageList.RemainingUptime === '00:00:00' )">
                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
                      </teams-button>
                      <teams-button *ngIf="attendeLabDetails.CloudPlatformId !== 3" [ariaLabel]="'Restart'" [tooltip]="'RESTART' | translate" id="{{ 'RESTART' | translate }}{{ row.ResourceGroupName }}{{
                                                              row.ResourceName
                                                            }}" (buttonClick)="virtualMachineOperations(row, '9')"
                        [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'"
                        [style]="(!(['VM running','running','RUNNING']).includes(row.Status) ||
                                              (row.Status !== null &&
                                                attendeLabDetails.ComputeVMUsage === true &&
                                                vmUsage.UptimeLimit !== null &&
                                                vmUsage.RemainingUptime !== null &&
                                                vmUsage.RemainingUptime === '00:00:00' )) ? 'cursor: not-allowed' : ''"
                        [disabled]="
                                                              !(['VM running','running','RUNNING']).includes(row.Status) ||
                                                              (row.Status !== null &&
                                                                attendeLabDetails.ComputeVMUsage === true &&
                                                                vmUsageList.UptimeLimit !== null &&
                                                                vmUsageList.RemainingUptime !== null &&
                                                                vmUsageList.RemainingUptime === '00:00:00' )
                                                            ">
                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fas fa-sync-alt"></i>
                      </teams-button>
                      <teams-button [ariaLabel]="'Deallocate'" [tooltip]="'DEALLOCATE' | translate" id="deallocate{{ row.ResourceGroupName }}{{
                                        row.ResourceName
                                      }}" (buttonClick)="virtualMachineOperations(row, '8')"
                        [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'"
                        [style]="((['VM deallocated','VM deallocating','deallocated', 'deallocating', 'Stopping', 'stopping', 'stopped', 'terminated','STOPPING','STOPPED','TERMINATED','TERMINATING']).includes(row.Status) || 
                        (row.Status !== null &&
                          attendeLabDetails.ComputeVMUsage === true &&
                          vmUsage.UptimeLimit !== null &&
                          vmUsage.RemainingUptime !== null &&
                          vmUsage.RemainingUptime === '00:00:00')) ? 'cursor: not-allowed' : ''" [disabled]="
                                        (['VM deallocated','VM deallocating','deallocated', 'deallocating', 'Stopping', 'stopping', 'stopped', 'terminated','TERMINATED','STOPPED','STOPPING','TERMINATING']).includes(row.Status) ||
                                        (row.Status !== null &&
                                          attendeLabDetails.ComputeVMUsage === true &&
                                          vmUsageList.UptimeLimit !== null &&
                                          vmUsageList.RemainingUptime !== null &&
                                          vmUsageList.RemainingUptime === '00:00:00')
                                      ">
                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
                      </teams-button>
                      <!-- {{row | json}} -->
                      <teams-button role="button" aria-label="Launch Lab"
                        tooltip="{{ 'LAUNCH_VM_IN_BROWSER' | translate }}" (buttonClick)="openVMLaunchURL(row)"
                        [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'"
                        [style]="(!(['VM running','running']).includes(row.Status) ||
                        (row.Status !== null &&
                          attendeLabDetails.ComputeVMUsage === true &&
                          vmUsageList.UptimeLimit !== null &&
                          vmUsageList.RemainingUptime !== null &&
                          vmUsageList.RemainingUptime === '00:00:00' )) ? 'cursor: not-allowed' : ''"
                        *ngIf="row.VMLaunchURL && showVMLaunchButton" [disabled]="
                                        !(['VM running','running']).includes(row.Status) ||
                                        (row.Status !== null &&
                                          attendeLabDetails.ComputeVMUsage === true &&
                                          vmUsageList.UptimeLimit !== null &&
                                          vmUsageList.RemainingUptime !== null &&
                                          vmUsageList.RemainingUptime === '00:00:00' )
                                      ">
                        <i alt="" role="img" aria-label="img" aria-hidden="true" class="fas fa-external-link-alt"></i>
                      </teams-button>
                      <teams-button aria-label="Launch url" role="button" [style]="(row?.InternalId === this.internalId ||
                      !['VM running', 'running'].includes(row.Status) ||
                      (row.Status !== null &&
                        attendeLabDetails.ComputeVMUsage === true &&
                        vmUsageList.UptimeLimit !== null &&
                        vmUsageList.RemainingUptime !== null &&
                        vmUsageList.RemainingUptime === '00:00:00')) ? 'cursor: not-allowed' : ''"
                        tooltip="{{ 'GO_TO' | translate }} {{ row?.ResourceName }}" (buttonClick)="openVMandGitDoc(row)"
                        *ngIf="
                                        showLaunchButton &&
                                        this?.internalId !== ''
                                      " [disabled]="
                                        row?.InternalId === this.internalId ||
                                        !['VM running', 'running'].includes(row.Status) ||
                                        (row.Status !== null &&
                                          attendeLabDetails.ComputeVMUsage === true &&
                                          vmUsageList.UptimeLimit !== null &&
                                          vmUsageList.RemainingUptime !== null &&
                                          vmUsageList.RemainingUptime === '00:00:00')
                                      "
                        [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-sm mr-2 mb-2 '">
                        <i alt="" role="img" aria-label="img" aria-hidden="true" class="fas fa-external-link-alt"></i>
                      </teams-button>
                    </td>
                  </ng-container>
                </tr>
                <ng-container>

                  <tr class="inner-table">
                    <td colspan="5">
                      <table aria-label="Lab Resource Table" class="table" tabindex="0">
                        <div class="collapse-resources" *ngIf="row?.expand">
                          <div class="inner-dns-details">
                            <tr class="w-100 d-flex" *ngIf="attendeLabDetails.CloudPlatformId !== 3">
                              <th>
                                {{ 'DNS_NAME' | translate }}
                              </th>
                              <td colspan="4" class="word-break">
                                {{ row?.DNSName }}
                                <a *ngIf="row.DNSName" role="button" ngxClipboard
                                  tooltip="{{'COPY' | translate}} {{'DNS_NAME' | translate}}" [cbContent]="row.DNSName" class=""
                                  href="javascript:void(0)" attr.aria-label="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                                  (click)="copyContent()">
                                  <i role="img" aria-hidden="true" aria-label="img" alt=""
                                    class="copyelmnt fa fa-clone"></i>
                                </a>
                              </td>
                            </tr>
                            <tr class="w-100 d-flex" *ngIf="row?.PublicIpAddress">
                              <th>
                                {{ "IP_ADDRESS" | translate}}
                              </th>
                              <td colspan="4" class="word-break">
                                {{ row?.PublicIpAddress }}
                                <a *ngIf="row.PublicIpAddress" role="button" ngxClipboard
                                  tooltip="{{'COPY' | translate}} {{'IP_ADDRESS' | translate}}" [cbContent]="row.PublicIpAddress" class=""
                                  href="javascript:void(0)" attr.aria-label="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                                  (click)="copyContent()">
                                  <i role="img" aria-hidden="true" aria-label="img" alt=""
                                    class="copyelmnt fa fa-clone"></i>
                                </a>
                              </td>
                            </tr>
                          </div>
                          <div class="hyperv-table" *ngIf="row.ContainsHyperV">
                            <tr>
                              <th>{{ 'NAME' | translate }}</th>
                              <th>{{ 'STATUS' | translate }}</th>
                              <th class="hide-column">
                                {{ 'DNS_NAME' | translate }}
                              </th>
                              <th *ngIf="attendeLabDetails.EnableResourceOperations">
                                {{ 'ACTIONS' | translate }}
                              </th>
                            </tr>
                            <tbody *ngIf="vmUsage">
                              <ng-container *ngFor="let hyperv of row.HyperVVM; let hyperIndex = index">

                                <tr>
                                  <ng-container *ngIf="!hyperv.IsDynamicRG">
                                    <td>
                                      <div class="d-flex align-items-center vm-name"
                                        [ngClass]="{'cursor-pointer': isMobile}"
                                        (click)="isMobile ? collpaseExpand(hyperv) : ''">
                                        <i *ngIf="isMobile" class="fas fa-chevron-down mr-3" role="img" aria-label="img"
                                          aria-hidden="true"></i>
                                        <span>
                                          {{ hyperv.ResourceName }}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <span *ngIf="
                                      hyperv.Status === '' ||
                                      hyperv.Status === null
                                    ">{{ 'UPDATING_STATUS' | translate }}</span>
                                      <span *ngIf="
                                      hyperv.Status !== '' &&
                                      hyperv.Status !== null
                                    ">{{ hyperv.Status }}</span>
                                      <span *ngIf="
                                      vmUsageList.UptimeLimit !== null &&
                                      vmUsageList.RemainingUptime !== null &&
                                      vmUsageList.RemainingUptime === '00:00:00' &&
                                      (hyperv.Status === 'VM deallocated' ||
                                        hyperv.Status === 'Deallocating' ||
                                        hyperv.Status === 'VM deallocating')
                                    ">-&nbsp;{{
                                        'RUNTIME_LIMIT_EXHAUSTED_STATUS'
                                        | translate
                                        }}</span>
                                    </td>

                                    <td *ngIf="!docActions" class="hide-column" style="word-break: break-word">
                                      {{ hyperv.DNSName }}
                                      <a *ngIf="hyperv.DNSName" role="button" ngxClipboard
                                        tooltip="{{ 'COPY' | translate }} {{'DNS_NAME' | translate}}" [cbContent]="hyperv.DNSName"
                                        class="" href="javascript:void(0)" attr.aria-label="{{
                                      'COPY' | translate
                                    }} username" (click)="copyContent()">
                                        <i role="img" aria-hidden="true" aria-label="img" alt=""
                                          class="copyelmnt fa fa-clone"></i>
                                      </a>
                                    </td>
                                    <td class="text-left vm-buttons" header-class="'text-left'" *ngIf="
                                    attendeLabDetails.EnableResourceOperations
                                  ">
                                      <teams-button [ariaLabel]="'Start'" [tooltip]="'START' | translate" id="{{ 'START' | translate }}
                                      {{ hyperv.ResourceGroupName }}
                                      {{ hyperv.ResourceName }}" (buttonClick)="
                                      virtualMachineOperations(hyperv, '7')
                                    " [style]="(![
                                    'Off',
                                    'Saved',
                                    'Paused'
                                  ].includes(hyperv.Status) ||
                                  (attendeLabDetails.ComputeVMUsage ===
                                    true &&
                                    vmUsageList.UptimeLimit !== null &&
                                    vmUsageList.RemainingUptime !== null &&
                                    vmUsageList.RemainingUptime === '00:00:00')) ? 'cursor: not-allowed' : ''" [classList]="
                                      'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'
                                    " [disabled]="
                                      ![
                                        'Off',
                                        'Saved',
                                        'Paused'
                                      ].includes(hyperv.Status) ||
                                      (attendeLabDetails.ComputeVMUsage ===
                                        true &&
                                        vmUsageList.UptimeLimit !== null &&
                                        vmUsageList.RemainingUptime !== null &&
                                        vmUsageList.RemainingUptime === '00:00:00')
                                    ">
                                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
                                      </teams-button>
                                      <teams-button [ariaLabel]="'HYPERV_RESET' | translate"
                                        [tooltip]="'HYPERV_RESET' | translate" id="{{ 'HYPERV_RESET' | translate }}{{
                                      hyperv.ResourceGroupName
                                    }}{{ hyperv.ResourceName }}" (buttonClick)="
                                      virtualMachineOperations(hyperv, '9')
                                    " [classList]="
                                      'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'
                                    " [style]="(!['VM running', 'running', 'Running'].includes(
                                      hyperv.Status
                                    ) ||
                                    (hyperv.Status !== null &&
                                      attendeLabDetails.ComputeVMUsage ===
                                        true &&
                                      vmUsageList.UptimeLimit !== null &&
                                      vmUsageList.RemainingUptime !== null &&
                                      vmUsageList.RemainingUptime === '00:00:00')) ? 'cursor: not-allowed' : ''"
                                        [disabled]="
                                      !['VM running', 'running', 'Running'].includes(
                                        hyperv.Status
                                      ) ||
                                      (hyperv.Status !== null &&
                                        attendeLabDetails.ComputeVMUsage ===
                                          true &&
                                        vmUsageList.UptimeLimit !== null &&
                                        vmUsageList.RemainingUptime !== null &&
                                        vmUsageList.RemainingUptime === '00:00:00')
                                    ">
                                        <i role="img" alt="" aria-label="img" aria-hidden="true"
                                          class="fas fa-sync-alt"></i>
                                      </teams-button>
                                      <teams-button [ariaLabel]="'SHUT_DOWN' | translate"
                                        [tooltip]="'SHUT_DOWN' | translate" id="{{'SHUT_DOWN' | translate}}{{
                                      hyperv.ResourceGroupName
                                    }}{{ hyperv.ResourceName }}" (buttonClick)="
                                      virtualMachineOperations(hyperv, '8')
                                    " [classList]="
                                      'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'
                                    " [style]="(![
                                    'Running',
                                    'Paused',
                                    'Saved'
                                  ].includes(hyperv.Status) ||
                                  (hyperv.Status !== null &&
                                    attendeLabDetails.ComputeVMUsage ===
                                      true &&
                                    vmUsageList.UptimeLimit !== null &&
                                    vmUsageList.RemainingUptime !== null &&
                                    vmUsageList.RemainingUptime === '00:00:00')) ? 'cursor: not-allowed' : ''" [disabled]="
                                      ![
                                        'Running',
                                        'Paused',
                                        'Saved'
                                      ].includes(hyperv.Status) ||
                                      (hyperv.Status !== null &&
                                        attendeLabDetails.ComputeVMUsage ===
                                          true &&
                                        vmUsageList.UptimeLimit !== null &&
                                        vmUsageList.RemainingUptime !== null &&
                                        vmUsageList.RemainingUptime === '00:00:00')
                                    ">
                                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
                                      </teams-button>
                                      <teams-button role="button" aria-label="Launch Lab" tooltip="{{
                                      'LAUNCH_VM_IN_BROWSER' | translate
                                    }}" (buttonClick)="openVMLaunchURL(hyperv)" [classList]="
                                      'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'
                                    " *ngIf="
                                      hyperv.VMLaunchURL && showVMLaunchButton
                                    " [style]="( !['VM running', 'running', 'Running'].includes(
                                      hyperv.Status
                                    ) ||
                                    (hyperv.Status !== null &&
                                      attendeLabDetails.ComputeVMUsage ===
                                        true &&
                                      vmUsageList.UptimeLimit !== null &&
                                      vmUsageList.RemainingUptime !== null &&
                                      vmUsageList.RemainingUptime === '00:00:00')) ? 'cursor: not-allowed' : ''"
                                        [disabled]="
                                      !['VM running', 'running', 'Running'].includes(
                                        hyperv.Status
                                      ) ||
                                      (hyperv.Status !== null &&
                                        attendeLabDetails.ComputeVMUsage ===
                                          true &&
                                        vmUsageList.UptimeLimit !== null &&
                                        vmUsageList.RemainingUptime !== null &&
                                        vmUsageList.RemainingUptime === '00:00:00')
                                    ">
                                        <i alt="" role="img" aria-label="img" aria-hidden="true"
                                          class="fas fa-external-link-alt"></i>
                                      </teams-button>
                                      <teams-button aria-label="Launch url" role="button" tooltip="{{ 'GO_TO' | translate }} {{
                                      hyperv?.ResourceName
                                    }}" (buttonClick)="openVMandGitDoc(hyperv)" *ngIf="
                                      showLaunchButton &&
                                      vmUsage?.ResourceURI !== null &&
                                      vmUsage?.ResourceURI !== '' &&
                                      this?.internalId !== ''
                                    " [style]="(hyperv?.InternalId === this.internalId ||
                                    !['VM running', 'running', 'Running'].includes(hyperv.Status)  ||
                                    (hyperv.Status !== null &&
                                      attendeLabDetails.ComputeVMUsage ===
                                        true &&
                                      vmUsage.UptimeLimit !== null &&
                                      vmUsage.RemainingUptime !== null &&
                                      vmUsage.RemainingUptime === '00:00:00')) ? 'cursor: not-allowed' : ''"
                                        [disabled]="
                                      hyperv?.InternalId === this.internalId ||
                                      !['VM running', 'running', 'Running'].includes(hyperv.Status)  ||
                                      (hyperv.Status !== null &&
                                        attendeLabDetails.ComputeVMUsage ===
                                          true &&
                                        vmUsage.UptimeLimit !== null &&
                                        vmUsage.RemainingUptime !== null &&
                                        vmUsage.RemainingUptime === '00:00:00')
                                    " [classList]="
                                      'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-sm mr-2 mb-2 '
                                    ">
                                        <i alt="" role="img" aria-label="img" aria-hidden="true"
                                          class="fas fa-external-link-alt"></i>
                                      </teams-button>
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <td colspan="4" class="border-0 p-0 child-container inner-dns-details">
                                    <div class="hyperv-dns-detail" *ngIf="hyperv?.expand">
                                  <th>
                                    {{ 'DNS_NAME' | translate }}
                                  </th>
                                  <td colspan="4" class="word-break border-0">
                                    {{ hyperv?.DNSName }}
                                    <a *ngIf="hyperv.DNSName" role="button" ngxClipboard
                                      tooltip="{{'COPY' | translate}} {{'DNS_NAME' | translate}}" [cbContent]="hyperv.DNSName" class=""
                                      href="javascript:void(0)" attr.aria-label="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                                      (click)="copyContent()">
                                      <i role="img" aria-hidden="true" aria-label="img" alt=""
                                        class="copyelmnt fa fa-clone"></i>
                                    </a>
                                  </td>
                          </div>
                    </td>
                  </tr>
                </ng-container>
            </tbody>
        </div>
      </div>
      </table>
      </td>
      </tr>

      </ng-container>
      </ng-container>
    </ng-container>
      </tbody>
      <tr *ngIf="!isVMLoading &&  (vmUsageList && vmUsageList.length>0 && vmUsageList[0]?.ResourceType === null)">
        <td colspan="3" translate="NO_RECORDS_FOUND" style="border-top: 0"></td>
      </tr>
      </table>
    </div>
</div>
<div class="modal" *ngIf="modelShow" [class.validation]="modelShow" id="validation" appTrapFocus>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" aria-label="modal title">
          {{ modalData.title | translate }}
        </h4>
        <teams-button aria-label="Close" role="button" [classList]="'close'" (buttonClick)="modelShow = false">
          &times;
        </teams-button>
      </div>
      <div class="modal-body" aria-label="modal description">
        <p>
          {{ modalData.description | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <teams-button aria-label="Confirm" (buttonClick)="modelShow = false">
          {{ "OK" | translate }}
        </teams-button>
      </div>
    </div>
  </div>
</div>
</div>
<!-- VM labs section closed -->
<!-- sql Pool section started -->
<div id="sqlPool-section" class="square-box" *ngIf="showSqlPool">
  <div class="heading-box">
    <h3 class="heading" role="heading" aria-level="3">{{'SYNAPSE_SQL_POOL' |translate}}</h3>
    <p role="heading" aria-level="4" *ngIf="show_sqlPooltime">
      {{'MAXIMUM_UPTIME_LIMIT'|translate}}{{ " (hh:mm) : " + sqlPool_uptimeLimit }}
    </p>
    <p role="heading" aria-level="4" *ngIf="show_sqlPooltime">
      {{'REMAINING_UPTIME'|translate}} {{ " (hh:mm) : " + sqlPool_remainingTime }}
      <span id="sql-tooltip" class="info-tooltip" [ngClass]="{ timeReached: sqlPool_remainingTime === '00:00' }"
        tooltip="{{ SQLtooltipText | translate }}" tabindex="0" attr.aria-label="{{ SQLtooltipText | translate }}"><i
          class="fas fa-info-circle"></i></span>
    </p>
  </div>
  <div class="sql-group">
    <div class="portlet-body">
      <div class="table-responsive">
        <table aria-label="Sql Pool Table" class="table" tabindex="0">
          <tr>
            <th>{{ "NAME" | translate }}</th>
            <th>{{ "STATUS" | translate }}</th>
          </tr>
          <tr let *ngFor="let row of sqlResourceList">
            <td>
              {{ row.ResourceName }}
            </td>
            <td>
              <span *ngIf="row.Status === '' || row.Status === null">
                {{ "UPDATING_STATUS" | translate }}
              </span>
              <span *ngIf="row.Status !== '' && row.Status !== null">
                {{ row.Status }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- sql Pool section closed -->
<!-- Section if no rsources are available -->
<div id="noResources" class="square-box" *ngIf="!showSqlPool && !isVMLoading && !vmUsageList">
  <p>{{ "NO_ACTIVE_RESOURCES" | translate }}</p>
</div>
</ng-template>
</div>

<div class="ai-section" *ngIf="openAiDetail">
  <div class="ai-head">
    <span role="heading" aria-level="2" class="heading"> {{"OPENAI_USAGE" | translate}} </span>
    <div class="text-align-end">
      <button id="refresh-all-btn" role="button" class="btn btn-sm ts-btn-fluent-primary btn-primary refresh text-right"
        tabindex="0" (click)="loadOpenAI()" (keyup.enter)="loadOpenAI()"
        attr.aria-label="{{ 'REFRESH_ALL_VM' | translate }}">
        <span style="font: normal normal normal 14px/1" class="fas fa-sync-alt vm-action-buttons mr-2 mb-2 refresh-vm"
          [ngClass]="{ rotate: openAiLoading }">
        </span>
        <span>{{ "BUTTON_REFRESH" | translate }}</span>
      </button>
      <span class="updated-time mt-2"> {{"LAST_UPDATED" | translate}} {{openAiUpdateTime}}</span>
    </div>
  </div>
  <div class="ai-body">
    <div class="mt-3 d-flex justify-content-between">
      <span>
        {{"CREDIT" | translate}}
      </span>
      <span>
        <b class="text-primary">{{ openAiDetail.RemainingCredit >= 0 ? openAiDetail.RemainingCredit : 0}} 
          {{"USD" | translate}}</b> {{"LEFT_OF" | translate}} {{openAiDetail.AllocatedCredit}} {{"USD" | translate}}
      </span>
    </div>
    <div class="progress mt-2 mb-3">
      <div class="progress-bar" 
        [ngStyle]="{ width: openAiDetail.UsedCredit ? (openAiDetail.UsedCredit * 100/openAiDetail.AllocatedCredit) + '%' : '0%' }">
      </div>
    </div>
  </div>
  <div class="ai-footer d-flex justify-content-between">
    <span>
      {{"TOKEN_CONSUMED" | translate}}
    </span>
    <span>
      {{openAiDetail.TokenCount}}
    </span>
  </div>
  <div class="ai-footer d-flex" *ngIf="openAiDetail.RemainingCredit === 0">
    <div>
      <i class="fas fa-info-circle text-danger"></i>
    </div>
    <span class="ml-3 text-danger">
      {{"CREDIT_EXHAUSTAED" | translate}}
    </span>
  </div>
</div>

<ng-container *ngIf="blockedTabModalShow">
  <cloudlabs-dialoge-prompt appTrapFocus appAutoFocusModal appRefocusPreviousEl *ngIf="blockedTabModalShow"
    [baseZIndex]="9999902" [isDialog]="blockedTabModalShow" (popupResponse)="blockedTabModalShow=false">
    <div header class="subheading">
      {{ 'BLOCKEDPOPUP_INFO_INSTRUCTIONS' | translate }}
    </div>
    <div content class="">
      <div *ngIf="browserName === 'Apple Safari'" class="text-left"
        [innerHTML]="'BLOCKEDPOPUP_MESSAGE_APPLE' | translate: {browser: browserName, baseURLName: baseURL}"></div>
      <div *ngIf="browserName === 'Mozilla Firefox'" class="text-left"
        [innerHTML]="'BLOCKEDPOPUP_MESSAGE_FIREFOX' | translate: {browser: browserName, baseURLName: baseURL}"></div>
      <div *ngIf="browserName !== 'Mozilla Firefox' && browserName !== 'Apple Safari'" class="text-left"
        [innerHTML]="'BLOCKEDPOPUP_MESSAGE' | translate: {browser: browserName, baseURLName: baseURL}"></div>
    </div>
    <div footer class="col-md-12">
      <teams-button
        [ngClasses]="environment?.appType === 'lab'? 'ts-btn-fluent-primary': 'ts-btn-fluent-white pt-2 pb-2'" class=""
        [classList]="'mt-2 btn ts-btn-fluent ts-btn-grey text-nowrap'" [title]="'OK' | translate"
        (click)="blockedTabModalShow = false">
      </teams-button>
    </div>
  </cloudlabs-dialoge-prompt>
</ng-container>