import { Inject, Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './_services/auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private environment;

  constructor(private router: Router,
    private authService: AuthService, @Inject('environment') environment) {
    this.environment = environment;
  }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (this.environment.appType && this.environment.appType === 'attendee') {
      localStorage.setItem('context', 'CloudLabs');
    } else if (this.environment.appType && this.environment.appType === 'exam') {
      localStorage.setItem('context', 'PracticeTest');
    }

    if (currentUser) {
      // logged in so return true
      return true;
    } else if (this.authService.isTeamsAppUser()) {
      return true;
    } else {
      sessionStorage.setItem('singleSignOn', 'yes');
      localStorage.setItem('preUrl', state.url);
      if (!state.url.includes('#id_token')) {
        localStorage.setItem('myUrl', state.url);
      }
      this.router.navigate(['']);
      return false;
      
    }
    // not logged in so redirect to login page with the return url
  // this.authService.logout();
    // return false;
  }

}
