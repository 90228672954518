<div class="navbar navbar-expand-lg py-0 headerBg" role="banner">
    <div class="logo-m mr-2" *ngIf="(environment.appType === appType.LAB || isSplitWindow) && !lab?.EnableCustomLabDetailsPage">
        <a *ngIf="customLogo && customLogo.includes('CloudLabs')" class="navbar-brand" href="javascript:void(0)" style="margin-left: 4px"
            aria-label="CloudLabs Logo" tabindex="-1">
            <img alt="CloudLabs Logo" class="mr-1" [src]="customLogo"
                style="height: 24px; width: auto; background: transparent" />
        </a>
        <a *ngIf="customLogo && !customLogo.includes('CloudLabs')" class="navbar-brand" href="javascript:void(0)" style="margin-left: 4px"
            aria-label="Microsoft Logo" tabindex="-1">
            <img alt="Microsoft Logo" class="mr-1" [src]="customLogo"
                style="height: 20px; width: auto; background: transparent" />
        </a>
        <a *ngIf="!customLogo" class="navbar-brand" href="javascript:void(0)" style="margin-left: 4px"
            aria-label="CloudLabs logo" tabindex="-1">
            <img alt="CloudLabs logo" class="mr-1" src="/assets/images/CloudLabs_Fullwhite.png"
                style="height: 24px; width: auto; background: transparent" />
        </a>
    </div>
    <span class="ml-2 separator" *ngIf="environment.appType === appType.LAB && showTitle && !lab?.EnableCustomLabDetailsPage">|</span>
    <div class="title-d" *ngIf="showTitle" [ngClass]="{'pl-4':(environment.appType === appType.LAB && !lab?.EnableCustomLabDetailsPage)}">
        {{lab?.DisplayName ? lab?.DisplayName : lab?.Title}}
    </div>
    <div class="right-block ml-auto" [ngClass]="{'mb-1': environment.appType !== appType.ATTENDEE}">
        <div class="dropdown lang vm-selection text-right mt-4 mr-4" *ngIf="vmDetail && vmDetail.length > 1">
            <teams-button [classList]="'dropdown-toggle'" [style]="'font-size: small'"
                attr.aria-label="change vm {{ currentVm?.VMName }} selected" data-toggle="dropdown"
                aria-haspopup="listbox">
                <i alt="" role="img" aria-label="img" aria-hidden="true" class="mr-1"
                      [ngClass]="currentVm?.Type === 'virtualMachines/hyperVm' ? 'fas fa-server' : 'fas fa-desktop' "></i>
                {{ currentVm.FriendlyName + ': ' + currentVm?.VMName }} <i class="ml-1 fas fa-caret-down"></i>
            </teams-button>
            <div id="langDropdown" tabindex="-1" class="dropdown-menu" role="listbox">
                <a role="option" *ngFor="let vm of vmDetail" style="text-decoration: none" class="vm-items dropdown-item navi-link ts-btn-fluent-white border-0"
                    attr.aria-label="{{ vm.FriendlyName + ': ' + vm.VMName }}" href="javascript:void(0)" (click)="switchVm(vm)">
                    <i alt="" role="img" aria-label="img" aria-hidden="true" class="mr-2"
                      [ngClass]="vm.Type === 'virtualMachines/hyperVm' ? 'fas fa-server' : 'fas fa-desktop' "></i>
                    {{ vm.FriendlyName + ': ' + vm.VMName }}
                </a>
            </div>
        </div>
        <div class="end-date pr-3" *ngIf="endDateDetails && endDateDetails?.endDate && timerInSeconds > 0"
            [title]="('MESSAGE_ONDEMAND_LAB_END_DATE' | translate) +' '+ (endDateDetails?.endDate | date: 'dd MMM y, hh:mm a') +' '+ (endDateDetails?.timeZone)" role="button" data-toggle="tooltip" placement="bottom-left"
            trigger="hover">
            <i class="fa fa-hourglass-end" style="margin-right: 0.25em;" aria-hidden="true"></i>
            {{endDateDetails?.endDate | date: 'dd MMM y, hh:mm a'}} {{endDateDetails?.timeZone}}
        </div>
        <div class="action-items pr-3" *ngIf="(endDateDetails === null || endDateDetails?.endDate === null) && timerInSeconds > 0">
            <i class="fa fa-clock-o" style="margin-right: 0.25em;" aria-hidden="true"></i>
            <span style="font-size:small">{{countDown | async | formatTime}}</span>
        </div>
        <span class="separator" *ngIf="(endDateDetails === null || endDateDetails?.endDate === null) && timerInSeconds > 0">|</span>
        <div class="action-items pr-3 pl-3" *ngIf="updatedActionList.length > 0">
            <ng-container *ngFor="let action of updatedActionList ; let i = index">
                <a role="button" href="javascript:void(0)" 
                    [title]="action.name | translate" (click)="actionClick(action.action)">
                    <i [class]="action.icon" aria-hidden="true"></i>
                </a>&nbsp; &nbsp;
                <!-- <i [class]="action.icon" [title]="action.name | translate" aria-hidden="true"
                    (click)="actionClick(action.action)"></i> &nbsp; -->
            </ng-container>
            <cloudlabs-toggle-header class="mr-3" *ngIf="labLaunched"></cloudlabs-toggle-header>
            
            <!-- <div class="dropdown lang more-section text-right mt-4" *ngIf="showMore"
                [ngClass]="labLaunched ? 'ml-3' : '' ">
                <teams-button [classList]="'dropdown-toggle'" [style]="'font-size: small'" data-bs-auto-close="false"
                    data-toggle="dropdown" aria-haspopup="listbox" [titleText]="'MORE' | translate">
                          <i class="fas fa-ellipsis-h"></i>
                </teams-button>
                <div id="langDropdown" tabindex="-1" class="dropdown-menu" role="listbox" data-bs-auto-close="false">
                    <div class="dropdown-item" role="option">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                [(ngModel)]="currCopyOption" (change)="switchCopyOption()">
                            <label class="custom-control-label cursor-pointer" for="customSwitch1" (click)="$event.stopPropagation(); ">{{'FILE_TRANSFER' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="dropdown d-inline" *ngIf="showMore" ngbDropdown
                #actionDropDn="ngbDropdown" [autoClose]="true" [placement]="'bottom-end'">
                <button class="btn dropdown-toggle topbar-item p-0"
                    ngbDropdownToggle
                    [attr.aria-label]="'MORE' | translate" [title]="'MORE' | translate">
                    <i class="fas fa-ellipsis-h" aria-hidden="true"></i>
                </button>
                <div ngbDropdownMenu class="toggle-action-dropdown dropdown-menu">
                    <a ngbDropdownItem href="javascript:void(0)">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                [(ngModel)]="currCopyOption" (change)="switchCopyOption()">
                            <label class="custom-control-label cursor-pointer" for="customSwitch1" (click)="$event.stopPropagation(); ">{{'FILE_TRANSFER' | translate}}</label>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="dropdown lang text-right mt-4 mr-2" *ngIf="environment.appType !== appType.ATTENDEE && !lab?.EnableCustomLabDetailsPage" [ngClass]="{'pl-3':updatedActionList.length <= 0}">
            <teams-button [classList]="'dropdown-toggle'" [style]="'font-size: small'"
                attr.aria-label="change Language {{ languageAriaLabel }} selected" data-toggle="dropdown"
                aria-haspopup="listbox">
                <i role="option" class="pr-1 fa fa-globe" aria-hidden="true"></i>{{ showLanguage }}
            </teams-button>
            <div id="langDropdown" tabindex="-1" class="dropdown-menu" role="listbox">
                <a role="option" *ngFor="let language of languages" style="text-decoration: none" class="dropdown-item"
                    attr.aria-label="{{ language.Name }}" href="javascript:void(0)"
                    (click)="switchLanguage(language.Code)">{{ language.Description }}</a>
            </div>
        </div>

        <span class="separator" *ngIf="showTitle && environment.appType === appType.ATTENDEE">|</span>
        
        <div class="action-items" *ngIf="showTitle && environment.appType === appType.ATTENDEE">
            <teams-button [classList]="'btn p-0 text-white'" (buttonClick)="goBack()" [ariaLabel]="'CLOSE' | translate" >
                <i class="fas fa-times pr-1 pl-2"></i>
                {{'CLOSE' | translate}}
            </teams-button>
            <!-- <span role="button" 
                aria-label="go to back" 
                tabindex="0" 
                class="back-opt cursor-pointer"
                (click)="goBack()">
                <i class="fas fa-times pr-1 pl-2"></i>
                {{'CLOSE' | translate}}
            </span> -->
        </div>

    </div>
</div>
<div class="header-pad"></div>