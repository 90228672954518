/* eslint-disable no-useless-escape */
import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { LabService } from '../../../../../services/lab.service';
import { TranslateService } from '@ngx-translate/core';
import { copyToClipboard } from '@utility';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cloudlabs-databricks-layout',
  templateUrl: './databricks-layout.component.html',
  styleUrls: ['./databricks-layout.component.scss'],
})
export class DatabricksLayoutComponent implements AfterViewInit {
  @ViewChild('iframe') iframe!: ElementRef<HTMLIFrameElement>;

  @Input() vCode: string;
  @Input() isDraggable: boolean;
  //@Input() initialDeployment:any;

  //public signInResponse: any;

  @HostListener('document:click', ['$event'])
  async handleClick($event) {
    if (
      $event.target.id === 'copyelement' ||
      ($event.target as HTMLElement).classList.contains('copyelement')
    ) {
      const brRegex = /<br\s*[\/]?>/gi;
      const copyData =
        $event.target.innerText || $event.target.firstChild.innerText;
      const txtdata = copyData.replace(brRegex, '\r\n');
      await copyToClipboard(txtdata);

      this.tostrSrv.success(
        '<p role="alert">' +
          this.translateSrv.instant('MESSAGE_COPIED') +
          '</p>',
        null,
        {
          enableHtml: true,
          timeOut: 3000,
        }
      );
    }
  }

  @HostListener('window:keydown', ['$event'])
  async onkeyPress($event) {
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (
        $event.target.id === 'copyelement' ||
        ($event.target as HTMLElement).classList.contains('copyelement')
      ) {
        const brRegex = /<br\s*[\/]?>/gi;
        const copyData =
          $event.target.innerText || $event.target.firstChild.innerText;
        const txtdata = copyData.replace(brRegex, '\r\n');
        await copyToClipboard(txtdata);

        this.tostrSrv.success(
          '<p role="alert">' +
            this.translateSrv.instant('MESSAGE_COPIED') +
            '</p>',
          null,
          {
            enableHtml: true,
            timeOut: 3000,
          }
        );
      }
    }
  }

  constructor(
    private _labService: LabService,
    private tostrSrv: ToastrService,
    private translateSrv: TranslateService,
  ) {
    this.isDraggable = false;
    //this.signInResponse = null;
  }

  ngAfterViewInit(): void {
    //const workSpaceLink = this.initialDeployment?.AllocatedTestDriveViewModalDetails[0]?.DeploymentOutputValues[0]?.OutputValues?.find(x=>x.Name==='Databricks Workspace URL');
    this._labService
      .getDatabricksFrameForm({ voucherCode: this.vCode })
      .subscribe((result) => {
        setTimeout(() => {
          this.setIframeReady(this.iframe, result.HtmlResponse);
        }, 100);
        // this.signInResponse = result;
        // setTimeout(() => {
        //   this.iframe, result.HtmlResponse;
        //   this.setIframeReady(this.iframe, result.HtmlResponse, result , workSpaceLink?.Value);
        // }, 2000);
      });
  }

  //result, workSpaceLink=''
  private setIframeReady(iframe, iframeCode): void {
    // const dbAuthentcateStatus = sessionStorage.getItem('dbAuthentcateStatus');
    // if(!dbAuthentcateStatus){
    //   sessionStorage.setItem("dbAuthentcateStatus","true");
    // }
    // this.iframe.nativeElement.src = workSpaceLink
    const win: Window = iframe.nativeElement.contentWindow;
    const doc: Document = win.document;
    doc.open();
    doc.write(iframeCode);
    doc.close();
  }
}
