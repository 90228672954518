<div class="card card-min-height" *ngIf="!loading">
  <!--Title-->
  <!-- <div class="course-header-title"
    *ngIf="isMylearning &&  (['GatheringInfo','Initiation',operationModes.AZUREPASSINITIATION,operationModes.GRABAZUREPASS,operationModes.UNINITIATED,operationModes.EXPIRED].includes(operationMode) ||  [9, 10].includes(lab.StatusId)) ">
    <div class="back-arrow cursor-pointer mt-2">
      <span role="button" aria-label="go to back" tabindex="0" (keydown.enter)="goBack()" (click)="goBack()">
        <i class="fas fa-arrow-left"></i>
      </span>
    </div>
    <h3 role="heading" aria-level="1">{{lab.Title}}</h3>
  </div> -->
  <!-- <div class="course-header-title" *ngIf="isMylearning && [
        operationModes.FAILED,
        operationModes.POLICYFAILEDAFTERDEPLOYMENT,
        operationModes.DEALLOCATING,
        operationModes.DEPLOYED,
        operationModes.DEALLOCATED,
        operationModes.ACTIVATIONCLOSED].includes(operationMode)">
        <div class="back-arrow cursor-pointer mt-2">
          <span role="button" aria-label="go to back" tabindex="0" (keydown.enter)="goBack()" (click)="goBack()">
              <i class="fas fa-arrow-left"></i>
          </span>
        </div>
    <h3>{{lab.Title}}</h3>
  </div> -->
  <!-- <ng-container *ngIf="
          operationMode === operationModes.INPROGRESS ||
          operationMode === operationModes.AZUREPASSUSERDETAILS
        " class="portlet box blue">
    <div class="course-header-title" *ngIf="isMylearning">
      <div class="back-arrow cursor-pointer mt-2">
        <span role="button" aria-label="go to back" tabindex="0" (keydown.enter)="goBack()" (click)="goBack()">
          <i class="fas fa-arrow-left"></i>
        </span>
      </div>
      <h3 role="heading" aria-level="1">{{lab.Title}}</h3>
    </div>
  </ng-container> -->
  <div class="multi-select mb-4 mx-auto"
    *ngIf="environment.appType === 'trial' &&  (['GatheringInfo','Initiation',operationModes.UNINITIATED,operationModes.EXPIRED].includes(operationMode) ||  [9, 10].includes(lab.StatusId)) ">
    <h1 class="trial-title">{{ labClustersLabData?.DisplayName || labClustersLabData?.Title }}</h1>
  </div>
  <div *ngIf="operationMode === operationModes.EXPIRED && isMylearning" class="row deployed-lab">
    <div class="col-md-8">
      <div class="alert " [ngClass]="{
              'alert-custom alert-light-danger' : isMylearning,
              'alert-danger' : !isMylearning
            }">
        <p class="mb-0 alert-text" translate="MESSAGE_INVITATION_EXPIRED"></p>
      </div>
    </div>
  </div>
  <div class="pb-2 cl-region" [ngClass]="{'mx-auto' : environment.appType === 'trial'}" *ngIf="
        lab.Regions !== null &&
        operationMode === operationModes.UNINITIATED &&
        !isSharedTestDrive &&
        lab.StatusId !== 9 &&
        lab.StatusId !== 10
      ">
    <label>{{ 'SELECT_REGION' | translate}}<span class="text-danger">*</span></label>
    <select class="form-control region-dropdown" role="listbox" aria-label="Please select a region for deployment"
      (change)="regionSelected($event)" id="selectedRegion" name="region" [(ngModel)]="selectedRegion">
      <option value="" selected disabled>{{'SELECT_REGION_DEPLOYMENT' | translate}}</option>
      <option *ngFor="let item of lab.Regions" [value]="item.Id">
        {{ item.RegionName }}
      </option>
    </select>
    <div class="cl-launch-lab" *ngIf="
                  operationMode === operationModes.UNINITIATED &&
                  !isSharedTestDrive &&
                  lab.StatusId !== 9 &&
                  lab.StatusId !== 10 &&
                  lab.StatusId !== 11 && isMylearning
                  && environment.appType !== 'trial'
                ">
      <div class="lab-container p-4">
        <div class="row">
          <div class="col-md-9">
            <div>{{ "LAUNCH_LAB_INFO" | translate }}</div>
          </div>
          <div class="col-md-3" style="margin: auto">
            <teams-button role="button" [disabled]="
                      (!lab?.UserSelectedRegion ||
                        lab?.UserSelectedRegion?.length === 0) &&
                      lab?.Regions !== null
                    " aria-label="Launch Lab" [classList]="'btn ts-btn-lg  ts-btn-fluent-primary launchBtn'"
              (buttonClick)="startLab()" style="margin: auto; display: block">
              {{ "BUTTON_LAUNCH_LAB" | translate }}
            </teams-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cl-grab-pass" *ngIf="
      operationMode === 'GrabAzurePass' &&
      !isSharedTestDrive &&
      lab.StatusId !== 9 &&
      lab.StatusId !== 10 && isMylearning
    ">
    <teams-button role="button" aria-label="Azure Pass"
      [classList]="'btn ts-btn-lg  ts-btn-fluent-primary margin-bottom-10'" (buttonClick)="grabAzurePass()"
      style="margin: auto; display: block">
      <i alt="" class="fa fa-ticket" style="color: #fff"></i> &nbsp;{{
      "BUTTON_GRAB_AZURE_PASS" | translate
      }}
    </teams-button>
  </div>
  <div class="multi-select mb-4 mx-auto" *ngIf="environment.appType === 'trial' && labClustersLabData?.ExtendedUserAttribute.length > 0 &&
        operationMode === operationModes.UNINITIATED &&
        !isSharedTestDrive &&
        lab.StatusId !== 9 &&
        lab.StatusId !== 10">
    <label class="font-weight-bolder"> {{labClustersLabData.ExtendedUserAttribute[0].DisplayName}} <span
        class="text-danger">*</span></label>
    <ul>
      <li class="hide-checkbox" *ngFor="let attribute of extendedAttribute; let i = index">
        <input type="checkbox" [id]="'myCheckbox' + i" [checked]="attribute?.DefaultValue"
          [disabled]="attribute?.IsReadOnly" (change)="selectSource(attribute)" />
        <label class="checkbox-label" [ngClass]="{'sources-disabled' : attribute?.IsReadOnly}" [for]="'myCheckbox' + i">
          <div class="check-icon"><span><i class="fa fa-check" aria-hidden="true"></i></span></div>
          <img [src]="attribute?.IconURL" />
          <p> {{attribute.Name}} </p>
        </label>
      </li>
    </ul>
    <span class="font-weight-bolder">{{ 'SOURCES_DATA_NOTE' | translate }}</span>
  </div>

  <div class="pb-2 trial-content" *ngIf="environment.appType === 'trial' && labClustersLabData?.ExtendedUserAttribute.length > 0 &&
        operationMode === operationModes.UNINITIATED &&
        !isSharedTestDrive &&
        lab.StatusId !== 9 &&
        lab.StatusId !== 10
      ">
    <div class="cl-launch-lab" *ngIf="
                  operationMode === operationModes.UNINITIATED &&
                  !isSharedTestDrive &&
                  lab.StatusId !== 9 &&
                  lab.StatusId !== 10 &&
                  lab.StatusId !== 11
                ">
      <div class="lab-container trial-lab mx-auto p-4">
        <div class="row">
          <div class="col-md-3 activate-trial">
            <teams-button role="button" [disabled]="launchButtonDisable" aria-label="Launch Lab"
              [classList]="'btn ts-btn-lg  ts-btn-fluent-primary launchBtn'" (buttonClick)="startTrial()"
              style="margin: auto; display: block">
              {{ "ACTIVATE_TRIAL" | translate }}
            </teams-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body p-0"
    [ngClass]="{'lab-card': ['GatheringInfo','Initiation'].includes(operationMode) && !videoUrl}">
    <ng-container *ngIf="
      [
        operationModes.UNINITIATED,
        operationModes.GRABAZUREPASS,
        operationModes.EXPIRED
      ].includes(operationMode)
    ">
      <div [ngClass]="{'cl-launch-lab': isMylearning}">
        <div
          *ngIf="lab?.StatusId !== 9 && lab?.StatusId !== 10 && lab?.StatusId !== 11 && !isMylearning && environment.appType !== 'trial'"
          class="lab-container p-4">
          <div class="row" *ngIf="
          operationMode === operationModes.UNINITIATED &&
          !isSharedTestDrive &&
          lab.StatusId !== 9 &&
          lab.StatusId !== 10 &&
          lab.StatusId !== 11 && !isMylearning
        ">
            <div class="col-md-9">
              <div>{{ "LAUNCH_LAB_INFO" | translate }}</div>
            </div>
            <div class="col-md-3" style="margin: auto">
              <teams-button role="button" [disabled]="
              (!lab?.UserSelectedRegion ||
                lab?.UserSelectedRegion?.length === 0) &&
              lab?.Regions !== null" [ariaLabel]="'Launch Lab'"
                [classList]="'btn ts-btn-lg  ts-btn-fluent-primary launchBtn'" (buttonClick)="startLab()"
                style="margin: auto; display: block">
                {{ "BUTTON_LAUNCH_LAB" | translate }}
              </teams-button>
            </div>
          </div>
          <!-- Lab is shared -->
          <div *ngIf="isSharedTestDrive" class="testing">
            <span role="alert" translate="MESSAGE_EMAIL_NOTIFICATION"></span>
            <a role="button" aria-label="refresh" (click)="refresh()" class="ml-3 fa fa-refresh" title="Refresh"></a>
          </div>

          <div class="">
            <teams-button role="button" aria-label="Azure Pass"
              [classList]="'btn ts-btn-lg  ts-btn-fluent-primary margin-bottom-10'" *ngIf="
            operationMode === 'GrabAzurePass' &&
            !isSharedTestDrive &&
            lab.StatusId !== 9 &&
            lab.StatusId !== 10
          " (buttonClick)="grabAzurePass()" style="margin: auto; display: block">
              <i alt="" class="fa fa-ticket" style="color: #fff"></i> &nbsp;{{
              "BUTTON_GRAB_AZURE_PASS" | translate
              }}
            </teams-button>
          </div>
          <div *ngIf="operationMode === operationModes.EXPIRED" class="alert " [ngClass]="{
        'alert-custom alert-light-danger' : isMylearning,
        'alert-danger' : !isMylearning
      }">
            <p class="mb-0 alert-text" translate="MESSAGE_INVITATION_EXPIRED"></p>
          </div>
        </div>
      </div>

      <!-- operationMode === operationModes -->

      <!-- Operation mode is expired -->
      <div *ngIf="[9, 10].includes(lab.StatusId)" class="alert" role="alert"
        [attr.aria-label]="'MESSAGE_ACTIVATION_CLOSED' | translate" [ngClass]="{
        'alert-custom alert-light-warning' : isMylearning,
        'alert-warning' : !isMylearning
      }">
        <h4 class="block alert-text" aria-hidden="true" aria-hidden="true">
          <i alt="" role="img" aria-label="img" aria-hidden="true" class="fa fa-exclamation-triangle"
            aria-hidden="true"></i>
          &nbsp; {{ "MESSAGE_ACTIVATION_CLOSED" | translate }}
        </h4>
        <p id="activation-closed-error-desc" aria-hidden="true" [innerHTML]="
          'MESSAGE_CONTACT_EMAIL' | translate: { Email: lab.ContactEmail }
        "></p>
      </div>

      <!-- Operation mode is expired -->

      <div *ngIf="[11].includes(lab.StatusId)" class="alert" role="alert" [ngClass]="{
      'alert-custom alert-light-warning' : isMylearning,
      'alert-warning' : !isMylearning
    }">
        <span class="alert-text" [innerHTML]="
          'REG_OPEN_LAB_VALIDATION_CLOSE_TEXT'
            | translate
              : { odlName: lab.Title, email: lab.ContactEmail }
        "></span>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-md-12 video embed-responsive embed-responsive-16by9" *ngIf="
      operationMode === operationModes.INITIATION && (environment.appType === 'attendee') && videoUrl"
        class="video embed-responsive embed-responsive-16by9">
        <iframe allowFullScreen="allowFullScreen" title="trial-video" class="embed-responsive-item" allow="autoplay"
          [src]="videoUrl | safe">
        </iframe>
      </div>
    </div>

    <div [id]="![operationModes.UNINITIATED, operationModes.INPROGRESS].includes(operationMode) ? 'main-landmark': ''"
      tabindex="-1" *ngIf="!isMylearning" [ngClass]="{
      'col-md-12':
        ([operationModes.UNINITIATED, operationModes.EXPIRED].includes(
          operationMode
        ) ||
        ![operationModes.UNINITIATED, operationModes.EXPIRED].includes(
          operationMode
        )),
        'd-flex flex-column mt-4': (environment.appType === 'attendee')
    }" class="labs-progress row">
      <!-- Independent Lab-->
      <div *ngIf="
        (operationMode === operationModes.INITIATION ||
        operationMode === operationModes.GATHERINGINFO) && !isMylearning
      " class="lab-container mx-auto p-4">
        <div role="heading" aria-level="2" style="align-items: center;
      display: flex;
      font-size: 20px;
      font-family: 'Segoe UI', sans-serif;
      font-weight: 500;
      line-height: 30px;
      color: #312F2F;" class="mb-4 loadLab">
          <cloudlabs-vm-spinner></cloudlabs-vm-spinner>
          <span role="alert">
            <span>&nbsp;
              {{
              operationMode === operationModes.GATHERINGINFO
              ? ("MESSAGE_INITIALIZING_ENVIRONMENT" | translate)
              : ("MESSAGE_PREPARING_ENVIRONMENT" | translate)
              }}</span>
          </span>
        </div>
        <ng-container *ngIf="operationMode === operationModes.INITIATION">
          <span role="text" class="timebar" appLabFocus [appProgressTime]="remainingduration"
            [attr.aria-label]="countDownTime?.i.value | timeTransform: progressBar">
            <span aria-hidden="true">
              <span>{{ "MESSAGE_ONDEMAND_LAB_READY_CUSTOM" | translate }} </span><span *ngIf="remainingMint"> 1 </span>
              <countdown *ngIf="!remainingMint" #cd [config]="remainingduration" (event)="handleCountDown($event)">
              </countdown><span *ngIf="showHours"> {{ 'HOURS' | translate:{singularPlural: remainingduration.leftTime |
                pluralize} }}</span>
              <span *ngIf="!showHours">
                <ng-container *ngIf="remainingMint">
                  {{ "MINUTES" | translate:{singularPlural: 1 | pluralize} }}
                </ng-container>
                <ng-container *ngIf="!remainingMint">
                  {{ "MINUTES" | translate:{singularPlural: remainingduration.leftTime | pluralize} }}
                </ng-container>
              </span>
            </span>
          </span>
          <div class="progress_1 pb-3" *ngIf="showProgress">
            <div class="indeterminate"></div>
          </div>
          <div class="progress mb-3" *ngIf="!showProgress">
            <div class="progress-bar" [ngStyle]="{ width: progressBar + '%' }">
              {{ progressBar > 90 ? 90 : progressBar }} %
            </div>
          </div>
        </ng-container>
      </div>
      <!-- video container -->
      <div class="col-md-12 video embed-responsive embed-responsive-16by9" *ngIf="
          operationMode === operationModes.INITIATION && (environment.appType === 'trial') && videoUrl"
        class="video embed-responsive embed-responsive-16by9">
        <iframe allowFullScreen="allowFullScreen" title="trial-video" class="embed-responsive-item" allow="autoplay"
          [src]="videoUrl | safe">
        </iframe>
      </div>


    </div>

    <div [id]="![operationModes.UNINITIATED, operationModes.INPROGRESS].includes(operationMode) ? 'main-landmark': ''"
      tabindex="-1" *ngIf="isMylearning" [ngClass]="{
      'col-md-12':
        ([operationModes.UNINITIATED, operationModes.EXPIRED].includes(
          operationMode
        ) ||
        ![operationModes.UNINITIATED, operationModes.EXPIRED].includes(
          operationMode
        )),
        'd-flex flex-column mt-4': (environment.appType === 'attendee')
    }">
      <!-- Attendee portal-->
      <div *ngIf="
          (operationMode === operationModes.INITIATION ||
          operationMode === operationModes.GATHERINGINFO) && isMylearning
        " class="lab-container mx-auto p-4">
        <div class="cl-gathering-info" role="alert" *ngIf="operationMode === operationModes.GATHERINGINFO">
          {{ "MESSAGE_INITIALIZING_ENVIRONMENT" | translate }}
        </div>
        <div role="heading" aria-level="2" style="align-items: center;
        display: flex;
        font-size: 20px;
        font-family: 'Segoe UI', sans-serif;
        font-weight: 500;
        line-height: 30px;
        color: #312F2F;" class="mb-4 loadLab">
          <span role="alert">
            <span *ngIf="operationMode !== operationModes.GATHERINGINFO">
              {{ "MESSAGE_PREPARING_ENVIRONMENT" | translate }}</span>
          </span>
        </div>
        <ng-container *ngIf="operationMode === operationModes.INITIATION">
          <span role="text" class="timebar" appLabFocus [appProgressTime]="remainingduration"
            [attr.aria-label]="countDownTime?.i.value | timeTransform: progressBar">
            <span aria-hidden="true">
              <span>{{ "MESSAGE_ONDEMAND_LAB_READY_CUSTOM" | translate }} </span>
              <div class="deploye-duration">
                <span *ngIf="remainingMint"> 1 {{ "MINUTES" | translate:{singularPlural: 1 | pluralize} }}</span>
                <countdown *ngIf="!remainingMint" #cd [config]="remainingduration" (event)="handleCountDown($event)">
                </countdown><span *ngIf="showHours"> {{ 'HOURS' | translate:{singularPlural: remainingduration.leftTime
                  | pluralize} }}</span>
                <span *ngIf="!showHours && !remainingMint"> {{ "MINUTES" | translate:{singularPlural:
                  remainingduration.leftTime | pluralize} }}</span>
              </div>
            </span>
          </span>
          <div class="progress_1 pb-3" *ngIf="showProgress">
            <div class="indeterminate"></div>
          </div>
          <div class="progress" [ngClass]="{'progress-height-cl': isMylearning}">
            <div class="progress-bar" role="progressbar" aria-label="progress bar" *ngIf="!showProgress"
              [ngStyle]="{ width: progressBar + '%' }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </ng-container>
      </div>
    </div>




    <div class="labs-progress row" *ngIf="operationMode === operationModes.AZUREPASSINITIATION" class="alert" [ngClass]="{
        'alert-custom alert-light-primary process-azurepass' : isMylearning,
        'alert-info' : !isMylearning
      }">
      <h4>
        <cloudlabs-vm-spinner></cloudlabs-vm-spinner>
        <span style="margin-bottom: 10px;" class="ml-3" role="alert"
          [attr.aria-label]="'MESSAGE_PLEASE_WAIT' | translate"><span aria-hidden="true">{{ "MESSAGE_PLEASE_WAIT" |
            translate }}</span></span>
      </h4>
    </div>
    <div *ngIf="
        operationMode === operationModes.INPROGRESS ||
        operationMode === operationModes.AZUREPASSUSERDETAILS
      " class="portlet box blue" [ngClass]="{'px-15':toggleFullScreenforCLPortal}">
      <p class="alert alert-secondary" *ngIf="operationMode !== operationModes.AZUREPASSUSERDETAILS  && !AttendeeEndDate &&
        environment.appType !=='trial' && lab.BlockExpirationOfInstance " role="alert">
        <span role="alert" *ngIf="lab.BlockExpirationOfInstance"
          [attr.aria-label]="'MESSAGE_ONDEMAND_LAB_READY_WITHOUT_TIMER' | translate">
          <span aria-hidden="true">
            {{ "MESSAGE_ONDEMAND_LAB_READY_WITHOUT_TIMER" | translate }}
          </span>
        </span>
      </p>
      <ng-container *ngIf="environment.appType === 'trial'">
        <div class="d-flex justify-content-between pb-3 mt-n4">
          <h1 class="trial-title">{{lab?.DisplayName || lab?.Title}}</h1>
          <ng-container *ngIf="vmUsage?.ResourceList?.length > 0 && vmUsage?.RemainingUptime !== '00:00:00'">
            <teams-button *ngIf="isResourceStopped" [classList]="'btn ts-btn-fluent-primary'"
              (buttonClick)="virtualMachineOperations('7')">
              <i class="fa fa-play px-1"></i>
              {{"START" | translate }} {{ "ENVIRONMENT" | translate}}
            </teams-button>
            <teams-button *ngIf="!isResourceStopped" [classList]="'btn ts-btn-fluent-red'"
              (buttonClick)="virtualMachineOperations('8')">
              <i class="fa fa-stop px-1"></i>
              {{"PAUSE" | translate }} {{ "ENVIRONMENT" | translate}}
            </teams-button>
          </ng-container>
        </div>
      </ng-container>

      <p role="alert" class="block portlet-title" [ngClass]="{'mt-5': isMylearning}"
        [attr.aria-label]="'MESSAGE_AWAIT_AZURE_PASS' | translate"
        *ngIf="operationMode === operationModes.AZUREPASSUSERDETAILS && environment.appType !=='trial'">
        <i class="fa fa-clock-o" aria-hidden="true"></i><span aria-hidden="true">&nbsp;
          {{ "MESSAGE_AWAIT_AZURE_PASS" | translate }}
        </span>
      </p>

      <cloudlabs-notes [customInstruction]="guideData?.CustomInstruction"
        [htmlContent]="workspaceURL"></cloudlabs-notes>

      <cloudlabs-lab-guide [labDetail]="lab" [mode]="operationMode" [voucherCode]="voucherCode" [type]="guideType"
        (startingLab)="startAzureLab()" [labExpiryDuration]="labExpiryDuration" [labUserData]="labUserData"
        [initialDeployment]="initialDeployment" [guideData]="guideData" *ngIf="labUserData.Status?.AllocatedTestDriveViewModalDetails[0]?.CloudPlatformId === 1"
        (labIsCompleted)="completeTheLab($event)" [isMobile]="isMobile" [showResourcesTab]="initialDeployment.ShowResourcesTab">
      </cloudlabs-lab-guide>

      <cloudlabs-lab-guide [labDetail]="lab" [mode]="operationMode" [voucherCode]="voucherCode" [type]="guideType"
        [guideData]="guideData" [labExpiryDuration]="labExpiryDuration" [labUserData]="labUserData"
        (startingLab)="startAzureLab()" *ngIf="labUserData.Status?.AllocatedTestDriveViewModalDetails[0]?.CloudPlatformId === 2"
        [initialDeployment]="initialDeployment" (labIsCompleted)="completeTheLab($event)" [isMobile]="isMobile" 
        [showResourcesTab]="initialDeployment.ShowResourcesTab">
      </cloudlabs-lab-guide>

      <cloudlabs-lab-guide [labDetail]="lab" [mode]="operationMode" [voucherCode]="voucherCode" [type]="guideType"
        [guideData]="guideData" [labExpiryDuration]="labExpiryDuration" [labUserData]="labUserData"
        (startingLab)="startAzureLab()" *ngIf="labUserData.Status?.AllocatedTestDriveViewModalDetails[0]?.CloudPlatformId === 3"
        [initialDeployment]="initialDeployment" (labIsCompleted)="completeTheLab($event)" [isMobile]="isMobile" 
        [showResourcesTab]="initialDeployment.ShowResourcesTab">
      </cloudlabs-lab-guide>

      <cloudlabs-lab-guide [labDetail]="lab" [mode]="operationMode" [voucherCode]="voucherCode" [type]="guideType"
        [guideData]="guideData" [labExpiryDuration]="labExpiryDuration" [labUserData]="labUserData"
        (startingLab)="startAzureLab()" *ngIf="labUserData.Status?.AllocatedTestDriveViewModalDetails[0]?.CloudPlatformId === 4"
        [initialDeployment]="initialDeployment" (labIsCompleted)="completeTheLab($event)" [isMobile]="isMobile" 
        [showResourcesTab]="initialDeployment.ShowResourcesTab">
      </cloudlabs-lab-guide>
      
    </div>
    <div *ngIf="operationMode === operationModes.FAILED" class="row deployed-lab">
      <div class="col-md-8">
        <div class="alert " [ngClass]="{
          'alert-custom alert-light-danger' : isMylearning,
          'alert-danger' : !isMylearning
        }">
          <h4 class="block alert-text" role="alert">
            <i role="img" alt="" aria-label="img" aria-hidden="true" class="fas fa-times"></i>
            &nbsp;
            <span *ngIf="lab?.PartnerName !== 'VMware'" [innerHTML]="
                'MESSAGE_FAILED' | translate: { Email: isMylearning ? environment?.supportMailLink : lab.ContactEmail }
              "></span>
            <span *ngIf="lab?.PartnerName === 'VMware'" [innerHTML]="
                'MESSAGE_FAILED_VMWARE' | translate: { Email: isMylearning ? environment?.supportMailLink : lab.ContactEmail }
              "></span>
          </h4>
          <!-- <p class="text-danger">{{ error }}</p> -->
        </div>
      </div>
    </div>

    <div *ngIf="operationMode === operationModes.POLICYFAILEDAFTERDEPLOYMENT" class="row deployed-lab">
      <div class="col-md-8">
        <div class="alert" role="alert" [ngClass]="{
          'alert-custom alert-light-danger' : isMylearning,
          'alert-danger' : !isMylearning
        }">
          <h4 class="block alert-text">
            <i role="img" alt="" aria-label="img" aria-hidden="true" class="fas fa-times"></i>&nbsp;
            <span *ngIf="lab?.PartnerName !== 'VMware'"
              [innerHTML]="'MESSAGE_FAILED' | translate: { Email: isMylearning ? environment?.supportMailLink : lab.ContactEmail }"></span>
            <span *ngIf="lab?.PartnerName === 'VMware'"
              [innerHTML]="'MESSAGE_FAILED_VMWARE' | translate: { Email: isMylearning ? environment?.supportMailLink : lab.ContactEmail }"></span>
          </h4>
          <p class="text-danger" translate="MESSAGE_POLICY_FAILED"></p>
        </div>
      </div>
    </div>

    <div *ngIf="operationMode === operationModes.DEALLOCATING" class="row deployed-lab flex-center">
      <div class="col-md-8">
        <div class="alert alert-info">
          <h4 role="alert" role="alert" [attr.aria-label]="'MESSAGE_DEALLOCATING' | translate">
            <i role="img" alt="" aria-label="img" aria-hidden="true"
              class="font-green-jungle font-lg fa fa-spinner fa-2x fa-spin"></i>
            <span aria-hidden="true">
              &nbsp; {{ "MESSAGE_DEALLOCATING" | translate }}
            </span>
          </h4>
        </div>
      </div>
    </div>
    <div *ngIf="operationMode === operationModes.DEALLOCATED" class="row deployed-lab">
      <div class="col-md-8">
        <div class="alert" role="alert" [attr.aria-label]="'ALERT_ODL_END' | translate" [ngClass]="{
          'alert-custom alert-light-warning' : isMylearning,
          'alert-warning' : !isMylearning
        }">
          <h4 class="block alert-text">
            <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-check-circle" aria-hidden="true"></i>
            <span aria-hidden="true">
              &nbsp; {{ "ALERT_ODL_END" | translate }}
            </span>
          </h4>
          <p class="alert-text">
            {{ "ALERT_ODL_END_THANKS1" | translate }}
          </p>
          <p class="alert-text">
            {{ "ALERT_THANKS" | translate }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="operationMode === operationModes.DEPLOYED" class="row deployed-lab">
      <div class="col-md-8">
        <div class="alert" role="alert" [attr.aria-label]="'ALERT_ALREADY_COMPLETED' | translate" [ngClass]="{
          'alert-custom alert-light-warning' : isMylearning,
          'alert-warning' : !isMylearning
        }">
          <h4 class="block alert-text">
            <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-exclamation-triangle"
              aria-hidden="true"></i>
            <span aria-hidden="true">
              &nbsp; {{ "ALERT_ALREADY_COMPLETED" | translate }}
            </span>
          </h4>
          <p class="alert-text">{{ "ALERT_THANKS" | translate }}</p>
        </div>
      </div>
    </div>

    <div *ngIf="operationMode === operationModes.ACTIVATIONCLOSED" class="row deployed-lab">
      <div class="col-md-8">
        <div class="alert" role="alert" [attr.aria-label]="'ACTIVATION_CLOSED' | translate" [ngClass]="{
          'alert-custom alert-light-warning' : isMylearning,
          'alert-warning' : !isMylearning
        }">
          <h4 class="block alert-text">
            <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-exclamation-triangle"
              aria-hidden="true"></i>
            <span aria-hidden="true" class="alert-text">
              &nbsp;{{ "ACTIVATION_CLOSED" | translate }}
            </span>
          </h4>
          <p>
            {{ "PLEASE_CONTACT" | translate: { Email: lab.ContactEmail } }}
          </p>
        </div>
      </div>
    </div>

    <div class="content-page mt-3">
      <div class="front-carousel margin-bottom-20">
        <div [innerHTML]="lab?.LabLaunchPageDescription" *ngIf="[operationModes.EXPIRED].includes(operationMode)"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal" role="dialog" appTrapFocus appAutoFocusModal aria-labelledby="modal-title"
  aria-describedby="modal-desc" *ngIf="modelShow" [class.validation]="modelShow" id="validation">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="modal-title" class="modal-title">{{modalData.title | translate}}</h4>
      </div>
      <div class="modal-body" aria-label="modal description">
        <p *ngIf="modalData.type === 'EXTEND_LAB_ERROR'"
        [innerHTML]="'MAXIMUM_TIME_REACHED' | translate:{allowedMin: lab?.MaxUserDurationExtension}"></p>
        <p id="modal-desc">
          {{modalData.description | translate}}
        </p>
      </div>
      <div class="modal-footer">
        <teams-button *ngIf="modalData.showCancel === true"
          [classList]="'btn ts-btn-fluent-red modal-close-button btn-danger'" (buttonClick)="modelShow=false">{{'CANCEL'
          | translate}}
        </teams-button>
        <teams-button *ngIf="modalData.type === 'EXTEND_LAB_ERROR'"
        [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="modelShow=false">
        {{'OK' |
        translate}}</teams-button>
      </div>
    </div>
  </div>
</div>