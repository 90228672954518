import { Injectable } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public getImageTag(path, markdownService: MarkdownService): any {
    const texting = path.substring(0, path.lastIndexOf('/'));
    markdownService.renderer.image = (
      href: string,
      title: string,
      text: string
    ) => {
      if (!href.includes('http:/') && !href.includes('https:/')) {
        return `<img alt ="${title || text}" src="${texting}/${href}">`;
      } else {
        return `<img alt ="${title || text}" src="${href}">`;
      }
    };
  }

  getLinks(markdownService: MarkdownService) {
    markdownService.renderer.link = (
      href: string,
      title: string,
      text: string
    ) => {
      if (!href.includes('http')) {
        return (
          '<a  class="selfnavigation" tabindex="0" href="javascript:void(0)" data-id="' +
          href +
          '">' +
          text +
          '</a>'
        );
      } else {
        return (
          '<a role="link" tabindex="0" href="' +
          href +
          '" target="_blank" rel="nofollow noopener noreferrer">' +
          text +
          '</a>'
        );
      }
    };
  }

  public getAllAttributes(
    props: any,
    xmlString: string,
    attendeLabDetails: any,
    guideData: any,
    initialDeploymentData: any
  ) {
    const styleTag = xmlString?.match(/style="(.*?)"/);
    const cloudProvider = xmlString.match(/cloudname\s*=\s*"(.*?)"/i);
    const enableCopy =
      xmlString?.match(/enableCopy="(.*?)"/) ||
      xmlString?.match(/enablecopy="(.*?)"/);
    if (enableCopy?.length) {
      props.enableCopy = enableCopy[1] === 'true';
    }
    if (styleTag?.length) {
      props.style = styleTag[1];
    }

    const attributeRegex = /(\w+)\s*=\s*["']([^"']*)["']/g;
    let match: any;
    let combineResult = '';
    let keyLabel: any;

    while ((match = attributeRegex?.exec(xmlString)) !== null) {
      const [, attributeName, attributeValue] = match;
      if (attributeName === 'key' || attributeName === 'value') {
        const attrDynamicProperty = attributeValue
          ?.replace(/ +/g, '')
          ?.toLowerCase();
        const prepareResult = this.getValues(
          attrDynamicProperty,
          attributeValue,
          guideData,
          initialDeploymentData,
          attributeName === 'key',
          attendeLabDetails,
          cloudProvider ? cloudProvider[1]?.toLowerCase() : ''
        );
        combineResult += prepareResult.value;
        keyLabel = prepareResult.label;
      }
    }
    return combineResult
      ? this.setGuideVariableHTML(props, combineResult, keyLabel)
      : xmlString;
  }

  private getValues(
    key: string,
    attributeValue: any,
    guideData: any,
    initialDeploymentData: any,
    isKeyAttr: boolean,
    attendeLabDetails: any,
    cloudProvider: string
  ) {
    let deploymentOutputValues: any = {};
    let guideDataValues: any = {};
    let initialDeploymentValues: any = {};
    if (attendeLabDetails.AllocatedTestDriveViewModalDetails.length > 1) {
      deploymentOutputValues =
        attendeLabDetails.AllocatedTestDriveViewModalDetails.find(
          (item) => item.FriendlyName?.toLowerCase() === cloudProvider
        )?.DeploymentOutputValues;
      guideDataValues = guideData.AllocatedTestDriveViewModalDetails.find(
        (item) => item.FriendlyName?.toLowerCase() === cloudProvider
      );
      initialDeploymentValues =
        initialDeploymentData.AllocatedTestDriveViewModalDetails.find(
          (item) => item.FriendlyName?.toLowerCase() === cloudProvider
        );
    } else {
      deploymentOutputValues =
        attendeLabDetails.AllocatedTestDriveViewModalDetails[0]?.DeploymentOutputValues;
      guideDataValues = guideData.AllocatedTestDriveViewModalDetails[0];
      initialDeploymentValues =
        initialDeploymentData.AllocatedTestDriveViewModalDetails[0];
    }
    switch (key) {
      case 'azureaduseremail':
        return { value: guideDataValues?.AADEmail || '', label: 'username' };
      case 'azureaduserpassword':
        return { value: guideDataValues?.TempPassword || '', label: 'password' };
      case 'applicationid':
      case 'accesskey':
        return { value: guideDataValues?.AADSPAppId || '', label: key };
      case 'displayname':
        return { value: guideDataValues?.AADSPDisplayName || '', label: key };
      case 'secretkey':
        return { value: guideDataValues?.AADSPAppKey || '', label: key };
      case 'subscriptionid':
        return { value: guideDataValues?.SubscriptionGuid || '', label: key };
      case 'tenantid':
        return { value: guideDataValues?.TenantId || '', label: key };
      case 'tenantdomainname':
        return { value: guideDataValues?.TenantDomainName || '', label: key };
      case 'cloudlabsdeploymentid':
        return { value: initialDeploymentValues?.CloudUserId || '', label: key };
      default: {
        const deploymentObjResult: any = this.getDeploymentValues(
          deploymentOutputValues,
          key
        );
        return {
          value: deploymentObjResult || (isKeyAttr ? '' : attributeValue),
          label: key,
        };
      }
    }
  }

  private setGuideVariableHTML(props: any, value: any, label: any) {
    return `<span class="copydetails ${
      props.enableCopy ? '' : 'hide-copy-btn'
    }"  aria-label="${label}">
    <span ${props.style !== '' ? `style="${props.style}"` : ''}>${
      value || ''
    }</span>
    </span>`;
  }

  private getDeploymentValues(deploymentOutputValues: any, keyLabel: any) {
    let outputValue: any;
    if (deploymentOutputValues?.length > 0) {
      deploymentOutputValues.forEach((item: any) => {
        const { OutputValues } = item;
        if (
          OutputValues &&
          OutputValues.length > 0 &&
          OutputValues.findIndex(
            (value: any) =>
              value.Name.replace(/ +/g, '').toLowerCase() === keyLabel
          ) > -1
        ) {
          outputValue = OutputValues.find(
            (value: any) =>
              value.Name.replace(/ +/g, '').toLowerCase() === keyLabel
          );
        }
      });
    }
    return outputValue ? outputValue?.Value : '';
  }

  iso8601ToHours(isoDuration: string): number {
    const duration = moment.duration(isoDuration);
    const totalHours = duration.asHours();
    return +totalHours.toFixed(2);
  }

  iso8601ToMinutes(isoDuration: string) {
    const duration = moment.duration(isoDuration);
    const totalMinutes = duration.asMinutes();
    return +totalMinutes.toFixed(2);
  }

  minutesToISO8601(minutes) {
    if (!minutes || minutes === 0) {
      return 'PT0S';
    }

    const duration = moment.duration(minutes, 'minutes');
    let isoDuration = 'P';

    if (duration.days() > 0) {
      isoDuration += duration.days() + 'D';
    }

    isoDuration += 'T';

    if (duration.hours() > 0) {
      isoDuration += duration.hours() + 'H';
    }

    if (duration.minutes() > 0) {
      isoDuration += duration.minutes() + 'M';
    }
    return isoDuration;
  }

  hoursToISO8601(hours) {
    const duration = moment.duration(hours, 'hours');
    let isoDuration = 'P';

    if (duration.days() > 0) {
      isoDuration += duration.days() + 'D';
    }

    if (duration.hours() > 0 || duration.minutes() > 0) {
      isoDuration += 'T';
    }

    if (duration.hours() > 0) {
      isoDuration += duration.hours() + 'H';
    }

    if (duration.minutes() > 0) {
      isoDuration += duration.minutes() + 'M';
    }
    return isoDuration;
  }

  downloadCSVFile(data: string) {
    const binaryData = [];
    binaryData.push(data);
    const mediaType = 'text/csv';
    const blob = new Blob(binaryData, { type: mediaType });
    return window.URL.createObjectURL(blob);
  }
}
