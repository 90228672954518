import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { miscellaneousConst } from '@utility';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'single-choice-question',
  templateUrl: './single-choice-question.component.html',
  styleUrls:['./single-choice-question.component.scss']
})
export class SingleChoiceQuestionComponent implements OnChanges {
  @Input() public question;
  @Input() sectionType = '';
  @Output() UserSelectAnswerOption = new EventEmitter();
  @Input() userAnswer = [];
  @Input() versionKey: string;
  @Input() questionStyle
  @Input() groupQuestRemainingRetries
  @Input() isRetryClicked;
  @Input() isSubmitClicked;
  public maxNo = false;
  @Input() set isCorrectAnswerSingleChoice (value){
    if (value?.type === 'ungrouped') {
      this.question?.options.forEach(element => {
        value?.answerKey.forEach(el => {
          if (value?.remainingRetries === 0 && (value?.correctAnswers[0]?.key === element.key)) {
            element.isCorrect = true
          } else
          if((value?.questionUniqueName === this.question?.questionUniqueName) && (el.key === element.key) && value?.isCorrect !== undefined) {
            element.isCorrect = value?.isCorrect
          }
        });
      })
    } else if (value?.type === 'grouped') {
      this.question?.options.forEach(option => {
        value?.mergedArrayGroupe.forEach(mergedArr => {
          if (mergedArr?.QuestionUniqueName === this.question?.questionUniqueName) {
            if (mergedArr.correctAnswers.length > 0) {
              const filteredUserAnswers = this.question?.options.filter(opt => 
                mergedArr.correctAnswers.some(correctAnswer => correctAnswer.key === opt.key)
              );
              filteredUserAnswers.forEach(filteredUserAnswer => {
                if (filteredUserAnswer.key === option.key && this.groupQuestRemainingRetries === 0 && mergedArr?.QuestionUniqueName === this.question?.questionUniqueName) {
                  option.isCorrect = true
                }
              });
            } else {
              mergedArr.userAnswer.forEach(userAns => {
                if ((mergedArr?.QuestionUniqueName === this.question?.questionUniqueName) && (userAns?.key === option?.key)) {
                  option.isCorrect = mergedArr?.isCorrect
                }
              });
            }
          }
        })
      })
    }
    
    
  };
  @Input() isInlineQuestion = false;

  public miscellaneousConst = miscellaneousConst;
  /**
   * @description: Change Single Choice Value
   */
  ngOnChanges(): void {
    this.question?.options.forEach((element) => {
      element.checked = '';
    });
    if (this.question.userAnswers?.length > 0) {
      const answer = this.question?.options.filter((i) =>
        this.question.userAnswers.some((o) => i.key === o.key)
      );
      answer.forEach((element) => {
        element.checked = this.question.userAnswers.find(
          (i) => i.key === element.key
        ).key;
      });
    }
    if (this.isInlineQuestion) {
      if ((this.isSubmitClicked) ) {
        this.maxNo = true
      } else if (this.isRetryClicked || (this.question?.totalRetries === 0)) {
        this.maxNo = false
      }
      this.question?.options.forEach(element => {
          this.question.userAnswersHistory.forEach((answer:any)=>{
            const answerHistory = answer.userAnswers.find((c:any)=>c.key==element.key)
            if (this.question?.correctAnswers.length > 0) {
              this.question?.correctAnswers.forEach(e => {
                if (this.questionStyle === 'ungroupedQuest' && this.question?.remainingRetries === 0 && e.key === element.key) {
                  element.isCorrect = true
                } else if (this.questionStyle === 'groupedQuest' && this.groupQuestRemainingRetries === 0 && e.key === element.key) {
                    element.isCorrect = true
                  }
              });
            } else
            if(answerHistory){
              answerHistory.isCorrect = answer.isCorrect
              element.isCorrect = answerHistory.isCorrect
            }
          })
      });
    }
  }
  
  /**
   * @description: Save Single Choice value
   */
  public selectSingleChoice(option: any): void {
    const submittedAnswer = [];
    const answer = this.question?.options.find((i) => i.key === option.key);
    submittedAnswer.push({ key: answer.key, value: answer.value });

    if(this.question?.isVersioned) {
      this.question?.questionVersions.forEach((element) => {
        
        if(element.versionKey === this.versionKey) {
          element.userAnswers = submittedAnswer
          this.question.userAnswers = submittedAnswer;
        }
      });
    } else { 
      this.question.userAnswers = submittedAnswer;
    }
    this.UserSelectAnswerOption.emit(this.question);
  }
}
