
<div [attr.inert]="!isSidenav && isMylearning ? true : null"  class="tab-wrp tab-wrpguide pb-5" [ngClass]="{'ts-tabs-panel top-adjustment': appType === 'attendee' || appType === 'trial'}" *ngIf="!loading">
    <div class="custom-tab-main">
        <div class="d-flex align-items-center justify-content-start w-100">
            <!-- Accessibility issue: Element has children which are not allowed, Once functionality done then we will take it -->
            <ul class="nav nav-tabs resize-tab tabs_1 tab-width ts-tabs-list" role="tablist">
                <li id="link_{{item.id}}" class="nav-item" role="presentation" *ngFor="let item of guideTab; let i = index;">
                    <a class="nav-link ts-tab-item" id="main-landmark{{i}}" role="tab"
                        [appArrowKeysAccessible]="'arrow-key-doc-guide'" style="text-decoration: none;"
                        [attr.aria-selected]="item.selected" [ngClass]="{'active-tab': tabView === item.link}"
                        (click)="viewTab(item.link, item, false, false)" href="javascript:void(0)"><b
                            [translate]="item.title"></b></a>
                </li>
                <ul class="nav nav-tabs tabs_1 dropdown-ul m-0 p-0 ts-tabs-list">
                    <li class="nav-item dropdown custom-dropdown-tab"
                        [ngClass]="{'attendee-droppdown': appType === 'attendee' || appType === 'trial'}">
                        <a href="javascript:void(0)" role="button" aria-label="More items" id="myTabDrop1"
                            class="ts-tab-item nav-tabs more-link" data-toggle="dropdown" aria-controls="myTabDrop1-contents"
                            aria-expanded="false">
                            <b id="showdropdown-menu" [title]="'SHOW_DROP_DOWN_TOOL_TIP' | translate">{{'MORE' |translate}} <i
                                    class="fas fa-chevron-down ml-2 more-icon-position"></i></b>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="myTabDrop1" id="myTabDrop1-contents" role="tablist">
                            <li id="link_{{item.id}}-dup" class="nav-item" *ngFor="let item of guideTab; let i = index;"
                                role="presentation">
                                <a role="tab" [appArrowKeysAccessible]="'arrow-key-doc-guide-dp'"
                                    [attr.aria-selected]="item.selected" style="text-decoration: none;"
                                    class="nav-link ts-tab-item"
                                    [ngClass]="{'active-tab': tabView === item.link, 'active': appType === 'lab' && tabView === item.link}"
                                    (click)="viewTab(item.link, item, false, false)" href="javascript:void(0)"><b
                                        [translate]="item.title"></b></a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </ul>
        </div>
        <ng-container *ngIf="showSplitWindow">
            <div class="toggle-icons">
                <div class="line"></div>
                <a *ngIf="!isSplitScreen" role="button" href="javascript:void(0)" [attr.aria-label]="'SPLIT_WINDOW' | translate" [title] = "'SPLIT_WINDOW' | translate" (click)="splitWindow()">
                    <img src="assets/images/split_window_icon-colour.svg" alt="">
                </a>
                <a *ngIf="isSplitScreen" role="button" href="javascript:void(0)" [attr.aria-label]="'JOIN_WINDOW' | translate" [title] = "'JOIN_WINDOW' | translate" (click)="joinWindow()">
                    <img src="assets/images/join_window_icon.svg" alt="">
                </a>
            </div>
        </ng-container>

    </div>
    <div class="content-wrp mt-2">
        <div class="tab-content" id="lab-guide-tab">
            <div class="guide-tabs lab-guide" [class.showtab]="tabView === 'guide'" id="guideView" tabindex="0"
                style="overflow: auto;">
                <div id="guide-page-"
                        *ngFor="let item of gitHUbdocs?.Files | paginate: { itemsPerPage: 1, currentPage: p }">
                        
                        <!-- Main loop to iterate over the content split by the testPattern -->
                        <ng-container *ngFor="let data of currentLabguide[p]?.split(testPattern); let i = index">
                            
                            <!-- Render markdown if the data doesn't match the testPattern -->
                            <cloudlabs-markdown *ngIf="!testPattern.test(data) && (!labDetail.EnableInlineValidation && !labDetail?.HasInlineQuestions)" 
                                class="variable-binding" [readMeData]="data">
                            </cloudlabs-markdown>

                            <!-- If either validation or questions are enabled -->
                            <ng-container *ngIf="labDetail.EnableInlineValidation || labDetail?.HasInlineQuestions">
                                
                                <!-- Render markdown that does not match the testPattern -->
                                <cloudlabs-markdown *ngIf="!testPattern.test(data)" class="variable-binding" [readMeData]="data"></cloudlabs-markdown>
                                
                                <!-- Inline validation -->
                                <ng-container *ngIf="labDetail.EnableInlineValidation && validationPattern.test(data)">
                                    <cloudlabs-lab-validation [voucherCode]="voucherCode" 
                                        [labDetail]="labDetail"
                                        [isInlineValidation]="true" 
                                        [stepUniqueId]="data" 
                                        (updatePageLock)="updatePageLock()">
                                    </cloudlabs-lab-validation>
                                </ng-container>

                                <!-- Inline questions -->
                                <ng-container *ngIf="labDetail?.HasInlineQuestions">
                                    
                                    <!-- Ungrouped questions -->
                                    <ng-container *ngIf="ungroupedQuestPattern.test(data)">
                                        <div class="position-relative">
                                            <cloudlabs-inline-question [questIndex]="i" 
                                                [questionStyle]="'ungroupedQuest'" 
                                                [inlineQuestionTag]="data" 
                                                [pageNumber]="p" 
                                                [questionData]="getUngroupQuestionData(data)">
                                            </cloudlabs-inline-question>
                                        </div>
                                    </ng-container>

                                    <!-- Grouped questions -->
                                    <div class="position-relative" *ngIf="groupedQuestPattern.test(data)">
                                        <cloudlabs-inline-question [questIndex]="i" 
                                            [questionStyle]="'groupedQuest'" 
                                            [inlineQuestionTag]="data" 
                                            [pageNumber]="p" 
                                            [questionData]="getGroupQuestionData(data)">
                                        </cloudlabs-inline-question>
                                    </div>
                                </ng-container>
                            </ng-container>
                            
                            <!-- Always render inline video when inlineVideoPattern.test(data) is true -->
                            <ng-container *ngIf="inlineVideoPattern.test(data)">
                                <cloudlabs-video [videoTag]="data" 
                                    [videoLink]="getVideoLink(data)" 
                                    [videoWidth]="getVideoWidth(data)" 
                                    [videoHeight]="getVideoHeight(data)">
                                </cloudlabs-video>
                            </ng-container>

                        </ng-container>
                    </div>
                    <!-- Keep commented code for future POV | Sheikh-->
                <!-- <ng-container *ngIf="gitHUbdocs && gitHUbdocs.Files?.length > 0">
                    <ng-container *ngFor="let item of gitHUbdocs?.Files | paginate: { itemsPerPage: 1, currentPage: p }">
                        <cloudlabs-markdown *ngIf="currentLabguide[p] && (!labDetail.EnableInlineValidation && !labDetail?.HasInlineQuestions)" 
                            class="variable-binding" [readMeData]="currentLabguide[p]"></cloudlabs-markdown>
                            <ng-container *ngIf="labDetail.EnableInlineValidation || labDetail?.HasInlineQuestions">
                                <ng-container *ngFor="let data of currentLabguide[p]?.split(testPattern); let i = index">
                                    <cloudlabs-markdown *ngIf="!testPattern.test(data)" class="variable-binding" [readMeData]="data"></cloudlabs-markdown>
                                    <ng-container *ngIf="labDetail.EnableInlineValidation">
                                        
                                    <cloudlabs-lab-validation *ngIf="validationPattern.test(data)" [voucherCode]="voucherCode" [labDetail]="labDetail"
                                    [isInlineValidation]="true" [stepUniqueId]="data" (updatePageLock)="updatePageLock()"></cloudlabs-lab-validation>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="labDetail?.HasInlineQuestions">
                                        <ng-container *ngIf="ungroupedQuestPattern.test(data)">
                                            <div class="position-relative">
                                                <cloudlabs-inline-question [questIndex] = "i" [questionStyle]="'ungroupedQuest'" [inlineQuestionTag]="data" [pageNumber]="p" [questionData]="getUngroupQuestionData(data)"></cloudlabs-inline-question>
                                            </div>
                                            
                                        </ng-container>
                                        <div class="position-relative"  *ngIf="groupedQuestPattern.test(data)">
                                            <cloudlabs-inline-question [questIndex] = "i" [questionStyle]="'groupedQuest'" [inlineQuestionTag]="data" [pageNumber]="p" [questionData]="getGroupQuestionData(data)"></cloudlabs-inline-question>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="inlineVideoPattern.test(data)">
                                        <cloudlabs-video [videoTag]="data" [videoLink]="getVideoLink(data)" [videoWidth]="getVideoWidth(data)" [videoHeight]="getVideoHeight(data)"></cloudlabs-video>
                                    </ng-container>
                                    
                                </ng-container>
                            </ng-container>
                    </ng-container>
                </ng-container> -->
            </div>
            <div class="guide-tabs p-0" [class.showtab]="tabView === 'environment'">
                <cloudlabs-lab-environment [minGuide]="minGuide" [labDetail]="labDetail" [voucherCode]="voucherCode"
                    (deallocateLab)="completeTheLab($event)"></cloudlabs-lab-environment>
            </div>
            <div class="guide-tabs" [class.showtab]="tabView === 'validation'" *ngIf="(!labDetail?.DisableValidationTab && !this.IsSupportQuestFlag)">
                <cloudlabs-lab-validation [labDetail]="labDetail" [voucherCode]="voucherCode"
                    [manager]="manager"></cloudlabs-lab-validation>
            </div>
            <ng-container *ngIf="showResourcesTab">
                <!-- *ngIf="this.guideData.CloudPlatformId === 1 || this.guideData.CloudPlatformId === 2 || this.guideData.CloudPlatformId === 3 || this.guideData.CloudPlatformId === 4" -->
                <div class="guide-tabs pl-0" [class.showtab]="tabView === 'resources'">
                    <cloudlabs-resource *ngIf="tabView === 'resources'" [labDetail]="labDetail" [mode]="mode" [attendeLabDetails]="guideData" [manager]="manager" [voucherCode]="voucherCode" [splitKey]="splitKey" [isMobile]="isMobile"></cloudlabs-resource>
                </div>
            </ng-container>

            <div class="guide-tabs" [class.showtab]="tabView === 'description'" id="lab-description" tabindex="0">
                <div [innerHTML]="labDetail.LabLaunchPageDescription | targetBlank | sanitizeHtml" tabindex="0"></div>
            </div>

            <div class="guide-tabs help-tab-content" [class.showtab]="tabView === 'help'">
              <h2 style="font-size:30px; font-weight:bold">  {{ "TROUBLESHOOTING" | translate }}</h2><br />
                
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px; font-weight:bold">
                    <div class="col-sm-4 common-issue">{{ "COMMON_ISSUE" | translate }}</div>
                    <div class="col-sm-8 recommended">{{ "RECOMMENDED_QUICK" | translate }}</div>
                </div>

                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;" *ngIf="environment.customer === customerType.DATABRICKS">
                    <div class="col-4">{{ "WORKSPACE_NOT_LOADING" | translate }} </div>
                    <div class="col-8">
                        <ol style="padding-inline-start: 1em;">
                            <li>{{ "STEP_1" | translate }} </li>
                            <li>{{ "STEP_2" | translate }} </li>
                            <li>{{ "STEP_3" | translate }} </li>
                            <li>{{ "STEP_4" | translate }} </li>
                            <li>{{ "STEP_5" | translate }} </li>
                            <li>{{ "STEP_6" | translate }} </li>
                        </ol>
                    </div>
                </div>  

                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="!EdgeMessage && !FirefoxHelpMessage">
                    <div class="col-4">{{ "UNABLE_TO_COPY" | translate }}</div>
                    <div class="col-8">
                        <p [innerHTML]="'SSL_CERTIFICATE' | translate"></p>
                        <p>{{ "CLIPBOARD_ACCESS" | translate }}</p>
                        <table class="table table-responsive" tabindex="0">
                            <tr>
                                <th>{{ "ATTENDEE_OS" | translate }}</th>
                                <th>{{ "VM_OS" | translate }}</th>
                                <th>{{ "COPY_SHRT" | translate }}</th>
                                <th>{{ "PASTE_SHRT" | translate }}</th>
                            </tr>

                            <ng-container *ngIf="osInfo === 'Windows'">
                                <tr>
                                    <td>Windows</td>
                                    <td>Windows</td>
                                    <td>Ctrl + C</td>
                                    <td>Ctrl + V</td>
                                </tr>
                                <tr>
                                    <td>Windows</td>
                                    <td>Linux</td>
                                    <td>Ctrl + C</td>
                                    <td>Ctrl + Shift + V</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngIf="osInfo === 'Mac OS'">
                                <tr>
                                    <td>Mac</td>
                                    <td>Windows</td>
                                    <td>Command + C</td>
                                    <td>Command + V</td>
                                </tr>
                                <tr>
                                    <td>Mac</td>
                                    <td>Linux</td>
                                    <td>Command + C</td>
                                    <td>Control + Shift + V</td>
                                </tr>
                            </ng-container>
                        </table>
                        <p>{{ "ALTERNATIVE_CP_PS" | translate }}</p>
                        <p [innerHTML]="'CLIPBOARD_COPY_BUTTON' | translate"></p>
                    </div>
                    <br />
                </div>


                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="!EdgeMessage && !FirefoxHelpMessage">
                    <div class="col-4">{{ "NEED_CREDENTIALS" | translate }} </div>
                    <div class="col-8">
                        {{ "LAB_CREDENTIALS" | translate }}
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="!EdgeMessage && !FirefoxHelpMessage">
                    <div class="col-4"> {{ "WINDOW_VISIBILITY" | translate }} </div>
                    <div class="col-8">
                        {{ "SCREEN_RESOLUTION_ISSUE" | translate }}
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="!EdgeMessage && !FirefoxHelpMessage && layoutType !== environmentLayout.DATABRICKS">
                    <div class="col-4" style="word-wrap: break-word;">{{ "VM_NAVIGATION_HELP" | translate }} </div>
                    <div class="col-8">
                        <span>
                            {{ 'VM_NAVIGATION_HELP_TEXT' | translate: { Email: labDetail.ContactEmail } }}
                            <ng-container *ngIf="osInfo === 'Mac OS'; else showWindowsInfo">
                                " Option + ` "
                            </ng-container>
                            <ng-template #showWindowsInfo>
                                " Alt + ` "
                            </ng-template>
                            
                        </span>
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="!EdgeMessage && !FirefoxHelpMessage">
                    <div class="col-4">{{ "ADDITIONAL_HELP" | translate }} </div>
                    <div class="col-8">
                        <span style="word-wrap: break-word;" innerHTML="{{ 'PLEASE_REACH_OUT' | translate: { Email: labDetail.ContactEmail }
                            }}"></span>
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="FirefoxHelpMessage">
                    <div class="col-4">{{ "UNABLE_TO_COPY" | translate }}</div>
                    <div class="col-8">
                        <p [innerHTML]="'FIREFOX_BROWSER_CONFIG' | translate"></p>
                        <p [innerHTML]="'SSL_CERTIFICATE' | translate"></p>
                        <p>{{ "CLIPBOARD_ACCESS" | translate }}</p>
                        <table class="table table-responsive" tabindex="0">
                            <tr>
                                <th>{{ "ATTENDEE_OS" | translate }}</th>
                                <th>{{ "VM_OS" | translate }}</th>
                                <th>{{ "COPY_SHRT" | translate }}</th>
                                <th>{{ "PASTE_SHRT" | translate }}</th>
                            </tr>

                            <ng-container *ngIf="osInfo === 'Windows'">
                                <tr>
                                    <td>Windows</td>
                                    <td>Windows</td>
                                    <td>Ctrl + C</td>
                                    <td>Ctrl + V</td>
                                </tr>
                                <tr>
                                    <td>Windows</td>
                                    <td>Linux</td>
                                    <td>Ctrl + C</td>
                                    <td>Ctrl + Shift + V</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngIf="osInfo === 'Mac OS'">
                                <tr>
                                    <td>Mac</td>
                                    <td>Windows</td>
                                    <td>Command + C</td>
                                    <td>Command + V</td>
                                </tr>
                                <tr>
                                    <td>Mac</td>
                                    <td>Linux</td>
                                    <td>Command + C</td>
                                    <td>Control + Shift + V</td>
                                </tr>
                            </ng-container>
                        </table>
                        <p>{{ "ALTERNATIVE_CP_PS" | translate }}</p>
                        <p [innerHTML]="'CLIPBOARD_COPY_BUTTON' | translate"></p>
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="FirefoxHelpMessage">
                    <div class="col-4">{{ "NEED_CREDENTIALS" | translate }} </div>
                    <div class="col-8">
                        {{ "LAB_CREDENTIALS" | translate }}
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="FirefoxHelpMessage">
                    <div class="col-4"> {{ "WINDOW_VISIBILITY" | translate }} </div>
                    <div class="col-8">
                        {{ "SCREEN_RESOLUTION_ISSUE" | translate }}
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="FirefoxHelpMessage && layoutType !== environmentLayout.DATABRICKS">
                    <div class="col-4">{{ "VM_NAVIGATION_HELP" | translate }}</div>
                    <div class="col-8">
                        <span innerHTML="{{ 'VM_NAVIGATION_HELP_TEXT' | translate: { Email: labDetail.ContactEmail }
                              }}"></span>
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;"
                    *ngIf="FirefoxHelpMessage">
                    <div class="col-4">{{ "ADDITIONAL_HELP" | translate }}</div>
                    <div class="col-8">
                        <span innerHTML="{{ 'PLEASE_REACH_OUT' | translate: { Email: labDetail.ContactEmail }
                            }}"></span>
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;" *ngIf="EdgeMessage">
                    <div class="col-4">{{ "UNABLE_TO_COPY" | translate }}</div>
                    <div class="col-8">
                        <p> {{ "SUPPORTED_BROWSER" | translate }}</p>
                        <p [innerHTML]="'SSL_CERTIFICATE' | translate"></p>
                        <p> {{ "CLIPBOARD_ACCESS" | translate }}</p>
                        <table class="table table-responsive" tabindex="0">
                            <tr>
                                <th>{{ "ATTENDEE_OS" | translate }}</th>
                                <th>{{ "VM_OS" | translate }}</th>
                                <th>{{ "COPY_SHRT" | translate }}</th>
                                <th>{{ "PASTE_SHRT" | translate }}</th>
                            </tr>

                            <ng-container *ngIf="osInfo === 'Windows'">
                                <tr>
                                    <td>Windows</td>
                                    <td>Windows</td>
                                    <td>Ctrl + C</td>
                                    <td>Ctrl + V</td>
                                </tr>
                                <tr>
                                    <td>Windows</td>
                                    <td>Linux</td>
                                    <td>Ctrl + C</td>
                                    <td>Ctrl + Shift + V</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngIf="osInfo === 'Mac OS'">
                                <tr>
                                    <td>Mac</td>
                                    <td>Windows</td>
                                    <td>Command + C</td>
                                    <td>Command + V</td>
                                </tr>
                                <tr>
                                    <td>Mac</td>
                                    <td>Linux</td>
                                    <td>Command + C</td>
                                    <td>Control + Shift + V</td>
                                </tr>
                            </ng-container>
                        </table>
                        <p>{{ "ALTERNATIVE_CP_PS" | translate }}</p>
                        <p [innerHTML]="'CLIPBOARD_COPY_BUTTON' | translate"></p>
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;" *ngIf="EdgeMessage">
                    <div class="col-4">{{ "UNABLE_TO_TYPE" | translate }}</div>
                    <div class="col-8">
                        {{ "SUPPORTED_BROWSER" | translate }}
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;" *ngIf="EdgeMessage">
                    <div class="col-4"> {{ "NEED_CREDENTIALS" | translate }} </div>
                    <div class="col-8">
                        {{ "LAB_CREDENTIALS" | translate }}
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;" *ngIf="EdgeMessage">
                    <div class="col-4"> {{ "WINDOW_VISIBILITY" | translate }} </div>
                    <div class="col-8">
                        {{ "SCREEN_RESOLUTION_ISSUE" | translate }}
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;" *ngIf="EdgeMessage && layoutType !== environmentLayout.DATABRICKS">
                    <div class="col-4"> {{ "VM_NAVIGATION_HELP" | translate }} </div>
                    <div class="col-8">
                        <span innerHTML="{{ 'VM_NAVIGATION_HELP_TEXT' | translate: { Email: labDetail.ContactEmail }
                              }}"></span>
                    </div>
                    <br />
                </div>
                <div class="row" style="border-bottom:1px solid lightgrey; padding-bottom:14px;" *ngIf="EdgeMessage">
                    <div class="col-4"> {{ "ADDITIONAL_HELP" | translate }} </div>
                    <div class="col-8">
                        <span innerHTML="{{ 'PLEASE_REACH_OUT' | translate: { Email: labDetail.ContactEmail }
                            }}"></span>
                    </div>
                    <br />
                </div>
            </div>
        </div>

    </div>
    <cloudlabs-pagination-controls [ngClass]="{ 'd-none': tabView !== 'guide'}" [responsive]="true"
        [previousLabel]="'PREVIOUS' | translate" [nextLabel]="'NEXT' | translate" [currentPageKey]="currentGuideKey"
        (pageChange)="p = $event; getcalue()">
    </cloudlabs-pagination-controls>
</div>