export interface Lab {
  [key: string]: any;
  OnDemandLabId: number;
  EventId: number;
  Duration: number;
  AllowUserToDeleteODL: boolean;
  InstructorId: number | null;
  HasLicenses: boolean;
  HasUnAssignedHotInstances: boolean;
  QuizId: number | null;
  BannerImage?: string;
  MaxRetryCount: number;
  LabPromotionMessage: string | null;
  LabLaunchPageDescription: string;
  MockExamGuid: string | null;
  MockExamId: number | null;
  IsTrial: boolean;
  TrialEndPageURL: string | null;
  HasUnDeletedDeployments: boolean;
  DeploymentDuration: number;
  SurveyGuid: number | null;
  LabGuideUrl: string | null;
  GitHubDocumentMasterFilePath: string | null;
  AnyManualTest: boolean;
  ConnectChannel: string | null;
  NeedDatabricksWorkspace: boolean;
  AllowUserToExtendLab: boolean;
  MaxUserDurationExtension: number | null;
  ACIContainerType: number;
  EnableCustomLabDetailsPage: boolean;
  LimitedAccess: string | null;
  EnableAttendeeTracking: boolean;
  EnvironmentIdleTimeout: string | null;
  LabRequestId: number | null;
  Id: number;
  LabCode: string | null;
  TypeId: number;
  Title: string | null;
  UniqueName: string | null;
  BitLink: string | null;
  TemplateId: number;
  PartnerId: number;
  PartnerName: string;
  PartnerLogo: string;
  Description: string;
  LocationType: string | null;
  Location: string | null;
  StartDate: string | null;
  EndDate: string | null;
  StartTime: string | null;
  EndTime: string | null;
  TimeZone: string | null;
  StatusId: number;
  AllowAnonymousUsers: boolean;
  ApprovalTypeId: number;
  SubscriptionGroupId: number | null;
  ContactEmail: string | null;
  CustomTitle: string | null;
  Tags: string | null;
  DoNotUseCloudSubscription: boolean;
  DoNotSendCloudCredentials: boolean;
  IsPrivate: boolean;
  AllowPersonalEmailAddressesForRegistration: boolean;
  EnableHotInstances: boolean;
  MinAvailableHotInstances: number;
  IntelligenceToSelfHeal: boolean;
  AdditionalNotes: string | null;
  SurveyUrl: string | null;
  IsForTestingPurpose: boolean;
  SponsorId: number | null;
  SponsorName: string | null;
  BlockExpirationOfInstance: boolean;
  IsPaid: boolean;
  PaymentType: string | null;
  RequiredCredits: string | null;
  Language: string | null;
  LinkToVideo: string | null;
  VideoApprovalTypeId: number | null;
  EnableVoucher: boolean;
  IsActive: boolean;
  InitiatedHotInstancesCount: string | null;
  CreatedBy: string | null;
  CreatedTime: string | null;
  ModifiedBy: string | null;
  ModifiedTime: string | null;
  TemplateName: string | null;
  Regions: Region[] | null;
  EventLicenses: string | null;
  SelectedChannelsUniqueName: Array<any>;
  DeploymentGranularityId: number | null;
  CloudPlatformId: number | null;
  InternalTags: string | null;
  InternalId: string | null;
  MaxUserCount: number | null;
  FeatureImageUrl: string | null;
  AllowExistingAD: boolean;
  EnableAzurePass: boolean;
  AzurePassDirectoryType: string | null;
  EnablePrivateSubscriptions: boolean;
  PrivateSubscriptionOwnerEmail: string | null;
  CustomPrivacyUrl: string | null;
  CustomPrivacyStatement: string | null;
  CustomLogoUrl: string | null;
  EventCategory: any;
  AllowShowingInCloudLabs: boolean;
  EnableLeaderboard: boolean;
  EnableLabValidation: boolean;
  EnableSnapshot: boolean;
  BackupLimitPerVM: number;
  EnableAccessOverHttp: boolean;
  LicenseLabel: string | null;
  ActiveVMDurationLimit: string | null;
  EnableResourceOperations: boolean;
  LabType: string | null;
  LinkToInstructorVideo: string | null;
  LabDescription: string | null;
  ClusterId: number | null;
  ODLUsagePurpose: string | null;
  LocalizationLanguage: string | null;
  FormattedLabDescription: string | null;
  LabEstimateInMinutes: string | null;
  ACIDeploymentType: number | null;
  AllowToSelectRegion: boolean;
  ParentEventId: number | null;
  PurchaseOrder: string | null;
  EnableHotInstanceCleanUp: boolean;
  HotInstanceLifeTime: string | null;
  ExpiryDate: string | null;
  UserSelectedRegion: any;
  CurrentVMName: any;
  LanguageCode: string;
  UsagePurposeId?: any;
  InformationMessage?: any;
  EnableCustomFooter: boolean;
  SupportEmail: string;
  TermsofUseURL: string | null;
  UserRegion: string | null;
  TransparentPartnerheaderLogo: string | null;
  DisplayName?: string | null;
  UserLabExperienceTypes: UserLabExperienceType[];
  AllowedExtendDurationByAttendee: number;
  IsOpenAILab?: string | null;
  OpenAIUserCredit?: number | null;
}

export interface UserLabExperienceType {
  Id: number;
  Name: string;
  DisplayName: string;
  IsActive: boolean;
}

export interface LabAttendee {
  ADSetupStatus: boolean;
  AttendeeEmail: null | string;
  AttendeeId: null | string;
  CloudUserId: null | string;
  CurrentStatus: null | string;
  DeploymentName: null | string;
  DeploymentStatus: string;
  Email: string;
  EventId: number;
  EventUniqueName: null | string;
  Id: number;
  InternalId: string;
  IsActive: true;
  IsAzurePassActive: false;
  IsDeploymentActive: true;
  IsSharedTestDrive: false;
  IsTestDriveUser: false;
  LabAccessStatus: number;
  LabStatus: null | string;
  LicenseStatus: false;
  Name: null | string;
  ODLApprovalType: number;
  Regions: null | string;
  RegisteredFor: null | string;
  ResourceGroupName: string;
  ResourceGroupRegion: string;
  Status: any;
  SubscriptionFriendlyName: string;
  SubscriptionTenantDomainName: string;
  UserStatus: number;
  Video: null | string;
  VideoAccessStatus: number;
  VideoApprovalType: null | string;
  VoucherId: null | string;
}

export interface Region {
  Id: number;
  RegionName: string;
  GeographicLocation: null | string;
  PairedRegion: any;
}

export interface Deployment {
  AADDomain: string;
  AADEmail: string;
  AADId: string;
  AADSPAppId: string;
  AADSPAppKey: string;
  AADSPAppObjectId: string;
  AADSPDisplayName: string;
  AADSPObjectId: string;
  ActiveVMDuration: null | string;
  AllowIntelligenceToSelfHeal: false;
  AllowUserToExtendLab: false;
  AttendeeId: null | string;
  BadgeName: null | string;
  BadgeUniqueId: null | string;
  BadgeUrl: null | string;
  BlockExpiration: false;
  CloudPlatformId: number;
  CloudUserId: number;
  ConfiguredAzurePassSubscription: false;
  ConnectChannel: null | string;
  CreatedTime: string;
  CustomInstruction?: string;
  Createdby: null | string;
  ComputeVMUsage?: boolean;
  CurrentStatus: string;
  DeploymentName: null | string;
  DeploymentOutputValues: [];
  DeploymentStatus: string;
  Duration: number;
  EnableAttendeeTracking: false;
  EnableResourceOperations: true;
  EnableAttendeeEndTime: false;
  EndTime: null | string;
  EnvironmentIdleTimeout: null | string;
  ExtendDuration: null | string;
  Id: number;
  InternalId: string;
  IsActive: false;
  IsDeploymentActive: false;
  IsExtendable: null | string;
  IsGitDocAvailable: true;
  IsSharedTestDrive: false;
  LabGuidUrl: null | string;
  LabInformationURL: null | string;
  Licenses: [];
  MaxUserDurationExtension: null | string;
  ModifiedBy: null | string;
  PreGuideUrl: null | string;
  RegisteredFor: null | string;
  RemainingCredit: null | string;
  ResourceGroupName: string;
  ResourceGroupRegion: string;
  Result: null | string;
  StartTime: string;
  StartTimeAdjustedBasedOnLabRequest: null | string;
  Status: number;
  SubscriptionFriendlyName: string;
  SubscriptionGuid: string;
  SubscriptionId: number;
  SurveyGuid: null | string;
  TempPassword: string;
  TenantDomainName: string;
  TenantId: string;
  TestDriveId: number;
  VMLaunchURL: [];
  VMUpTime: number;
  VoucherCode: null | string;
  PracticeTestUrl?: string;
  EndDate: null;
  ShowResourcesTab: boolean;
  AllocatedTestDriveViewModalDetails?: any
}

export enum LabModes {
  ANONYMOUS = 'Anonymous',
  INFORMATION = 'Information',
  LAB = 'Lab',
}

export enum UserLabType {
  RDP_Over_Http = 1,
  Databricks_IFrame = 2,
  Databricks_NewTab = 3,
}

export enum OperationModes {
  INITIATION = 'Initiation',
  UNINITIATED = 'UnInitiated',
  AZUREPASSINITIATION = 'AzurePassInitiation',
  AZUREPASSUSERDETAILS = 'AzurePassUserDetails',
  GRABAZUREPASS = 'GrabAzurePass',
  INPROGRESS = 'InProgress',
  FAILED = 'Failed',
  POLICYFAILEDAFTERDEPLOYMENT = 'PolicyFailedAfterDeployment',
  DEALLOCATING = 'Deallocating',
  DEALLOCATED = 'Deallocated',
  DEPLOYED = 'Deployed',
  ACTIVATIONCLOSED = 'ActivationClosed',
  EXPIRED = 'Expires',
  GATHERINGINFO = 'GatheringInfo',
}

export interface DatabricksResource {
  ResourceType: string;
  ResourceId: string;
  ResourceName: string;
  ResourceStatus: string;
  TotalUpTime: number;
  IsSharedResource: boolean;
}

export enum CustomerType {
  DATABRICKS = 'DATABRICKS',
  PAN = 'PALO-ALTO',
  OTHERS = 'OTHERS',
}

export class LabPreviewModel {
  Name: string;
  Language: string;
  Files: LabPreviewFileModel[];
  EventName: string;
  DoNotSendCloudCredentials: boolean;
  AllowExistingAD: boolean;
  LicenseLabel: string;
  IsTrial: boolean;
  TrialEndPageURL: string;
  BaseURL: string;
  AssesmentFolderPath: string;
}

export class LabPreviewFileModel {
  RawFilePath: string;
  Order: string;
  RawFileBaseURL: string;
}

export enum AppType {
  LAB = 'lab',
  ATTENDEE = 'attendee',
}

export enum EnvironmentLayout {
  DATABRICKS = 'databricks',
  VM = 'VM',
}

export enum ActionItems {
  RELOAD = 'RELOAD',
  DEALLOCATE = 'DEALLOCATE',
  EXTEND = 'Extend',
  EXT_CREDIT = 'EXT_CREDIT',
}
export interface ActionListItem {
  name: string;
  icon: string;
  action: string;
}

export const CustomPortalInputValidators = {
  Text: '^(?!.*\\.{2})[a-zA-Z.-]+(?:\\s[a-zA-Z.-]+)*$',
  Email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
  WorkPhone: '^(\\+\\d{1,3}( )?)?\\d{6,15}$',
  MSTranscript:
    '^https?://(?:www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$',
};

export const MaxLengthValidation = {
  LongLength: 100,
  MidLength: 60,
  ShortLength: 50,
  GalleryTitleLength: 80,
  ExtendQuotaValue: 720,
};

export const MaxValueValidation = {
  ExtendQuotaValue: 720,
  TotalAttemptsValue: 99,
};

export enum ContainerAction {
  Start = 'start',
  Stop = 'stop',
  Rrstart = 'restart',
}

export const ExtendCreditStatus = {
  REQUEST_APPROVED: 'REQUEST_APPROVED',
  REQUEST_REJECTED: 'REQUEST_REJECTED',
  REQUEST_PENDING: 'REQUEST_PENDING',
  REQUEST_EXPIRED: 'REQUEST_EXPIRED',
  REQUEST_NOT_FOUND: 'REQUEST_NOT_FOUND',
};

export interface ExtendRequestStatusObject {
  OperationType: string;
  Status: string;
  TabNavigation: string;
  ErrorMessage: string;
  ErrorDetail: string;
  Data: string;
  ErrorKey: string;
}
