<div>
    <!-- <ul class="singlequestion-list" id="main-landmark"
        [ngClass]="sectionType === miscellaneousConst.performanceQuizwithVM ? 'width-110' : ''"> -->
    <ul class="singlequestion-list"
        [ngClass]="{'width-110':sectionType === miscellaneousConst.performanceQuizwithVM, 'margin-botom-0':isInlineQuestion}">
        <li [ngClass]="{'p-0':isInlineQuestion}">
            <span *ngIf="!isInlineQuestion" class="sql-title">{{ 'exam.questions.questionSection' | translate }}</span>
            <div class="sql-ques case-study-sql-ques" [class.mb-0]="isInlineQuestion">
                <h4 *ngIf="!isInlineQuestion" role="heading" aria-level="2" class="" [innerHTML]="question?.question | targetBlank"></h4>
                <div class="subheading" *ngIf="isInlineQuestion" role="heading" aria-level="2" class="mt-2" [innerHTML]="question?.question | targetBlank"></div>
                <!-- <h6 *ngIf="isInlineQuestion" role="heading" aria-level="2" class="mt-2" [innerHTML]="question?.question | targetBlank"></h6> -->
                <span *ngIf="question?.note !== null && !isInlineQuestion"><strong>{{ 'exam.questions.note' | translate }}: </strong> <span
                        [innerHTML]="question?.note"></span></span>
                <span *ngIf="question?.instruction !== null && !isInlineQuestion"><strong>{{ 'exam.questions.instruction' | translate
                        }}:</strong> <span [innerHTML]="question?.instruction"></span></span>
            </div>
            <span *ngIf="!isInlineQuestion" class="sql-title">{{ 'exam.questions.answerSection' | translate }}</span>
            <div class="sql-ans-box">
                <ng-container *ngIf="!isInlineQuestion">
                    <div class="sql-option-box"
                    [ngClass]="sectionType === miscellaneousConst.performanceQuizwithVM ? 'col-md-8' : ''"
                    *ngFor="let option of question?.options">
                    <teams-radio [value]='option.key' [id]="option.key" name="single-choice"
                        [checked]="option.key === option.checked" [(ngModel)]="option.checked"
                        (change)="selectSingleChoice(option)" [label]="option.value"
                        [labelClass]="'icon-cursor ts-radio-fluent-text'"></teams-radio>
                    </div>
                </ng-container>
                <ng-container *ngIf="isInlineQuestion">
                    <ng-container >
                        <div class="sql-option-box inline-question"
                        *ngFor="let option of question?.options">
                        <teams-radio class="d-flex" [isInlineQuest]="isInlineQuestion" [value]='option.key' [id]="option.key" name="single-choice" 
                        [ngClass]="option.isCorrect===true ? 'ts-radio-fluent-success':option.isCorrect===false?'ts-radio-fluent-failure':''"
                            [checked]="option.key === option.checked" [(ngModel)]="option.checked" [disabled]="maxNo"
                            (change)="selectSingleChoice(option)" [label]="option.value"
                            [labelClass]="'icon-cursor ts-radio-fluent-text'"></teams-radio>
                        </div>
                    </ng-container>
                    
                </ng-container>
                
            </div>
        </li>
    </ul>
</div>