import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  NgZone,
  ChangeDetectorRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { LabService } from '../../../services/lab.service';
import {
  CustomerType,
  Deployment,
  EnvironmentLayout,
  Lab,
} from '../../../modals/lab.model';
import { MarkdownService } from 'ngx-markdown';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../services/event.services';
import { htmlDecode, manageServiceVariables, stringToHtml } from '@utility';
import { NotificationService } from '../../../services/notification.service';
import { RemoteDesktopManager } from '../../../lib/remote';
import { EventEmitterService, UserSessionService } from '@teams-auth';
import { Subject } from 'rxjs';
import { GithubDataService, CommonService } from '@utility';
import { ToastrService } from 'ngx-toastr';
declare const $: any;
import * as moment from 'moment';
import { CommonService as CommonServicePT } from '@questions-ui';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'cloudlabs-doc-guide',
  templateUrl: './doc-guide.component.html',
  styleUrls: ['./doc-guide.component.scss'],
})
export class DocGuideComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: string;
  @Input() labDetail: Lab;
  @Input() voucherCode: string;
  @Input() lang: string;
  @Input() manager: RemoteDesktopManager;
  @Input() guideData: Deployment;
  @Input() isSidenav = false;
  @Input() mode;
  @Input() layoutType: string;
  @Input() isMobile: boolean;
  @Input() showResourcesTab: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onLoadingComplete = new EventEmitter();
  @Output() labIsCompleted = new EventEmitter<string>();
  @Output() spliLabIsCompleted = new EventEmitter<string>();
  @Output() collapseSideBar = new EventEmitter();
  @Input() splitKey: string;
  @Input() set initialDeployment(initialDeployment: Deployment) {
    this.initialDeploymentData = initialDeployment;
  }

  public loading = false;
  public minGuide = true;
  public tabView = 'guide';
  public osInfo: string;
  public currentTab: any;
  public itemsPerPage = 5;
  public showDropdown = false;
  public labGuideData = [];
  public p = 1;
  public gitHUbdocs: any;
  public guideTab: any = [];
  public EdgeMessage = false;
  public FirefoxHelpMessage = false;
  public attendeLabDetails: any;
  public IsSupportQuestFlag: boolean;
  public defaultProps = {
    style: '',
    enableCopy: true,
    enableClicktoPaste: false,
  };
  appType: string;
  private notifier = new Subject();
  vmTabKey: string;
  currentGuideKey: string;
  @Input() isSplitScreen = false;
  public index: any;
  public validationPattern = /(<validation[^>]*>)/;
  @Input() isMylearning;
  @Output() splitScreen = new EventEmitter();
  @Output() joinScreen = new EventEmitter();
  public environmentLayout = EnvironmentLayout;
  public customerType = CustomerType;
  private initialDeploymentData: Deployment;
  public currentLabguide = [];
  public groupedQuestPattern = /(<grouped-questions[^>]*>)/;
  public ungroupedQuestPattern = /(<question[^>]*>)/;
  public inlineVideoPattern = /(<inline-video[^>]*>)/;
  public testPattern =
    /(<validation[^>]*>)|(<grouped-questions[^>]*>)|(<question[^>]*>)|(<inline-video[^>]*>)/;
  public mdPath: any = [];
  public mdPathGroup: any = [];
  showSplitWindow = true;
  public videoPath: any = [];
  public videoWidth: any = [];
  public videoHeight: any = [];

  @HostListener('document:click', ['$event'])
  async handleClick($event) {
    if ($event.target.id !== 'showdropdown-menu') {
      this.showDropdown = false;
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.updatePreHeights();
  }

  constructor(
    private LabSrv: LabService,
    public markdownService: MarkdownService,
    private _clipboardService: ClipboardService,
    private translateSrv: TranslateService,
    private notificationService: NotificationService,
    private eventSrv: EventService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private userSessionService: UserSessionService,
    private gitHubSrv: GithubDataService,
    private commonSrv: CommonService,
    private eventEmitter: EventEmitterService,
    private tostrSrv: ToastrService,
    private commonServicePT: CommonServicePT,
    private route: Router,
    @Inject('environment') public environment
  ) {
    $('li.dropdown').hide();
    this.appType = environment.appType;
    this._clipboardService.copyResponse$.subscribe((re) => {
      if (re.isSuccess) {
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_COPIED'),
          null
        );
      }
    });
    this.osInfo = '';
  }

  async ngOnInit() {
    if (
      this.route.url.includes('virtual-machine') ||
      this.route.url.includes('template')
    ) {
      this.showSplitWindow = false;
    }
    this.detectOS();
    this.vmTabKey = `vmTab_${this.labDetail.UniqueName}_${this.voucherCode}`;
    this.currentGuideKey = `currentPage_${this.labDetail.UniqueName}_${this.voucherCode}`;
    this.lang = localStorage.getItem('language') || 'en';
    this.currentTab = localStorage.getItem(this.vmTabKey);
    this.loading = true;
    this.IsSupportQuestFlag = this.labDetail.IsSupportQuest;

    // ui reflect changes
    await this.ngZone.run(async () => {
      await this.getLabDocs();
    });

    this.attendeLabDetails = await this.LabSrv.getLabEnvironment(
      this.labDetail.UniqueName,
      this.voucherCode
    ).toPromise();
    this.createTab();
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    setTimeout(() => {
      // manage dynamic tabs calculation
      this.reFlow();
      $(window).on('resize', this.reFlow);
      $(window).on('resize-tabs', this.reFlow);
    }, 1500);

    $(document).ready(() => {
      if (/Edge/.test(navigator.userAgent)) {
        this.EdgeMessage = true;
      }
      if (navigator.userAgent.indexOf('Firefox') !== -1) {
        this.FirefoxHelpMessage = true;
      }
    });
    this.onLoadingComplete.emit(true);
    this.eventSrv.windowJoin.subscribe(() => {
      this.getVmTab();
      if (!this.currentLabguide[this.p]) {
        this.loadMdFiles();
      }
    });
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 1000);
    this.userSessionService.vmRenderOnLeftPanelChange$.subscribe(
      (boolValue) => {
        setTimeout(() => {
          this.reFlow();
        }, 1500);
      }
    );
    this.translateSrv.onLangChange.subscribe(async () => {
      this.reloadLabguide();

      // old code for language change
      // setTimeout(() => {
      //   this.getLabDocs();
      //   this.reFlow();
      // }, 1500);
    });
    this.eventSrv.changeTab.subscribe((res) => {
      this.tabView = 'environment';
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      for (const key in changes) {
        if (
          Object.prototype.hasOwnProperty.call(changes, key) &&
          changes[key].currentValue
        ) {
          this[key] = changes[key].currentValue;
        }
        if (
          !changes[key].isFirstChange() &&
          key === 'lang' &&
          (changes[key].currentValue ||
            // eslint-disable-next-line no-prototype-builtins
            (changes[key].hasOwnProperty('previousValue') &&
              changes[key].currentValue !== changes[key].previousValue))
        ) {
          // Old code for language changed
          // this.loading = true;
          // this.labGuideData = [];
          // this.gitHUbdocs = null;
          // this.p = 0;
          // this.guideTab = [];
          // this.currentLabguide = [];
          // await this.getLabDocs();
          // this.createTab();
          // // await this.ngOnInit();
          // this.loading = false;
          // setTimeout(() => {
          //   window.dispatchEvent(new Event('resize-tabs'));
          // }, 100);
        }
        if (key === 'showResourcesTab') {
          if (!this.showResourcesTab) {
            const index = this.guideTab.findIndex((item) => item.id === 4);
            if (index !== -1) {
              this.guideTab.splice(index, 1);
              this.cdr.detectChanges();
            }
          }
        }
      }
    }
    this.cdr.detectChanges();
  }

  async reloadLabguide() {
    this.loading = true;
    this.labGuideData = [];
    this.gitHUbdocs = null;
    this.p = 1;
    this.currentLabguide = [];
    await this.getLabDocs();
    this.loading = false;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize-tabs'));
    }, 100);
  }

  splitWindow() {
    this.splitScreen.emit();
  }

  joinWindow() {
    this.joinScreen.emit();
  }

  createTab() {
    if (this.guideData?.IsGitDocAvailable == true) {
      this.guideTab.push({
        id: 1,
        link: 'guide',
        title: 'HEADING_LAB_GUIDE',
        selected: true,
      });
    }
    if (this.labDetail) {
      this.guideTab.push({
        id: 2,
        link: 'environment',
        title: 'TAB_ENVIRONMENT_DETAILS',
        selected: false,
      });
    }
    if (
      this.labDetail?.EnableLabValidation === true &&
      !this.labDetail?.DisableValidationTab &&
      !this.IsSupportQuestFlag
    ) {
      this.guideTab.push({
        id: 3,
        link: 'validation',
        title: 'TAB_LAB_VALIDATION',
        selected: false,
      });
    }
    // if (
    //   (!this.guideData?.IsSharedTestDrive &&
    //     this.attendeLabDetails.CloudPlatformId === 1) ||
    //   this.guideData.CloudPlatformId == 1 ||
    //   this.guideData.CloudPlatformId == 2 ||
    //   this.guideData.CloudPlatformId == 3 ||
    //   (!this.guideData?.IsSharedTestDrive &&
    //     (this.attendeLabDetails.CloudPlatformId === 1 ||
    //       this.attendeLabDetails.CloudPlatformId === 2))
    // ) {

    if (this.showResourcesTab) {
      this.guideTab.push({
        id: 4,
        link: 'resources',
        title: 'TAB_RESOURCES',
        selected: false,
      });
    }
    // }
    if (this.labDetail) {
      this.guideTab.push({
        id: 5,
        link: 'help',
        title: 'HELP',
        selected: false,
      });
    }
    if (this.labDetail.LabLaunchPageDescription) {
      this.guideTab.unshift({
        id: 6,
        link: 'description',
        title: 'LAB_DESCRIPTION_TAB',
        selected: true,
      });

      if (!this.isSplitScreen) {
        this.tabView = 'description';
      }
    }
  }

  getcalue() {
    this.navigationAnalytics();
    const page = this.p - 1;
    const path = this.gitHUbdocs.Files[page].RawFilePath;
    const texting = path.substring(0, path.lastIndexOf('/'));
    this.loadMdFiles();
    this.markdownService.renderer.image = (
      href: string,
      title: string,
      text: string
    ) => {
      if (!href.includes('http:/') && !href.includes('https:/')) {
        return `<img alt ="${title || text}" src="${texting}/${href}">`;
      } else {
        return `<img alt ="${title || text}" src="${href}">`;
      }
    };
    const el = $(`#guideView`);
    el.animate({ scrollTop: 0 });
    setTimeout(() => {
      this.handleLabGuideElements(texting);
    }, 500);
    el.focus();
  }

  navigationAnalytics() {
    const data = {
      VoucherCode: this.voucherCode,
      VisitedPageNumber: this.p,
      TotalPages: this.gitHUbdocs.Files.length,
      VisitedTime: moment.utc().format(),
    };
    this.LabSrv.createNavigationAnalytics(data).subscribe();
  }

  async updatePageLock() {
    this.gitHUbdocs = await this.LabSrv.getGitHubDocs(
      this.labDetail.UniqueName,
      this.voucherCode
    );
    this.checkUnlockingRules();
  }

  checkUnlockingRules() {
    const filteredGuides = this.gitHUbdocs.Files.filter(
      (guide) => guide.HasUnlockingRules
    ).map((guide) => guide.Order);
    this.eventSrv.lockPages.next(filteredGuides);
    setTimeout(() => {
      const page = this.p > 0 ? this.p - 1 : 1;
      const path = this.gitHUbdocs.Files[page].RawFilePath;
      const texting = path.substring(0, path.lastIndexOf('/'));
      this.handleLabGuideElements(texting);
    }, 100);
  }

  loadMdFiles() {
    const page = this.p > 0 ? this.p - 1 : 1;
    if (!this.currentLabguide[this.p]) {
      this.gitHubSrv
        .getGitHubData(this.gitHUbdocs.Files[page].RawFilePath)
        .subscribe(
          (res) => {
            this.currentLabguide[this.p] = res;
            const path = this.gitHUbdocs.Files[page].RawFilePath;
            const texting = path.substring(0, path.lastIndexOf('/'));
            setTimeout(() => {
              this.handleLabGuideElements(texting);
            }, 3000);

            if (this.labDetail?.HasInlineQuestions) {
              this.currentLabguide[this.p]
                ?.split(this.ungroupedQuestPattern)
                .forEach((element) => {
                  if (this.ungroupedQuestPattern.test(element)) {
                    this.mdPath.push(this.getSourcePath(element));
                  }
                });
              this.currentLabguide[this.p]
                ?.split(this.groupedQuestPattern)
                .forEach((element) => {
                  if (this.groupedQuestPattern.test(element)) {
                    this.mdPathGroup.push(this.getSourcePath(element));
                  }
                });
              this.getlabguideQuestion();
            }
            this.currentLabguide[this.p]
              ?.split(this.inlineVideoPattern)
              .forEach((element) => {
                if (this.inlineVideoPattern.test(element)) {
                  this.videoPath.push(this.getSourcePath(element));
                  this.videoWidth.push(this.getWidth(element));
                  this.videoHeight.push(this.getHeight(element));
                }
              });
          },
          (err) => {
            this.currentLabguide[this.p] =
              'Error: One or more documents not found';
            const code = 'Error - 70018';
            this.eventEmitter.debugAlert(code, err.error);
          }
        );
    } else if (this.labDetail?.HasInlineQuestions) {
      this.currentLabguide[this.p]
        ?.split(this.ungroupedQuestPattern)
        .forEach((element) => {
          if (this.ungroupedQuestPattern.test(element)) {
            this.mdPath.push(this.getSourcePath(element));
          }
        });
      this.currentLabguide[this.p]
        ?.split(this.groupedQuestPattern)
        .forEach((element) => {
          if (this.groupedQuestPattern.test(element)) {
            this.mdPathGroup.push(this.getSourcePath(element));
          }
        });
      this.getlabguideQuestion();
      this.currentLabguide[this.p]
        ?.split(this.inlineVideoPattern)
        .forEach((element) => {
          if (this.inlineVideoPattern.test(element)) {
            this.videoPath.push(this.getSourcePath(element));
            this.videoWidth.push(this.getWidth(element));
            this.videoHeight.push(this.getHeight(element));
          }
        });
    }
  }

  /**
   * get lab docs md file
   */
  async getLabDocs() {
    if (
      this.guideData.IsGitDocAvailable == true
      // Commenting this code for Databrick WorkSpace - Vijay Chaudhary (320)
      //|| !this.labDetail.EnableAccessOverHttp
    ) {
      this.labGuideData = [];
      this.gitHUbdocs = null;
      this.gitHUbdocs = await this.LabSrv.getGitHubDocs(
        this.labDetail.UniqueName,
        this.voucherCode
      );
      if (this.labDetail.EnableInlineValidation) {
        this.checkUnlockingRules();
      }
      this.navigationAnalytics();
      if (this.gitHUbdocs.EventName && this.gitHUbdocs.EventName !== '') {
        this.LabSrv.guideTitle.next(this.gitHUbdocs.EventName);
      }
      if (this.gitHUbdocs.Files.length > 0) {
        this.gitHUbdocs.Files.sort((a, b) => a.Order - b.Order);
        this.getVmTab();
        // manage the markdown renderers
        const path = this.gitHUbdocs.Files[0].RawFilePath;
        const texting = path.substring(0, path.lastIndexOf('/'));

        this.markdownService.renderer.code = (code: string) => {
          const injectTagRegex =
            /<inject\s+(key="([^"]*)")?\s*(cloudname="([^"]*)")?\s*(style="([^"]*)")?\s*(enableCopy="([^"]*)")?\s*(enableClicktoPaste="([^"]*)")?\s*\/?>/gi;

          let style = '';
          let enableCopy = true;
          let enableClicktoPaste = false;

          code = code.replace(
            injectTagRegex,
            (
              _,
              keyAttr,
              key,
              cloudnameAttr,
              cloudnameValue,
              styleAttr,
              styleValue,
              enableCopyAttr,
              enableCopyValue,
              enableClicktoPasteAttr,
              enableClicktoPasteValue
            ) => {
              key = key.replace(/ +/g, '').toLowerCase();

              cloudnameValue = cloudnameValue?.toLowerCase();
              let deploymentOutputValues: any = {};
              let guideDataValues: any = {};
              let initialDeploymentValues: any = {};

              if (
                this.attendeLabDetails.AllocatedTestDriveViewModalDetails
                  .length > 1
              ) {
                deploymentOutputValues =
                  this.attendeLabDetails.AllocatedTestDriveViewModalDetails.find(
                    (item) => item.FriendlyName?.toLowerCase() === cloudnameValue
                  )?.DeploymentOutputValues;
                guideDataValues =
                  this.guideData.AllocatedTestDriveViewModalDetails.find(
                    (item) => item.FriendlyName?.toLowerCase() === cloudnameValue
                  );
                initialDeploymentValues =
                  this.initialDeploymentData.AllocatedTestDriveViewModalDetails.find(
                    (item) => item.FriendlyName?.toLowerCase() === cloudnameValue
                  );
              } else {
                deploymentOutputValues =
                  this.attendeLabDetails.AllocatedTestDriveViewModalDetails[0]
                    ?.DeploymentOutputValues;
                guideDataValues =
                  this.guideData.AllocatedTestDriveViewModalDetails[0];
                initialDeploymentValues =
                  this.initialDeploymentData
                    .AllocatedTestDriveViewModalDetails[0];
              }

              const replacements = {
                azureaduseremail: guideDataValues?.AADEmail || '',
                azureaduserpassword: guideDataValues?.TempPassword || '',
                accesskey: guideDataValues?.AADSPAppId || '',
                displayname: guideDataValues?.AADSPDisplayName || '',
                secretkey: guideDataValues?.AADSPAppKey || '',
                subscriptionid: guideDataValues?.SubscriptionGuid || '',
                tenantid: guideDataValues?.TenantId || '',
                tenantdomainname: guideDataValues?.TenantDomainName || '',
                cloudlabsdeploymentid:
                  initialDeploymentValues?.CloudUserId || '',
              };

              // Iterate through OutputValues and add to replacements
              if (
                deploymentOutputValues &&
                Array.isArray(deploymentOutputValues.DeploymentOutputValues)
              ) {
                deploymentOutputValues.DeploymentOutputValues.forEach(
                  (deployment: any) => {
                    deployment.OutputValues.forEach((outputValue: any) => {
                      replacements[
                        outputValue.Name.replace(/ +/g, '').toLowerCase()
                      ] = outputValue.Value;
                    });
                  }
                );
              }

              // Update style if available
              if (styleAttr && styleValue) {
                style = styleValue;
              }
              // Handle enableCopy attribute
              enableClicktoPaste = enableClicktoPasteValue;
              enableCopy = enableCopyValue === 'false' ? false : true;

              return replacements[key] ?? '';
            }
          );
          code = code.replace(/<\/inject>/g, '');
          code = code.replace(/</g, '&lt;').replace(/>/g, '&gt;');
          if (enableClicktoPaste) {
            return `<pre class="line-numbers language-none" tabindex="0"><code class="language-none" enableClicktoPaste="${enableClicktoPaste}" style="${style}">${code}</code></pre>`;
          } else {
            return `<pre class="line-numbers language-none" tabindex="0"><code class="language-none" enablecopy="${enableCopy}" style="${style}">${code}</code></pre>`;
          }
        };

        this.markdownService.renderer.html = (htmlTag: string) => {
          if (htmlTag === '</inject>') {
            return '';
          }
          const props = { ...this.defaultProps };
          return this.commonSrv.getAllAttributes(
            props,
            htmlTag,
            this.attendeLabDetails,
            this.guideData,
            this.initialDeploymentData
          );
        };

        this.commonSrv.getImageTag(path, this.markdownService);
        this.commonSrv.getLinks(this.markdownService);

        this.loadMdFiles();

        // const gitdocObserables = this.gitHUbdocs.Files.map((file) =>
        //   this.gitHubSrv.getGitHubData(file.RawFilePath)
        // );
        // combineLatest(gitdocObserables)
        //   .pipe(
        //     takeUntil(this.notifier),
        //     map((resp) =>
        //       resp.map((res, index) => ({
        //         res,
        //         order: this.gitHUbdocs.Files[index].Order,
        //       }))
        //     )
        //   )
        //   .subscribe(
        //     (res) => {
        //       this.labGuideData = res;
        //       this.labGuideData.sort((x, y) => {
        //         return x.order - y.order;
        //       });
        //       setTimeout(() => {
        //         this.handleLabGuideElements(texting);
        //       }, 3000);
        //     },
        //     (err) => {
        //       this.labGuideData = [
        //         { res: 'Error: One or more documents not found', order: 1 },
        //       ];
        //       const code = 'Error - 70018';
        //       this.eventEmitter.debugAlert(code, err.error);
        //     }
        //   );
      }
    } else if (this.labDetail.LabLaunchPageDescription) {
      this.tabView = 'description';
    } else {
      this.tabView = 'environment';
      this.collapseSideBar.emit();
    }
  }

  async completeTheLab(event: string) {
    if (localStorage.getItem(this.splitKey)) {
      this.spliLabIsCompleted.emit(event);
      localStorage.setItem(`delete-lab-${this.voucherCode}`, event);
    }
    this.labIsCompleted.emit(event);
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }
  /**
   * selected tab
   * @param item
   */

  viewTab(item, list, spliceItem: boolean, spliceItem2: boolean) {
    this.tabView = item;
    setTimeout(() => {
      this.handleLabGuideElements('');
    }, 500);
    this.guideTab.forEach((element) => {
      if (element.title == list.title) {
        element.selected = true;
        localStorage.setItem(this.vmTabKey, element.link);
      } else {
        element.selected = false;
      }
    });
  }

  reFlow() {
    let tabHidden = false;
    const tab_wrapper = $('.resize-tab');
    const wrapper_width = tab_wrapper.width();
    const dropdown_width = tab_wrapper.find('li.dropdown').width();
    let width_sum = 0;
    tab_wrapper.find('>li:not(li.dropdown)').each(function (index, elm) {
      width_sum += $(elm).outerWidth(true);
      if (width_sum + dropdown_width + 10 > wrapper_width) {
        if (
          $(elm).outerWidth(true) <=
            tab_wrapper.find('li.dropdown').outerWidth(true) + 10 &&
          $(elm).index() ===
            tab_wrapper.find('>li:not(li.dropdown)').length - 1 &&
          !tabHidden
        ) {
          $(elm).show();
        } else {
          tabHidden = true;
          $(elm).hide();
        }
      } else {
        $(elm).show();
      }
    });
    const hidden_lists = tab_wrapper.find('>li:not(li.dropdown):not(:visible)');
    if (hidden_lists.length > 0) {
      $('li.dropdown').show();
      tab_wrapper.find('ul.dropdown-menu li').hide();
      hidden_lists.map(function (element) {
        tab_wrapper
          .find('ul.dropdown-menu li#' + hidden_lists[element].id + '-dup')
          .show();
      });
    } else {
      $('li.dropdown').hide();
    }
  }

  handleLabGuideElements(texting) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const defaultProps = { ...this.defaultProps };
    if (texting !== '') {
      $('#guideView img').each(function (index, elm) {
        // Add tab index to all images
        $(elm).attr('tabindex', '0');
        $(elm).addClass('img-click');

        if ($(elm).attr('src') && !$(elm).attr('src').includes('http')) {
          $(elm).attr('src', texting + '/' + $(elm).attr('src'));
        }
      });
    } else {
      $('#guideView img').each(function (index, elm) {
        // Add tab index to all images
        $(elm).attr('tabindex', '0');
        $(elm).addClass('img-click');
      });
    }
    const clickToCopyText = this.translateSrv.instant('COPY');
    const clickToPasteText = this.translateSrv.instant('CLICK_TO_PASTE');
    $('#guideView .copydetails').each(function () {
      $(this).find('a').remove();
      let text = $(this).text();
      if (text !== undefined && text !== null && text !== '') {
        text = text.trim();
      }

      const ariaLabel = $(this).attr('aria-label');
      $(this).append(
        '<a style="' +
          $(this).attr('style') +
          '" tabindex="0" role="button" aria-label="' +
          clickToCopyText +
          ' ' +
          ariaLabel +
          '"  data-toggle="tooltip" ' +
          '  title="' +
          clickToCopyText +
          ' ' +
          ariaLabel +
          '" alt="" class="fa fa-clone copyelement">' +
          text +
          '</a>'
      );
      // set tooltip
      $('[data-toggle="tooltip"]').tooltip({
        placement: 'bottom',
        trigger: 'hover',
      });
    });
    $('#guideView .copyPasteDetails').each(function () {
      $(this).find('a').remove();
      let text = $(this).text();
      if (text !== undefined && text !== null && text !== '') {
        text = text.trim();
      }

      const ariaLabel = $(this).attr('aria-label');
      $(this).append(
        '<a id="pasteInsideVM" style="' +
          $(this).attr('style') +
          '" tabindex="0" role="button" aria-label="' +
          clickToPasteText +
          ' ' +
          ariaLabel +
          '"  data-toggle="tooltip" ' +
          '  title="' +
          clickToPasteText +
          ' ' +
          ariaLabel +
          '" alt="" class="fa fa-paste copyelement">' +
          text +
          '</a>'
      );
      // set tooltip
      $('[data-toggle="tooltip"]').tooltip({
        placement: 'bottom',
        trigger: 'hover',
      });
    });
    $('#guideView pre').each(function () {
      $(this).find('a').remove();
      const text = $(this).find('code')[0].innerHTML;
      const htmlText = htmlDecode(text);
      const elm = stringToHtml(htmlText);
      let outputValue;
      const props = { ...defaultProps };
      if (elm) {
        const key = elm.getAttribute('key').replace(/ +/g, '').toLowerCase();

        const cloudName = elm
          .getAttribute('cloudname')
          .replace(/ +/g, '')
          ?.toLowerCase();
        let deploymentOutputValues;
        let guideDataValues;
        if (
          self.attendeLabDetails.AllocatedTestDriveViewModalDetails.length > 1
        ) {
          deploymentOutputValues =
            self.attendeLabDetails.AllocatedTestDriveViewModalDetails.find(
              (item) => item.FriendlyName?.toLowerCase() === cloudName
            )?.DeploymentOutputValues;
          guideDataValues =
            self.guideData.AllocatedTestDriveViewModalDetails.find(
              (item) => item.FriendlyName?.toLowerCase() === cloudName
            );
        } else {
          deploymentOutputValues =
            self.attendeLabDetails.AllocatedTestDriveViewModalDetails[0]
              ?.DeploymentOutputValues;
          guideDataValues =
            self.guideData.AllocatedTestDriveViewModalDetails[0];
        }

        if (elm.hasAttribute('enableCopy')) {
          props.enableCopy = Boolean(elm.getAttribute('enableCopy') === 'true');
        }
        if (elm.hasAttribute('enableClicktoPaste')) {
          props.enableClicktoPaste = Boolean(
            elm.getAttribute('enableClicktoPaste') === 'true'
          );
          props.enableCopy = props.enableClicktoPaste
            ? false
            : props.enableCopy;
        }
        if (elm.hasAttribute('style')) {
          props.style = elm.getAttribute('style');
        }

        if (deploymentOutputValues && deploymentOutputValues.length > 0) {
          deploymentOutputValues.forEach((item) => {
            const { OutputValues } = item;
            if (
              OutputValues &&
              OutputValues.length > 0 &&
              OutputValues.findIndex(
                (value) => value.Name.replace(/ +/g, '').toLowerCase() === key
              ) > -1
            ) {
              outputValue = OutputValues.find(
                (value) => value.Name.replace(/ +/g, '').toLowerCase() === key
              );
            }
          });
        }

        // manage the service variable
        if (guideDataValues) {
          const Value = manageServiceVariables(key, guideDataValues);
          if (Value !== '') {
            outputValue = { Value };
          }
        }
      }

      const value = outputValue !== undefined ? outputValue.Value : text;
      if (outputValue !== undefined) {
        $(this).find('code')[0].innerHTML = value;
      }
      // handle css style on variables
      if (props.style) {
        const codeElm = $(this).find('code')[0];
        if (codeElm) {
          $(codeElm).attr('style', props.style);
        }
      }
      const enableClicktoPaste = props.enableClicktoPaste
        ? props.enableClicktoPaste
        : $(this).find('code')[0]?.attributes['enableclicktopaste']?.value;
      const enablecopy =
        $(this).find('code')[0]?.attributes['enablecopy']?.value || true;
      props.enableCopy = enablecopy === 'false' ? false : true;
      const heightinPX = $(this).css('height');
      $(this).css('display', 'inline-block');
      if (
        $(this).next('a').length === 0 &&
        parseInt(heightinPX.slice(0, -2)) > 0
      ) {
        $(this).after(
          `<a tabindex="0" title="${
            enableClicktoPaste ? clickToPasteText : clickToCopyText
          }" role="button" data-toggle="tooltip" aria-label="${
            enableClicktoPaste ? clickToPasteText : clickToCopyText
          }" alt="" id="${
            enableClicktoPaste ? 'pasteInsideVM' : ''
          }" class="fa ${
            enableClicktoPaste ? 'fa-paste' : 'fa-clone'
          } copyelement" style="bottom:${
            parseInt(heightinPX.slice(0, -2)) - 10
          }px;left:0.4rem"><p style="display:none">${value.trimEnd()}</p></a>`
        );
        $('[data-toggle="tooltip"]').tooltip({
          placement: 'bottom',
          trigger: 'hover',
        });
      }
    });
    $('.selfnavigation').on('click', function () {
      const id = $(this).data('id');
      const elementID = id.replace('#', '');
      const el = document.getElementById(elementID);
      el.scrollIntoView();
    });
  }
  getVmTab(): void {
    if (localStorage.getItem(this.currentGuideKey)) {
      this.p = Number(localStorage.getItem(this.currentGuideKey));
    }
    this.currentTab = localStorage.getItem(this.vmTabKey);
    if (this.currentTab !== null) {
      this.tabView = this.currentTab;
    } else if (this.guideData.IsGitDocAvailable) {
      this.tabView = 'guide';
    } else {
      this.tabView = 'environment';
    }
    const page = this.p > 0 ? this.p - 1 : 1;
    const path = this.gitHUbdocs.Files[page].RawFilePath;
    const texting = path.substring(0, path.lastIndexOf('/'));
    setTimeout(() => {
      this.handleLabGuideElements(texting);
    }, 500);
  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private updatePreHeights(): void {
    $('#guideView pre').each(function () {
      const heightinPX = $(this).css('height');
      if ($(this).next('a').length > 0) {
        $(this)
          .next('a')
          .css('bottom', parseInt(heightinPX.slice(0, -2)) - 10 + 'px');
      }
    });
  }

  private detectOS(): void {
    const userAgent = window.navigator.userAgent;
    if (/Windows/.test(userAgent)) {
      this.osInfo = 'Windows';
    } else if (/Macintosh/.test(userAgent)) {
      this.osInfo = 'Mac OS';
    } else if (/Android/.test(userAgent)) {
      this.osInfo = 'Android';
    } else if (/Linux/.test(userAgent)) {
      this.osInfo = 'Linux';
    } else if (/iOS/.test(userAgent)) {
      this.osInfo = 'iOS';
    } else {
      this.osInfo = 'Unknown';
    }
  }

  getUngroupQuestionData(data: any) {
    const questData = this.mdPath.find((e) => data.indexOf(e.md) > -1);
    if (questData?.childData) {
      this.userSessionService.isQuestionLoaded$.next(true);
    }
    return questData?.childData;
  }
  getGroupQuestionData(data: any) {
    const questData = this.mdPathGroup.find((e) => data.indexOf(e.md) > -1);
    if (questData?.childData) {
      this.userSessionService.isQuestionLoaded$.next(true);
    }
    return questData?.childData;
  }

  public getlabguideQuestion(): void {
    this.commonServicePT
      .getlabguideQuestion(this.voucherCode, this.p)
      .subscribe(
        (res: any) => {
          // const res = decryptBackendResponse(encryptRes, this.userSessionService.getOriginalKeyPair().private);
          if (res?.status === 'Error') {
            const code = 'Error - 30052';
            this.notificationService.error(res.message, null);
            this.eventEmitter.debugAlert(code, res.message);
          } else {
            const ungroupedQuestions = res.ungroupedQuestions;
            this.mdPath = this.mdPath.map((e: any) => {
              const findQuestFileName = ungroupedQuestions?.find(
                (r: any) => r.questionFileName == e
              );
              const question = `<question source="${e}" />`;
              e = {
                md: e,
                childData: findQuestFileName,
                questionTag: question,
              };
              return e;
            });
            const groupedQuestions = res.groupedQuestions;
            this.mdPathGroup = this.mdPathGroup.map((e: any) => {
              const findgroupFileName = groupedQuestions?.find(
                (r: any) => r.groupFileName == e
              );
              const question = `<grouped-questions source="${e}" />`;
              e = {
                md: e,
                childData: findgroupFileName,
                questionTag: question,
              };
              return e;
            });
          }
        },
        (err) => {
          const code = 'Error - 30053';
          this.eventEmitter.debugAlert(code, err);
        }
      );
  }

  private getSourcePath(inlineQuestionTag): string {
    const regexr = /source="([^"]+)"/;
    const match = regexr.exec(inlineQuestionTag);
    if (match && match.length > 1) {
      const questionMdUrl = match[1];
      return questionMdUrl;
    }
    return '';
  }

  getVideoLink(data: any) {
    const videoLink = this.videoPath.find((e) => data.indexOf(e) > -1);
    return videoLink;
  }

  getVideoWidth(data: any) {
    const width = this.videoWidth.find((e) => data.indexOf(e) > -1);
    return width;
  }

  getVideoHeight(data: any) {
    const height = this.videoHeight.find((e) => data.indexOf(e) > -1);
    return height;
  }

  private getWidth(inlineQuestionTag): string {
    const regexr = /width="([^"]+)"/;
    const match = regexr.exec(inlineQuestionTag);
    if (match && match.length > 1) {
      const width = match[1];
      return width;
    }
    return '';
  }

  private getHeight(inlineQuestionTag): string {
    const regexr = /height="([^"]+)"/;
    const match = regexr.exec(inlineQuestionTag);
    if (match && match.length > 1) {
      const height = match[1];
      return height;
    }
    return '';
  }
}
