export const AppInsightEvents = {
    REGISTRATION_SCREEN_VISIT:"REGISTRATION_SCREEN_VISIT",
    REGISTRATION_SUCCESS:"REGISTRATION_SUCCESS",
    REGISTRATION_FAILED:"REGISTRATION_FAILED",
    DEPLOYMENT_INITIATION:"DEPLOYMENT_INITIATION",
    DEPLOYMENT_FAILED:"DEPLOYMENT_FAILED",
    DEPLOYMENT_SUCCESS:"DEPLOYMENT_SUCCESS",
    RDP_OVER_HTTPS_SCREEN_VISIT:"RDP_OVER_HTTPS_SCREEN_VISIT",
    RDP_OVER_HTTPS_SUCCESS:"RDP_OVER_HTTPS_SUCCESS",
    RDP_OVER_HTTPS_FAILED:"RDP_OVER_HTTPS_FAILED",
    REDEEM_VOUCHER_SUCCESS:"REDEEM_VOUCHER_SUCCESS",
    REDEEM_VOUCHER_FAILED:"REDEEM_VOUCHER_FAILED",
    REDEEM_ACTIVATION_CODE_SUCCESS:"REDEEM_ACTIVATION_CODE_SUCCESS",
    REDEEM_ACTIVATION_CODE_FAILED:"REDEEM_ACTIVATION_CODE_FAILED",
    VIRTUAL_MACHINE_START: "VIRTUAL_MACHINE_START",
    VIRTUAL_MACHINE_FAILED: "VIRTUAL_MACHINE_FAILED",
    VIRTUAL_MACHINE_STATUS: "VIRTUAL_MACHINE_STATUS",
    CONTAINER_START: "CONTAINER_START",
    CONTAINER_FAILED: "CONTAINER_FAILED",
    CONTAINER_STATUS: "CONTAINER_STATUS",
    COURSE_SCREEN_VISIT: "COURSE_SCREEN_VISIT",
    COURSE_DETAIL_SCREEN_VISIT: "COURSE_DETAIL_SCREEN_VISIT",
    CATALOG_LAUNCH_SUCCESS: "CATALOG_LAUNCH_SUCCESS",
    CATALOG_LAUNCH_FAILED: "CATALOG_LAUNCH_FAILED",
    LAUNCH_LAB_COURSE_SUCCESS: "LAUNCH_LAB_COURSE_SUCCESS",
    LAUNCH_LAB_COURSE_EVENT_EXPIRED: "LAUNCH_LAB_COURSE_EVENT_EXPIRED",
    LAUNCH_LAB_COURSE_FAILED: "LAUNCH_LAB_COURSE_FAILED",
    COUPON_TO_USER_SUCCESS: "COUPON_TO_USER_SUCCESS",
    COUPON_TO_USER_FAILED: "COUPON_TO_USER_FAILED",
    PARENT_MARK_COMPLETED_SUCCESS: "PARENT_MARK_COMPLETED_SUCCESS",
    PARENT_MARK_COMPLETED_FAILED: "PARENT_MARK_COMPLETED_FAILED",
    MARK_COMPLETED_SUCCESS: "MARK_COMPLETED_SUCCESS",
    MARK_COMPLETED_FAILED: "MARK_COMPLETED_FAILED",
    TRANSCRIPT_URL_SUCCESS: "TRANSCRIPT_URL_SUCCESS",
    TRANSCRIPT_URL_FAILED: "TRANSCRIPT_URL_FAILED",
    BADGE_CERTIFICATE_SUCCESS: "BADGE_CERTIFICATE_SUCCESS",
    BADGE_CERTIFICATE_FAILED: "BADGE_CERTIFICATE_FAILED",
    CREDLY_BADGE_SUCCESS: "CREDLY_BADGE_SUCCESS",
    CREDLY_BADGE_FAILED: "CREDLY_BADGE_FAILED",
    PORTAL_CERTIFICATE_SUCCESS: "PORTAL_CERTIFICATE_SUCCESS",
    PORTAL_CERTIFICATE_FAILED: "PORTAL_CERTIFICATE_FAILED",
    PRACTICE_TEST_SCREEN_VISIT: "PRACTICE_TEST_SCREEN_VISIT",
    PRACTICE_TEST_DETAIL_SCREEN_VISIT: "PRACTICE_TEST_DETAIL_SCREEN_VISIT",
    PRACTICE_TEST_PROCESSED: "PRACTICE_TEST_PROCESSED",
    PRACTICE_TEST_STARTED: "PRACTICE_TEST_STARTED",
    PRACTICE_TEST_LOADED: "PRACTICE_TEST_LOADED",
    PRACTICE_TEST_LOADED_FAILED: "PRACTICE_TEST_LOADED_FAILED",
    PRACTICE_TEST_CREATE_INSTANCE_SUCCESS: "PRACTICE_TEST_CREATE_INSTANCE_SUCCESS",
    PRACTICE_TEST_CREATE_INSTANCE_FAILED: "PRACTICE_TEST_CREATE_INSTANCE_FAILED",
    PRACTICE_TEST_REPORT_SCREEN_VISIT: "PRACTICE_TEST_REPORT_SCREEN_VISIT",
    PRACTICE_TEST_REPORT_SUCCESS: "PRACTICE_TEST_REPORT_SUCCESS",
    PRACTICE_TEST_REPORT_FAILED: "PRACTICE_TEST_REPORT_FAILED",
    PRACTICE_TEST_RESULT_SCREEN_VISIT: "PRACTICE_TEST_RESULT_SCREEN_VISIT",
    PRACTICE_TEST_RESULT_SUCCESS: "PRACTICE_TEST_RESULT_SUCCESS",
    PRACTICE_TEST_RESULT_FAILED: "PRACTICE_TEST_RESULT_FAILED",
    PRACTICE_TEST_REVIEW_SECTION_SCREEN_VISIT: "PRACTICE_TEST_REVIEW_SECTION_SCREEN_VISIT",
    LECTURE_SCREEN_VISIT: "LECTURE_SCREEN_VISIT",
    LECTURE_DETAIL_SCREEN_VISIT: "LECTURE_DETAIL_SCREEN_VISIT",
    LECTURE_VIDEO_PAUSED: "LECTURE_VIDEO_PAUSED",
    LECTURE_VIDEO_PERCENTAGE_SUCCESS: "LECTURE_VIDEO_PERCENTAGE_SUCCESS",
    LECTURE_VIDEO_PERCENTAGE_FAILED: "LECTURE_VIDEO_PERCENTAGE_FAILED",
    PORTAL_REDIRECTION:"PORTAL_REDIRECTION",
    PORTAL_LOGIN_SUCCESS:"PORTAL_LOGIN_SUCCESS",
    PORTAL_LOGIN_FAILURE:"PORTAL_LOGIN_FAILURE",
}

export const PropertyName = {
    ENTITY_ID:"ENTITY_ID",
    USER_ID:"USER_ID",
    ERROR_MESSAGE:"ERROR_MESSAGE",
    PAYLOAD_DATA:"PAYLOAD_DATA",
    ERROR_DATA:"ERROR_DATA",
    URL:"URL",
    OPERATION: "OPERATION",
    OPERATION_STATUS: "OPERATION_STATUS",
    PRE_URL:"PRE_URL"
}

export const RDPOperation = {
    VM_DEALLOCATED_OR_STOPPED:'VM_DEALLOCATED_OR_STOPPED',
    VM_QUOTA_EXHAUSTED:'VM_QUOTA_EXHAUSTED',
    GUACAMOLE_SESSION_FAILURE:'GUACAMOLE_SESSION_FAILURE',
    TOKEN_FETCH_FAILURE:'TOKEN_FETCH_FAILURE',
    QUICK_CONNECT_SESSION_FAILURE:'QUICK_CONNECT_SESSION_FAILURE',
    CONTAINER_FAILURE:'CONTAINER_FAILURE',
    VM_DISCONNECTED:'VM_DISCONNECTED'
}