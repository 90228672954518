import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Deployment, Lab } from '../../../modals/lab.model';
import { LabService } from '../../../services/lab.service';
import { NotificationService } from '../../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { videoUrlCheck } from '@utility';
import { ClipboardService } from 'ngx-clipboard';
import { EventEmitterService } from '@teams-auth';
import * as moment from 'moment';

@Component({
  selector: 'cloudlabs-trial-environment',
  templateUrl: './trial-environment.component.html',
  styleUrls: ['./trial-environment.component.scss'],
})
export class TrialEnvironmentComponent implements OnInit {
  @Input() labDetail: Lab;
  @Input() minGuide: boolean;
  @Input() voucherCode: string;
  @Input() type: string;
  @Input() mode: string;
  @Input() guideData: Deployment;
  @Input() labExpiryDuration;

  endDate: string;

  attendeLabDetails: any;
  dataArray: Array<any> = [];
  userVoucherCode: string;
  docUrl: string;
  videoUrl: string;
  videoPopup: boolean;
  dialogClass: string;
  labAssetDetail: any[];
  videoPopupData: any;
  rdtOrder = [
    { key: 'RDtHostURL', value: 'RDt Host URL' },
    { key: 'RDtUsername', value: 'RDt Username' },
    { key: 'RDtPassword', value: 'RDt Password' },
  ];
  dextrusOrder = [
    { key: 'DextrusAppLoginURL', value: 'Dextrus App Login URL' },
    { key: 'Username', value: 'Username' },
    { key: 'Password', value: 'Password' },
    { key: 'ClientName', value: 'Client Name' },
  ];
  constructor(
    private LabSrv: LabService,
    private aroute: ActivatedRoute,
    private clipboardService: ClipboardService,
    private notificationService: NotificationService,
    private translateSrv: TranslateService,
    private eventEmitter: EventEmitterService
  ) {}

  ngOnInit(): void {
    if (this.labExpiryDuration) {
      this.setRemainingTime();
    }
    const id = this.aroute.snapshot.params.id;
    const vCode = this.aroute.snapshot.params.voucherCode;
    this.LabSrv.getTemplateLabAssetDetail(id, vCode).subscribe(
      (res: any) => {
        this.labAssetDetail = res;
      },
      (error) => {
        const code = 'Error - 50030';
        this.eventEmitter.debugAlert(code, error);
      }
    );
    this.getEnvironment();
    this.clipboardService.copyResponse$.subscribe((re) => {
      if (re.isSuccess) {
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_COPIED'),
          null
        );
      }
    });
    this.dialogClass = 'ts-dialog-fluent ts-dialog-fluent-confirmation';
  }

  public isLink(str: string): boolean {
    const pattern = /(http(s)?:\/\/[^\s]+)/gi;
    return pattern.test(str);
  }

  private setRemainingTime() {
    const curDate: any = moment();
    this.endDate = moment(curDate.add(this.labExpiryDuration))
      .format('MMMM Do YYYY hh:mm A')
      .replace(/(\d)(st|nd|rd|th)/g, '$1<sup>$2</sup>');
  }

  async getEnvironment() {
    const data:any = await this.LabSrv.getLabEnvironment(
      this.labDetail.UniqueName,
      this.voucherCode
    ).toPromise();
    this.attendeLabDetails = data.AllocatedTestDriveViewModalDetails[0];
    if (this.attendeLabDetails.DeploymentOutputValues) {
      this.attendeLabDetails.DeploymentOutputValues.forEach((element) => {
        element.OutputValues.forEach((data) => {
          const dextrusIndex = this.dextrusOrder.findIndex(
            (e) => e.key === data.Name
          );
          const rdtIndex = this.rdtOrder.findIndex((e) => e.key === data.Name);
          if (data.ExcludeOutputParameter === false) {
            this.dataArray.push({
              key:
                dextrusIndex !== -1
                  ? this.dextrusOrder[dextrusIndex].value
                  : rdtIndex !== -1
                  ? this.rdtOrder[rdtIndex].value
                  : data.Name,
              value: data.Value,
              tooltipKey: 'CLICK_TO_COPY_FIELD',
            });
          }
        });
      });
      const orderToFollow = this.dataArray
        .map((e) => e.key)
        .includes(this.rdtOrder[0].value)
        ? this.rdtOrder
        : this.dataArray.map((e) => e.key).includes(this.dextrusOrder[0].value)
        ? this.dextrusOrder
        : [];
      if (orderToFollow.length !== 0) {
        this.dataArray.sort((a, b) => {
          return (
            orderToFollow.map((e) => e.value).indexOf(a.key) -
            orderToFollow.map((e) => e.value).indexOf(b.key)
          );
        });
      }
    }
  }

  watchVideo(data) {
    if (data.TargetLocation === 'popup') {
      this.videoPopupData = data;
      this.videoUrl = videoUrlCheck(data.AssetURL, 'true');
      this.videoPopup = true;
    } else {
      window.open(data.AssetURL, data.TargetLocation);
    }
  }

  openDoc(url, targetLocation) {
    window.open(url, targetLocation);
  }

  /**
   * @description: Close Confimation Popup
   */
  public closeConfirmResponse(event): void {
    this.videoPopup = event;
  }
}
