<div appTrapFocus appAutoFocusModal appRefocusPreviousEl role="dialog" aria-labelledby="extend-duration-head"
  aria-describedby="extend-duration-desc">
  <div class="modal-header" id="extend-duration-head">
    <h4 class="modal-title pull-left"> {{ title }} </h4>
  </div>
  <div class="modal-body" id="extend-duration-desc">
    <p-progressSpinner *ngIf="loading" class="custom-spinner" strokeWidth="5"
      animationDuration="10s"></p-progressSpinner>
    <div *ngIf="!loading">
      <div class="mb-4"
        *ngIf="data?.approvalRequired && statusDetails?.ErrorKey!==extendCreditStauts.REQUEST_NOT_FOUND">
        {{'REQUEST_STATUS' | translate}}: <b>{{statusDetails?.ErrorKey | translate}}</b>
      </div>
      <p *ngIf="statusDetails?.ErrorKey!==extendCreditStauts.REQUEST_PENDING && !data?.approvalRequired">
        {{'EXTEND_LAB_DURATION_DESCRIPTION' | translate:{unit: formatMinutes(data?.duration)} }}
      </p>
      <p *ngIf="statusDetails?.ErrorKey!==extendCreditStauts.REQUEST_PENDING && data?.approvalRequired">{{
        'EXT_DURATION_DESC' | translate:{unit: formatMinutes(data?.duration)} }}
      </p>
      <p *ngIf="data?.approvalRequired && statusDetails?.ErrorKey===extendCreditStauts.REQUEST_PENDING">{{
        'EXT_CREDIT_DESC_PENDING' |
        translate:{type:translateSrv.instant('DURATION')} }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <teams-button [classList]="'btn ts-btn-fluent-red modal-close-button btn-danger'" (buttonClick)="onCancel()">
      {{data.failureButtonText}}</teams-button>
    <teams-button [classList]="'btn ts-btn-fluent-primary modal-ok-button'"
      [disabled]="loading || (statusDetails?.ErrorKey===extendCreditStauts.REQUEST_PENDING && data?.approvalRequired)"
      (buttonClick)="onConfirm()">
      {{data.successButtonText}}</teams-button>
  </div>
</div>