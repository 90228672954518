import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LabClusterEntity } from '../modals/lab-cluster.modal';


export enum RDPDialogStatus {
  NOT_OPENED,
  OPEN,
  CLOSED,
  CONFIRM_CLOSED,
}

export enum ValidationDialogStatus {
  NOT_OPENED,
  OPEN,
  CLOSED,
  CONFIRM_CLOSED,
}

export enum RetryDialogStatus {
  CONFIRM,
}

export interface RetryDialogEventEntity{
  status: RetryDialogStatus;
  entity: LabClusterEntity;
}

@Injectable({
  providedIn: 'root'
})
export class EventService {
  openSideNav = new EventEmitter();
  onCloseDialog = new EventEmitter();
  activityDialog = new EventEmitter();
  completeDialog = new EventEmitter();
  resourceDialog = new EventEmitter();
  closeduration = new EventEmitter();
  onExtendduration = new EventEmitter();
  tokenNotfound = new EventEmitter();
  toggleHeader = new EventEmitter();
  toggleFooter = new EventEmitter();
  onExtendDurationError = new EventEmitter<any>();
  onExtendDurationCancel = new EventEmitter();
  languageChanged = new EventEmitter<string>();
  onDeallocateODL = new EventEmitter();
  copyEvent = new EventEmitter();
  actionClickEvent = new  Subject();
  themeId = new Subject<number>();
  rdpDialogStatus = new Subject<RDPDialogStatus>();
  validationDialogStatus = new Subject<ValidationDialogStatus>();
  retryDialogStatus = new Subject<RetryDialogEventEntity>();
  windowJoin = new Subject<null>();
  pasteInsideVm = new Subject();
  onLoadValidation = new Subject();
  lockPages = new BehaviorSubject(null);
  copyMode = new BehaviorSubject(false);
  splitWindowCopy = new BehaviorSubject(null);
  changeTab = new Subject();

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Get app settings
   */
  onMenuClick() {
    this.openSideNav.emit();
  }

  /**
   * vm dialog on close
   */

   onCloseVM(){
    this.onCloseDialog.emit();
   }

   /**
    *
    * @param item
    */
   onActivityDialog(item : string){
    this.activityDialog.emit(item);
   }

         /**
    *
    * @param item
    */
    onResourceDialog(item : string)
    {
    this.resourceDialog.emit(item);
    }

      /**
    *
    * @param item
    */
   oncompleteDialogDialog(item : string){
    this.completeDialog.emit(item);
   }
   onCloaseDuration(){
    this.closeduration.emit();
   }
   extendduration(){
    this.onExtendduration.emit();
   }
   tokenError(){
    this.tokenNotfound.emit();
   }
   setThemeId(themeId: number) {
     this.themeId.next(themeId);
   }
   extendDurationError(errObject){
     this.onExtendDurationError.emit(errObject);
   }
   extendDurationCancel() {
     this.onExtendDurationCancel.emit();
   }
   languageIsChanged(language: string) {
     this.languageChanged.emit(language);
   }

   emmitRDPDialogConfirmClosed() {
    this.rdpDialogStatus.next(RDPDialogStatus.CONFIRM_CLOSED);
  }

  emmitRDPDialogClosed() {
    this.rdpDialogStatus.next(RDPDialogStatus.CLOSED);
  }

  emitRDPDialogOpen() {
    this.rdpDialogStatus.next(RDPDialogStatus.OPEN);
  }

  emmitValidationDialogConfirmClosed() {
    this.validationDialogStatus.next(ValidationDialogStatus.CONFIRM_CLOSED);
  }

  emmitValidationDialogClosed() {
    this.validationDialogStatus.next(ValidationDialogStatus.CLOSED);
  }

  emmitretryDialogConfirm(entity: LabClusterEntity) {
    this.retryDialogStatus.next({
      status: RetryDialogStatus.CONFIRM,
      entity
    });
  }

  emmitToggleHeader(){
    this.toggleHeader.emit();
    this.toggleFooter.emit();
  }

  emmitWinodwJoin(){
    this.windowJoin.next();
  }

  deallocateODL(){
    this.onDeallocateODL.emit();
  }
  
  onCopy(){
    this.copyEvent.emit();
  }

}
