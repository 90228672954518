<cloudlabs-skip-to-main-content *ngIf="!isMylearning && environment.appType !== 'trial'"
  [mainDiv]="'main-landmark0'"></cloudlabs-skip-to-main-content>

<ng-container *ngIf="lab">

  <cloudlabs-refined-header [customLogo]="lab.PartnerheaderLogo ? lab.PartnerheaderLogo : lab?.PartnerLogo" [lab]="lab"
    [menu]="false" [actionList]="actionList" [counter]="counter" (actionClickEvent)="actionClickEvent($event)" [labLaunchedState]="showFullScreen"
    [modeOfOperation]="modeOfOperation" [attendeeEndDate]="endDateData" [vmDetail]="initialDeployment?.AllocatedTestDriveViewModalDetails"  (vmChanged)="vmSwitch($event)"
    [showMore] = "initialDeployment && showMoreOption && !initialDeployment?.DisableHostVMCopyPaste ? true : false">
  </cloudlabs-refined-header>

  <div class="vm-environment">

    <div #docGuide [class.sideNavBar]="isSidenav === true" [class.stretch]="!enableHeader && !toggleFullScreenforCLPortal && !lab?.EnableCustomLabDetailsPage" [ngClass]="{'inside-attendee-container': environment.appType === 'attendee' && !toggleFullScreenforCLPortal, 'inside-trial-container' : environment.appType === 'trial',
    'cloudlab-portal': isMylearning === true && !toggleFullScreenforCLPortal }" *ngIf="!isComplete" [attr.role]="!isMylearning ? 'main' : ''">

      <ng-container [ngSwitch]="layoutType">
        <ng-container *ngSwitchCase="environmentLayout.DATABRICKS">
          <cloudlabs-databricks-layout [vCode]="vCode" [isDraggable]="isDraggable"></cloudlabs-databricks-layout>
        </ng-container>

        <ng-container *ngSwitchCase="environmentLayout.VM">
          <div [class.frameFull]="isDraggable" class="iframeBlock" id="iframeBlock" 
            [ngClass]="{ 'overflow-y-auto': !loading && !loader && manager?.getState() !== remoteDesktopStates.CONNECTED, 'set-width':!isSidenav }">
            <ng-container>
              <div id="vdi-container" [ngClass]="{'vdi-container':!toggleFullScreenforCLPortal}">
                <p style="display: none" id="remote-desktop-description">
                  <ng-container *ngIf="osInfo === 'Mac OS'; else showWindowsInfo">
                    {{'VM_FOCUS_MESSAGE' | translate: {key1: 'Option', key2: '`'} }}
                  </ng-container>
                  <ng-template #showWindowsInfo>
                    {{'VM_FOCUS_MESSAGE' | translate: {key1: 'Alt', key2: '`'} }}
                  </ng-template>
                </p>
                <app-vm class="w-100" [isInsideEnv]="true" (checkVmStatus)="checkVmStatus()" [lab]="lab" [vCode]="vCode" [cloudPlateFormId]="cloudPlateFormId"
                  [blockCopyPaste]="initialDeployment?.DisableHostVMCopyPaste" [steps]="steps" [currentVMdetail]="currentVMdetail"
                  (stepAction)="stepAction($event)" [vmErrorMessage]="vmErrorMessage" (managerDetail)="updateManager($event)"
                  [isSplitWindow]="isSplitWindow"></app-vm>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="!loading" [class.draggable-sidebar]="isDraggable"
        class="guideBlock cl-sideBar fixed-top one vmSideBar courses-doc" [class.sidebarCollapse]="!isSidenav"  [style.padding-top]=" toggleFullScreenforCLPortal ? '0px !important' : ''"
        #guideBlock>

        <p aria-label="VM Name" [ngClass]="{'no-bottom': environment?.appType === 'lab'}" aria-hidden="true"
          (click)="openSidenav()" class="vmName anc">

          {{ vmName }}

        </p>

        <div id="side-con" class="col-md-12 p-0 123" [style.padding-right]=" toggleFullScreenforCLPortal ? '0px !important' : ''">

          <div class="sidebar-close cursor-pointer" *ngIf="isSidenav" (keydown.enter)="closeSidenav()"
            (click)="closeSidenav()">

            <div role="button" tabindex="0"
              attr.aria-label="{{ labTitle === '' ? lab.title : labTitle }}Collapse Sidebar">

              <i class="fas fa-chevron-right white-color"></i>

            </div>

          </div>

          <div class="sidebar-open cursor-pointer" *ngIf="!isSidenav" (keydown.enter)="openSidenav()"
            (click)="openSidenav()">

            <div role="button" tabindex="0" attr.aria-label="{{ labTitle === '' ? lab.title : labTitle }}Expand Sidebar">

              <i class="fas fa-chevron-left white-color"></i>

            </div>

          </div>

          <div #sidebarHeader class="sidebarHeader py-1" [attr.aria-hidden]='isSidenav !== true'>


          </div>
          <cloudlabs-notes [ngClass]="{'mt-10':isMylearning && notes?.length > 0}" class="ml-4 mr-3 mt-2 mb-1" *ngIf="guideData.CustomInstruction" [customInstruction]="guideData.CustomInstruction"></cloudlabs-notes>
          <!-- [attr.aria-hidden]="docguidescreen"  => Remove this line for accessibility fix (id: 4416)-->
          <cloudlabs-doc-guide *ngIf="(loadGitDoc || isDeviceSmall()) && initialDeployment" [attr.inert]="docguidescreen"
            [ngClass]="{'pl-4': environment?.appType === 'lab'}" [manager]="manager" [labDetail]="lab" [initialDeployment]="initialDeployment"
            [voucherCode]="vCode" [isSidenav]="isSidenav" [lang]="lang" [mode]="modeOfOperation" [guideData]="guideData" [showResourcesTab]="showResourcesTab"
            [splitKey]="splitKey" (labIsCompleted)="completeTheLab($event)" (onLoadingComplete)="calculateHeight()"
            [isMylearning]="isMylearning" (splitScreen)="splitWindow()" [layoutType]="layoutType" (collapseSideBar)="closeSidenav()" [isMobile]="isMobile"></cloudlabs-doc-guide>

        </div>

      </div>
    </div>


    <!--  Message on Deallocating -->
    <div [ngClass]="isMylearning ? 'row mt-5 flex-center pt-5' : 'col-md-7 mx-auto mt-5 flex-center'" role="alert"
      *ngIf="isComplete && modeOfOperation === 'Deallocating'">
      <div class="card text-center">
        <div class="alert mb-0" [ngClass]="{
        'alert-custom alert-light-warning' : isMylearning,
        'alert-warning' : !isMylearning
      }">
          <h4 class="block">
            <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
            &nbsp; {{ "MESSAGE_DEALLOCATING" | translate }}
          </h4>
        </div>
      </div>
    </div>

    <div class="doc-content" [ngClass]="{'card-min-height': ['Deallocated','Deployed'].includes(modeOfOperation)}">
      <!-- <div class="course-header-title" *ngIf="isMylearning && ([
      'Deallocated',
      'Deployed'].includes(modeOfOperation) && isComplete)">
        <span class="back-arrow cursor-pointer" (click)="goBackPage()"><i class="fas fa-arrow-left"></i></span>
        <h3>{{lab.Title}}</h3>
      </div> -->
      <!--  Message on Deployed -->
      <div class="col-md-7 mx-auto pt-5" role="alert" *ngIf="isComplete && modeOfOperation === 'Deployed'">
        <div class="card text-center" tabindex="0">
          <div class="alert mb-0" [ngClass]="{
          'alert-custom alert-light-warning' : isMylearning,
          'alert-warning' : !isMylearning
        }">
            <h4 class="block">
              <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
              &nbsp; {{ "ALERT_ALREADY_COMPLETED" | translate }}
            </h4>
            <p>{{ "ALERT_THANKS" | translate }}</p>
          </div>
        </div>
      </div>

      <!--  Message on Deallocated -->
      <div class="col-md-7 mx-auto pt-5" role="alert" *ngIf="isComplete && modeOfOperation === 'Deallocated'">
        <div class="card text-center">
          <div class="alert mb-0" [ngClass]="{
          'alert-custom alert-light-warning' : isMylearning,
          'alert-warning' : !isMylearning
        }">
            <h4 class="block">
              <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
              &nbsp; {{ "ALERT_ODL_END" | translate }}
            </h4>
            <p>{{ "ALERT_ODL_END_THANKS1" | translate }}</p>
            <p>{{ "ALERT_THANKS" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Error message on failed -->
    <div class="col-md-7 mx-auto mt-5" role="alert" *ngIf="isComplete && modeOfOperation === 'Failed'">
      <div class="card text-center">
        <div class="alert mb-0" [ngClass]="{
        'alert-custom alert-light-danger' : isMylearning,
        'alert-danger' : !isMylearning
      }">
          <h4 class="block">
            <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
            <span *ngIf="lab?.PartnerName !== 'VMware'"
              [outerHTML]="'MESSAGE_FAILED' | translate: { Email: lab.ContactEmail }"></span>
            <span *ngIf="lab?.PartnerName === 'VMware'"
              [outerHTML]="'MESSAGE_FAILED_VMWARE' | translate: { Email: lab.ContactEmail }"></span>
          </h4>
          <p class="text-danger" [innerHTML]="error"></p>
        </div>
      </div>
    </div>

    <!-- Error message on failed -->
    <div class="col-md-7 mx-auto mt-5" role="alert" *ngIf="isComplete && modeOfOperation === 'Error'">
      <div class="card text-center">
        <div class="alert mb-0" [ngClass]="{
        'alert-custom alert-light-danger' : isMylearning,
        'alert-danger' : !isMylearning
      }">
          <h4 class="block" *ngIf="lab?.PartnerName !== 'VMware'">
            <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
            &nbsp; {{ "MESSAGE_FAILED" | translate: { Email: lab.ContactEmail } }}
          </h4>
          <h4 class="block" *ngIf="lab?.PartnerName === 'VMware'">
            <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
            &nbsp; {{ "MESSAGE_FAILED_VMWARE" | translate: { Email: lab.ContactEmail } }}
          </h4>
          <p class="text-danger">{{ "RDP_ERROR" | translate }}</p>
        </div>
      </div>
    </div>

    <!-- Loading screen -->
    <div class="vex-splash-screen" *ngIf="loading">
      <div class="wrapper">
        <div class="ball-scale-multiple">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="showImagePopup">
    <cloudlabs-dialoge-prompt appTrapFocus appAutoFocusModal appRefocusPreviousEl *ngIf="showImagePopup"
      [popupWidth]="imagePopupStyle.width" [popupHeight]="imagePopupStyle.height" [baseZIndex]="9999902"
      [isDialog]="showImagePopup" (popupResponse)="closeImagePopup($event)">
      <div header class="subheading">
        {{ 'PREVIEW_BUTTON'| translate }}
      </div>
      <div content class="">
        <div class="text-center" [innerHTML]="imagePopupElement">
        </div>
      </div>
      <div footer class="col-md-12 mt-3">
        <teams-button [ngClasses]="environment?.appType === 'lab'? 'ts-btn-fluent-primary': 'ts-btn-fluent-white pt-2 pb-2'" class="" [classList]="'mt-2 btn ts-btn-fluent ts-btn-grey text-nowrap'"
          [title]="'CLOSE' | translate" (click)="showImagePopup = false">
        </teams-button>
      </div>
    </cloudlabs-dialoge-prompt>
  </ng-container>

  <div class="modal" role="dialog" appTrapFocus appAutoFocusModal aria-labelledby="modal-title"
    aria-describedby="modal-desc" *ngIf="modelShow" [class.validation]="modelShow" id="validation">
    <div class="modal-dialog" [class.validation-model]="isLabValidation && modalData.type === 'DEALLOCATE_LAB'">
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="modal-title" class="modal-title">{{modalData.title | translate}}</h4>
        </div>
        <div class="modal-body delete-body" aria-label="modal description">
          <p *ngIf="modalData.type === 'EXTEND_LAB_ERROR'"
            [innerHTML]="'MAXIMUM_TIME_REACHED' | translate:{allowedMin: lab.MaxUserDurationExtension}"></p>
          <p id="modal-desc" [innerHTML]="modalData.description | translate">
          </p>
          <cloudlabs-lab-validation *ngIf="isLabValidation && modalData.type === 'DEALLOCATE_LAB'" [voucherCode]="vCode" [labDetail]="lab" [manager]="manager" [templateValidation]="templateValidation" [isDialog]="true"></cloudlabs-lab-validation>
        </div>
        <div class="modal-footer">
          <teams-button *ngIf="modalData.showCancel === true"
            [classList]="'btn ts-btn-fluent-red modal-close-button btn-danger'" (buttonClick)="modelShow=false; loadValidations();">{{'CANCEL'
            | translate}}
          </teams-button>
          <teams-button *ngIf="modalData.type === 'DEALLOCATE_LAB'"
            [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="deallocateLab()">
            {{'PROCEED_TO_DELETE' |
            translate}}</teams-button>
          <teams-button *ngIf="modalData.type === 'EXTEND_LAB_ERROR'"
            [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="modelShow=false">
            {{'OK' |
            translate}}</teams-button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" role="dialog" appTrapFocus appAutoFocusModal aria-labelledby="modal-title"
    aria-describedby="modal-desc" *ngIf="showFileTransferPopUp"  [class.validation]="showFileTransferPopUp">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="modal-title" class="modal-title">{{'INFORMATION' | translate}}</h4>
        </div>
        <div class="modal-body" aria-label="modal description">
          <p id="modal-desc" [innerHTML]=" 'FILE_TRANSFER_DESCRIPTION' | translate">
          </p>
        </div>
        <div class="modal-footer">
          <teams-button
            [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="showFileTransferPopUp = false">
            {{'OK' |
            translate}}</teams-button>
        </div>
      </div>
    </div>
  </div>

</ng-container>